import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import VpnKey from "@material-ui/icons/VpnKey";
import Timeline from "@material-ui/icons/Timeline";
import OndemandVideo from "@material-ui/icons/OndemandVideo";
import EmojiPeople from "@material-ui/icons/EmojiPeople";
import Payment from "@material-ui/icons/Payment";
import Assignment from "@material-ui/icons/Assignment";
import Assessment from "@material-ui/icons/Assessment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Let{"'"}s talk about Class Manager</h2>
          <h5 className={classes.description}>
          Class Manager is a online teaching platform that provide with
                many benifits. Every one can access ClassManager via Mobile, Laptop,
                Desktop. Teachers and Students can teach and learn any where they like.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Exams"
              description="With Class Manager Teachers and Parents can send view Students Exam Marks."
              icon={Timeline}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="E-Learning"
              description="With Class Manager Teachers can send Videos, Zoom Meeting Link, any URL with a description and Students can view them."
              icon={OndemandVideo}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Attendance"
              description="With Class Manager Teachers and Parents can view Students Attendance."
              icon={EmojiPeople}
              iconColor="warning"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Payments"
              description="With Class Manager Teachers and Parents can view Students Payments By Month."
              icon={Payment}
              iconColor="rose"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Homeworks"
              description="With Class Manager Teachers can send homeworks to Students and Students can do them and discuss with Teacher."
              icon={Assignment}
              iconColor="warning"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Reports"
              description="With Class Manager Teachers and Parents can send view Students Exam Marks and Progress."
              icon={Assessment}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Verified Students"
              description="In Class Manager Platform Teachers can decide which student can enter a classroom. Also Teachers can suspend students."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Send SMS"
              description="With Class Manager Mobile App Teachers can send SMS to Parents. There is special benifit that Teachers can send SMS to absent students very easily."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Secured Data"
              description="The Data store in Class Manager is highly protected. There is no need to worry for storing data."
              icon={VpnKey}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
