import React, { useEffect } from "react";
import HttpCommon from "http-common.js";
import { useHistory } from 'react-router-dom';


// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';


import ButtonMaterial from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';

import styles from "assets/jss/material-kit-react/views/signUpPage.js";

// Sections for this page
import ProductSection from "../LandingPage/Sections/ProductSection.js";
import TeamSection from "../LandingPage/Sections/TeamSection.js";
import WorkSection from "../LandingPage/Sections/WorkSection.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddAlert from "@material-ui/icons/AddAlert";
import { Cancel } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";
import NavPills from "components/NavPills/NavPills.js";
import { Chip } from "@material-ui/core";
import { PureComponent } from "react";
// import { render } from "node-sass";
// import { render } from "node-sass";


const dashboardRoutes = [];

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: '#3952C7',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: '#3952C7',
            },
            // '&.Mui-focused fieldset': {
            //   borderColor: 'green',
            // },
        },
        "& .MuiInputBase-root.Mui-disabled": {
            color: "black"
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "black"
        },
    },
})(TextField);

const useStyles = makeStyles(styles);
// const useStyles = makeStyles((theme) => ({
//     root: {
//       '& .MuiTextField-root': {
//         margin: theme.spacing(1),
//         width: '25ch',
//       },
//     },
//   }));

var token;


export default function MyAccount(props) {

    const history = useHistory();

    const classes = useStyles();
    const { ...rest } = props;

    var responseData;
    var institution;
    var addressLineIN;
    var contactNoIN;

    // var centersArr= [];

    // useEffect(() => {

    //     centersArr= 

    // }, [centersArr]);

    const [centersArr, setCenterArr] = React.useState([]);
    const [type, setType] = React.useState('');
    const [subjects, setSubjects] = React.useState([]);

    const [values, setValues] = React.useState({
        institution: '',
        addressLineIN: '',
        contactNoIN: '',
        fName: '',
        lName: '',
        nic: '',
        CurrentPassword: '',
        NewPassword: '',
        gender: '',
        address: '',
        city: '',
        province: '',
        contactNo: '',
        id: '',
        type: '',
        showPassword: false,
    });
    const [selectedDate, setSelectedDate] = React.useState();
    const [errorMsg, setErrorMsg] = React.useState(' ');


    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openFieldError, setOpenFieldError] = React.useState(false);
    const [openErrorMsg, setOpenErrorMsg] = React.useState(false);
    const [openUpdatedSuccess, setOpenUpdatedSuccess] = React.useState(false);



    const handleClickUpdateEmployee = () => {
        getData();
        // console.log("token = " + token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (values.fName != '' && values.lName != '') {
            HttpCommon.post('/api/UpdateEmployee', {            // not /api/v1/UpdateEmployee
                "id": values.id,
                "firstname": values.fName,
                "lastname": values.lName,
            },
                config
            )
                .then(response => {
                    setOpenUpdatedSuccess(true);

                    if (response.data.employees != null) {
                        console.log("user Updated");
                        console.log(response.data);
                        // setOpen(true);
                        setOpenUpdatedSuccess(true);

                    }
                }).catch(function (error) {

                    console.log(error.response);
                    setErrorMsg(error.response.data);
                    setOpenErrorMsg(true);
                });
        } else {
            setErrorMsg("Enter All Required Field");
            setOpenErrorMsg(true);
        }
    };


    const handleClickUpdatePassword = () => {
        getData();
        // console.log("token = " + token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (values.CurrentPassword != '' && values.NewPassword != '') {
            HttpCommon.post('/api/changePassword', {            // not /api/v1/UpdateEmployee
                "Current": values.CurrentPassword,
                "New": values.NewPassword
            },
                config
            )
                .then(response => {
                    console.log(response.data);

                    if (response.data.error != null) {
                        setErrorMsg(response.data.msg);
                        setOpenErrorMsg(true);
                        // setOpen(true);
                    } else if (response.data.user != null) {
                        console.log("user Updated");
                        console.log(response.data);
                        setOpenUpdatedSuccess(true);
                        // setOpen(true);
                    }
                    setValues({ ...values, CurrentPassword: '', NewPassword: '' });

                }).catch(function (error) {

                    console.log(error.response);
                    setErrorMsg(error.response.data);
                    setOpenErrorMsg(true);
                });
        } else {
            setErrorMsg("Enter All Required Field");
            setOpenErrorMsg(true);
        }
    };

    const handleClickAddCenter = () => {
        getData();
        // console.log("token = " + token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (values.institution != '' && values.addressLineIN != '' && values.contactNoIN != '') {
            HttpCommon.post('/api/v1/createCenter', {            // not /api/v1/UpdateEmployee
                "centerName": values.institution,
                "centerAddress": values.addressLineIN,
                "centerTel": values.contactNoIN
            },
                config
            )
                .then(response => {
                    console.log(response.data);

                    if (response.data.error != null) {
                        setErrorMsg(response.data.msg);
                        setOpenErrorMsg(true);
                        // setOpen(true);
                    } else if (response.data.center != null) {
                        console.log("user Updated");
                        console.log(response.data);
                        setOpen(true);
                        // setOpen(true);
                    }
                    setValues({ ...values, institution: '', addressLineIN: '', contactNoIN: '' });

                }).catch(function (error) {

                    console.log(error.response);
                    error.response.data.error.errors.forEach(element => {
                        setErrorMsg(errorMsg + "\n" + element.message);
                    });
                    setOpenErrorMsg(true);
                });
        } else {
            setErrorMsg("Enter All Required Field");
            setOpenErrorMsg(true);
        }
    };

    async function clearErrorMsg() {
        setErrorMsg('');
        console.log("Cleared Error msg = " + errorMsg);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpenUpdatedSuccess(false);
        setOpenError(false);
        setOpenErrorMsg(false);
        clearErrorMsg();

    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function getData() {
        token = localStorage.getItem('token') || null;
    }

    function getSubjects() {
        getData();
        // console.log("token = " + token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        HttpCommon.post('/api/getsubjects', null,
            config
        )
            .then(response => {
                console.log(response.data);

                if (response.data.error != null) {
                    setErrorMsg(response.data.msg);
                    setOpenErrorMsg(true);
                    // setOpen(true);
                } else if (response.data.subjects != null) {
                    console.log("user subjects");
                    console.log(response.data);
                    // setOpen(true);                        
                    var subjectNames = [];
                    response.data.subjects.forEach(element => {
                        subjectNames.push({ 'title': element.Name, 'id': element.id });
                    });
                    console.log(subjectNames);
                    setSubjects(subjectNames);

                }

            }).catch(function (error) {

                console.log(error.response);
                error.response.data.error.errors.forEach(element => {
                    setErrorMsg(errorMsg + "\n" + element.message);
                });
                setOpenErrorMsg(true);
            });

    }

    useEffect(() => {
        getData();
        // console.log("token = " + token);
        getSubjects();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (token != null) {
            HttpCommon.post('/api/verifyUser', null,
                config
            )
                .then(response => {
                    if (response.data.id != null) {
                        console.log("user Verified");
                        console.log(response.data);
                        responseData = response.data;
                        setValues({
                            ...values, fName: response.data.firstname, lName: response.data.lastname
                            , nic: response.data.nic, address: response.data.address
                            , contactNo: response.data.telno, id: response.data.id, type: response.data.type
                        });
                        setCenterArr(response.data.centers);
                        console.log(response.data.centers);
                        setType(response.data.type);
                        console.log(response.data.type);

                    }
                });
        } else {
            history.push({
                pathname: "/login-page"
            });
        }



    }, []);

    return (
        <div>
            <Header
                brand="Class Manager"

                rightLinks={<HeaderLinks />}
                fixed
                color="white"
                changeColorOnScroll={{
                    height: 200,
                    color: "dark"
                }}
                {...rest}
            />

            <Parallax small image={require("assets/img/landing2.jpg")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div className={classes.brand}>
                                <h1 className={classes.title}>Account Detailes</h1>

                            </div>
                            <br />

                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8}>
                                <h2 className={classes.title1}>Profile Detailes</h2>
                                <h5 className={classes.description}>
                                    In this Profile Page you can only edit First Name, Last Name And Password Only. If you want correct other than name and password contact ClassManager from contactUs Page.
                                </h5>

                                <br />
                                <br />
                                <form className={classes.root} noValidate>

                                    <h5 className={classes.description1}>
                                        Account Detailes
                                     </h5>
                                    {/* <Divider orientation="vertical" flexItem /> */}
                                    <br />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="First Name"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="fName"
                                        fullWidth
                                        helperText=""
                                        value={values.fName}
                                        onChange={handleChange('fName')}


                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Last Name"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="lName"
                                        fullWidth
                                        helperText=""
                                        value={values.lName}
                                        onChange={handleChange('lName')}



                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="NIC"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="nic"
                                        fullWidth
                                        helperText=""
                                        value={values.nic}
                                        disabled


                                    />

                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Address"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="address"
                                        fullWidth
                                        helperText=""
                                        value={values.address}
                                        disabled


                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Contact No"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="contactNo"
                                        fullWidth
                                        helperText=""
                                        value={values.contactNo}
                                        disabled


                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Acc Type"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="contactNo"
                                        fullWidth
                                        helperText=""
                                        value={values.type}
                                        disabled


                                    />
                                </form>
                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClickUpdateEmployee()}>
                                    Save
                                        </ButtonMaterial>

                                <form className={classes.root} noValidate>

                                    <h5 className={classes.description1}>
                                        Change Password
                                     </h5>
                                    <FormControl fullWidth className={clsx(classes.margin, classes.root1)} variant="outlined">
                                        <InputLabel required htmlFor="outlined-adornment-password">Current Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            required
                                            onChange={handleChange('CurrentPassword')}
                                            fullWidth
                                            value={values.CurrentPassword}

                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className={clsx(classes.margin, classes.root1)} variant="outlined">
                                        <InputLabel required htmlFor="outlined-adornment-password">New Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            required
                                            value={values.NewPassword}
                                            onChange={handleChange('NewPassword')}
                                            fullWidth

                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                    </FormControl>
                                </form>
                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClickUpdatePassword()}>
                                    Update
                                        </ButtonMaterial>

                                <h2 className={classes.title1}>Institution Detailes</h2>
                                <h5 className={classes.description}>
                                    In this Institution Detailes Page you can only add new Institution only (Only center owners can add a new Institute).You can not change the detailes in other institutions.
                                    If you want correct detailes in other institutions contact ClassManager from contactUs Page.
                                </h5>

                                <br />
                                <br />

                                <GridContainer justify="center">

                                    {centersArr != null ?
                                        <ul>
                                            {centersArr.map((center, j) =>
                                                <CenterCard
                                                    key={j}
                                                    centerDetailes={center}
                                                    subjectArray={center.subjects}
                                                    type={type}
                                                    subjects={subjects}
                                                    classes={classes}

                                                />
                                            )}
                                        </ul>
                                        :
                                        <>
                                        </>
                                    }

                                    {type == 'Owner' ?
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Card >
                                                {/* <form className={classes.form}> */}
                                                <CardHeader color="warning" className={classes.cardHeader}>
                                                    <h4>Add New Institution</h4>
                                                </CardHeader>
                                                <CardBody>

                                                    <GridItem xs={12} sm={12} md={12} lg={12}>

                                                        <>
                                                            <form style={{ marginRight: "10px" }}>


                                                                <ValidationTextField
                                                                    className={classes.margin}
                                                                    label="Institution Name"
                                                                    variant="outlined"
                                                                    // defaultValue="Success"
                                                                    id="institution"
                                                                    fullWidth
                                                                    helperText=""
                                                                    value={values.institution}
                                                                    onChange={handleChange('institution')}



                                                                />

                                                                <ValidationTextField
                                                                    className={classes.margin}
                                                                    label="Address"
                                                                    variant="outlined"
                                                                    // defaultValue="Success"
                                                                    id="addressLineIN"
                                                                    fullWidth
                                                                    helperText=""
                                                                    value={values.addressLineIN}
                                                                    onChange={handleChange('addressLineIN')}


                                                                />

                                                                <ValidationTextField
                                                                    className={classes.margin}
                                                                    label="Contact No"
                                                                    variant="outlined"
                                                                    // defaultValue="Success"
                                                                    id="contactNoIN"
                                                                    fullWidth
                                                                    helperText=""
                                                                    value={values.contactNoIN}
                                                                    onChange={handleChange('contactNoIN')}


                                                                />
                                                            </form>
                                                        </>

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12} lg={12}>

                                                        <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClickAddCenter()}>
                                                            Add
                                         </ButtonMaterial>
                                                    </GridItem>


                                                </CardBody>
                                                {/* </form> */}
                                            </Card>
                                        </GridItem>
                                        :
                                        <>
                                        </>
                                    }







                                </GridContainer>
                                <Snackbar open={openUpdatedSuccess} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        User Detailes Updated Successfully !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        New Institution Added Succesfully !

                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openError} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="error">
                                        Error Occured !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openErrorMsg} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="error">
                                        {errorMsg}
                                    </Alert>
                                </Snackbar>


                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}


export class CenterCard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            nicTeacher: '',
            subject: '',
            errormsg: '',
            openErrorMsg: false,
            open: false
        };
    }


    render() {
        const classes = this.props;
        // console.log(props.name); // result: 'some_value'
        // console.log(this.props.allData); // result: 'some_value'
        console.log(this.props);



        const onSubjectChange = (event, value) => {
            if (value != null) {
                this.setState({
                    subject: value
                });
                // setValues({ ...values, institution: value });
                console.log("subject = " + value.id + " " + value.title);
                console.log(value);
            }

        };

        const handleChange = (prop) => (event) => {
            // setValues({ ...values, [prop]: event.target.value });
            this.setState({
                nicTeacher: event.target.value
            });
            console.log(this.state.nicTeacher);

        };

        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            this.setState({
                open: false,
                openErrorMsg: false,
                errormsg: ''
            });
            // setOpen(false);
            // setOpenUpdatedSuccess(false);
            // setOpenError(false);
            // setOpenErrorMsg(false);
            // clearErrorMsg();

        };

        const handleClickAddClass = (centerId) => {
            var temp = {
                "teacherNIC": this.state.nicTeacher,
                "subjectId": this.state.subject.id,
                "centerId": centerId
            }
            console.log(temp);

            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            HttpCommon.post('/api/v1/addClass', {
                "teacherNIC": this.state.nicTeacher,
                "subjectId": this.state.subject.id,
                "centerId": centerId
            },
                config
            )
                .then(response => {
                    console.log(response.data);

                    if (response.data.error != null) {
                        this.setState({
                            errormsg: response.data.error,
                            openErrorMsg: true
                        });
                        // setErrorMsg(response.data.msg);
                        // setOpenErrorMsg(true);
                        // setOpen(true);
                    } else if (response.data.center != null) {
                        console.log("user Updated");
                        console.log(response.data.center);
                        this.setState({
                            open: true
                        });
                        window.location.reload(false);
                        // setOpen(true);
                        // setOpen(true);
                    }
                    // setValues({ ...values, institution: '', addressLineIN: '', contactNoIN: '' });

                }).catch(error => {

                    console.log(error.response);
                    if (error.response.data != null) {
                        error.response.data.error.errors.forEach(element => {
                            this.setState({
                                errormsg: this.state.errormsg + "\n" + element.message,
                                openErrorMsg: true
                            });

                        });
                    } else {
                        this.setState({
                            errormsg: "Unknown Error Occured",
                            openErrorMsg: true
                        });
                    }

                    // setOpenErrorMsg(true);


                });

        };


        return (
            <>
                <GridItem xs={12} sm={12} md={12}>
                    <Card >
                        <form className={classes.form}>
                            <CardHeader color="success" className={classes.cardHeader}>
                                <h4 className={classes.titleCard}>{this.props.centerDetailes.Name} Insitution</h4>
                            </CardHeader>
                            <CardBody>

                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <NavPills
                                        // ["primary","warning","danger","success","info","rose"].
                                        color="primary"
                                        tabs={[
                                            {
                                                tabButton: "Detailes",
                                                tabIcon: Dashboard,
                                                tabContent: (
                                                    <>
                                                        <div style={{ marginBottom: "20px" }}></div>

                                                        <ValidationTextField
                                                            className={classes.margin}
                                                            label="Institution Name"
                                                            variant="outlined"
                                                            // defaultValue="Success"
                                                            id="Name"
                                                            fullWidth
                                                            helperText=""
                                                            value={this.props.centerDetailes.Name}
                                                            disabled


                                                        />
                                                        <div style={{ marginBottom: "20px" }}></div>

                                                        <ValidationTextField
                                                            className={classes.margin}
                                                            label="Address"
                                                            variant="outlined"
                                                            // defaultValue="Success"
                                                            id="Address"
                                                            fullWidth
                                                            helperText=""
                                                            value={this.props.centerDetailes.Address}
                                                            disabled


                                                        />
                                                        <div style={{ marginBottom: "20px" }}></div>

                                                        <ValidationTextField
                                                            className={classes.margin}
                                                            label="Contact No"
                                                            variant="outlined"
                                                            // defaultValue="Success"
                                                            id="TelNo"
                                                            fullWidth
                                                            helperText=""
                                                            value={this.props.centerDetailes.TelNo}
                                                            disabled


                                                        />
                                                        <div style={{ marginBottom: "20px" }}></div>
                                                        <ValidationTextField
                                                            className={classes.margin}
                                                            label="Expire Date"
                                                            variant="outlined"
                                                            // defaultValue="Success"
                                                            id="expireDate"
                                                            fullWidth
                                                            helperText=""
                                                            value={this.props.centerDetailes.expireDate}
                                                            disabled
                                                        />
                                                    </>
                                                )
                                            },
                                            {
                                                tabButton: "Subjects",
                                                tabIcon: Schedule,
                                                tabContent: (
                                                    <>
                                                        {this.props.subjectArray != null ?
                                                            <ul>
                                                                {this.props.subjectArray.map((subject, i) =>
                                                                    <GridItem key={i} xs={12} sm={12} md={12} >
                                                                        <MuiAlert elevation={6} variant="filled" color="warning" icon={false}>
                                                                            <div style={{ display: 'flex', position: 'relative', alignItems: 'left', justifyContent: 'left', color: "#635343" }}>
                                                                                {subject.Name}
                                                                            </div>
                                                                            <>
                                                                                {
                                                                                    subject.teachers != null ? (
                                                                                        subject.teachers.map((teacher, i) =>
                                                                                            <div style={{ display: 'flex', position: 'relative', alignItems: 'left', justifyContent: 'left', marginLeft: '10px' }}>
                                                                                                {teacher.firstname} {teacher.lastname}
                                                                                            </div>
                                                                                        )
                                                                                    ) :
                                                                                        <>
                                                                                        </>
                                                                                }
                                                                            </>
                                                                        </MuiAlert>
                                                                        <div style={{ marginBottom: "10px" }}></div>
                                                                    </GridItem>
                                                                )}
                                                            </ul>
                                                            :
                                                            <>
                                                            </>
                                                        }
                                                    </>
                                                )
                                            },
                                            {
                                                tabButton: "Add Class",
                                                tabIcon: List,
                                                tabContent: (
                                                    <>
                                                        {this.props.type == "Owner" ?
                                                            <>
                                                                <div style={{ marginBottom: "20px" }}></div>

                                                                <Autocomplete
                                                                    className={classes.margin3}
                                                                    fullWidth
                                                                    id="combo-box-demo3"
                                                                    onChange={onSubjectChange}
                                                                    // defaultValue={values.institution.title}
                                                                    value={{ title: this.state.subject.title }}
                                                                    options={this.props.subjects}
                                                                    getOptionLabel={(option) => option.title}
                                                                    // options={centersArr.map((option) => option.title)}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Subject"
                                                                            required
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            value={this.state.subject.title}
                                                                        />
                                                                    )}
                                                                />
                                                                <div style={{ marginBottom: "20px" }}></div>

                                                                <ValidationTextField
                                                                    className={classes.margin}
                                                                    label="Teacher NIC"
                                                                    variant="outlined"
                                                                    // defaultValue="Success"
                                                                    id="nicTeacher"
                                                                    fullWidth
                                                                    helperText=""
                                                                    value={this.state.nicTeacher}
                                                                    onChange={handleChange('nicTeacher')}



                                                                />
                                                                <div style={{ marginBottom: "20px" }}></div>

                                                                <ButtonMaterial variant="contained" className={this.props.classes.buttonMaterial2} onClick={() => handleClickAddClass(this.props.centerDetailes.id)}>
                                                                    Add
                                                                 </ButtonMaterial>


                                                            </>
                                                            :
                                                            <>
                                                                <GridItem xs={12} sm={12} md={12} >
                                                                    <MuiAlert elevation={6} variant="filled" color="error" icon={false}>
                                                                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                                                                            <div>Sorry. This Feature is available to Institute Owners only.</div>
                                                                        </div>
                                                                    </MuiAlert>
                                                                    <div style={{ marginBottom: "10px" }}></div>
                                                                </GridItem>
                                                            </>
                                                        }

                                                    </>

                                                )
                                            }
                                        ]}
                                    />
                                </GridItem>

                            </CardBody>
                        </form>
                    </Card>
                    <div style={{ marginBottom: "50px" }}></div>
                    <Snackbar open={this.state.openErrorMsg} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}  >
                        <Alert onClose={handleClose} severity="error">
                            {this.state.errormsg}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={this.state.open} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}  >
                        <Alert onClose={handleClose} severity="success">
                            New Class Added Succesfully !

                                    </Alert>
                    </Snackbar>

                </GridItem>
            </>

        );
    }

}


