import React from "react";
import styled from "styled-components";
import { Marginer } from "../marginer";

import NikeImg from "../../../assets/images/LOGO1.png";
import MediaQuery from "react-responsive";

const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5em 6px 0 6px;
  line-height: 1.4;
`;

const MediumText = styled.span`
  font-size: 18px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
`;

const SmallText = styled.span`
  font-size: 11px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
`;

const SizeChangeText = styled.span`
  font-size: 60%;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
`;

const SpacedHorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpacedHorizontalContainer2 = styled.div`
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BuyButton = styled.button`
  padding: 10px 16px;
  background-color: #fbbe01;
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  border: 3px solid transparent;
  outline: none;
  cursor: pointer;
  transition: all 290ms ease-in-out;
  border-radius: 8px;

  &:hover {
    background-color: transparent;
    color: #fff;
    border: 3px solid #fbbe01;
  }
`;

const NikeLogo = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: 25px;
  }
`;

export function ShoesDetails(props) {
  return (
    <>
      <MediaQuery minWidth={900}>
        <DetailsContainer>
          <SmallText>ID Card Detailes</SmallText>
          <SpacedHorizontalContainer>
            <MediumText>Full Name</MediumText>
            <MediumText>{props.name}</MediumText>
          </SpacedHorizontalContainer>
          <Marginer direction="vertical" margin="1.2em" />
          <SpacedHorizontalContainer>
            <MediumText>Birth Day</MediumText>
            <MediumText>{props.birthDay}</MediumText>
          </SpacedHorizontalContainer>
          <Marginer direction="vertical" margin="1.2em" />

          <SpacedHorizontalContainer>
            <MediumText>Barcode No</MediumText>
            <MediumText>{props.barCode}</MediumText>
            {/* <BuyButton>202100256</BuyButton> */}
          </SpacedHorizontalContainer>
          <NikeLogo>
            <img src={NikeImg} />
          </NikeLogo>
        </DetailsContainer>
      </MediaQuery>

      <MediaQuery maxWidth={900}>
        <DetailsContainer>
          <SpacedHorizontalContainer2>
            <SizeChangeText>Full Name</SizeChangeText>
            <SizeChangeText>{props.name}</SizeChangeText>
          </SpacedHorizontalContainer2>
          <Marginer direction="vertical" margin="1.2em" />
          <SpacedHorizontalContainer2>
            <SizeChangeText>Birth Day</SizeChangeText>
            <SizeChangeText>{props.birthDay}</SizeChangeText>
          </SpacedHorizontalContainer2>
          <Marginer direction="vertical" margin="1.2em" />

          <SpacedHorizontalContainer2>
            <SizeChangeText>Barcode No</SizeChangeText>
            <SizeChangeText>{props.barCode}</SizeChangeText>
            {/* <BuyButton>202100256</BuyButton> */}
          </SpacedHorizontalContainer2>
        </DetailsContainer>
      </MediaQuery>
    </>
  );

}
