import React, { useEffect, useRef } from "react";
import HttpCommon from "http-common.js";
import { useHistory } from 'react-router-dom';


// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';


import ButtonMaterial from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';

import styles from "assets/jss/material-kit-react/views/signUpPage.js";

// Sections for this page
import ProductSection from "../LandingPage/Sections/ProductSection.js";
import TeamSection from "../LandingPage/Sections/TeamSection.js";
import WorkSection from "../LandingPage/Sections/WorkSection.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddAlert from "@material-ui/icons/AddAlert";
import { Cancel, Close } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";
import NavPills from "components/NavPills/NavPills.js";
import { Chip, LinearProgress } from "@material-ui/core";
import { PureComponent } from "react";
import axios, { post } from 'axios';
import { Receiver1 } from 'react-file-uploader';

// import { render } from "node-sass";
// import { render } from "node-sass";
const superagent = require('superagent');
var progress = require('superagent-progress');



const dashboardRoutes = [];

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: 2,
                borderColor: 'grey',
            },
            '&:hover fieldset': {
                borderColor: '#3952C7',
            },

        },
        "& .MuiInputBase-root.Mui-disabled": {
            color: "black"
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "black"
        },
    },
})(TextField);

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const useStyles = makeStyles(styles);

var token;
var roleType;


export default function ELearningVideo(props) {

    const history = useHistory();

    const classes = useStyles();
    const { ...rest } = props;

    var responseData;
    var institution;
    var addressLineIN;
    var contactNoIN;
    var isEmpty = true;



    const [centersArr, setCenterArr] = React.useState([]);
    const [centersNameArr, setCentersNameArr] = React.useState([]);
    const [eLearningVideos, seteLearningVideos] = React.useState([]);
    const [viewableELearningVideos, setviewableELearningVideos] = React.useState([]);
    const [type, setType] = React.useState('');
    const [notValued, setNotValued] = React.useState(true);
    const [reset1, setReset1] = React.useState(true);
    const [random1, setRandom1] = React.useState(true);
    const [subjects, setSubjects] = React.useState([]);
    const [addedClassesVideo, setAddedClassesVideo] = React.useState([]);
    const [addedClassesLink, setAddedClassesLink] = React.useState([]);
    const inputFile = useRef(null);
    const [file, setFile] = React.useState('');
    const resettingRef = useRef(false);


    const [values, setValues] = React.useState({
        institution: '',
        classYear: '',
        subject: '',
        teacherId: '',
        name: '',
        description: '',
        link: '',
        institutionKey: '1',
        subjectKey: '2',
        classYearKey: '3',
        addressLineIN: '',
        contactNoIN: '',
        fName: '',
        lName: '',
        nic: '',
        CurrentPassword: '',
        NewPassword: '',
        gender: '',
        address: '',
        city: '',
        province: '',
        contactNo: '',
        id: '',
        type: '',
        showPassword: false,
    });
    const [selectedDate, setSelectedDate] = React.useState();
    const [errorMsg, setErrorMsg] = React.useState(' ');
    const [uploadPrecentage, setUploadPrecentage] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isAborting, setIsAborting] = React.useState(false);



    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openFieldError, setOpenFieldError] = React.useState(false);
    const [openErrorMsg, setOpenErrorMsg] = React.useState(false);
    const [openUpdatedSuccess, setOpenUpdatedSuccess] = React.useState(false);
    const [openUploadSuccess, setOpenUploadSuccess] = React.useState(false);

    const xhr = new XMLHttpRequest();




    useEffect(() => {
        if (isAborting) {
            console.log("Canceled UseEffect");
            setIsAborting(false);
            xhr.abort();
        }

    }, [isAborting]);


    const handleClickAddeLearningVideo = (isMeeting) => {
        getData();
        // console.log("token = " + token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (isMeeting) {
            if (values.name != '' && values.description != '' && values.link != '' && addedClassesLink != [] && addedClassesLink != null) {

                HttpCommon.post('/api/v1/addELearningVideo', {
                    "key": values.link,
                    "classes": addedClassesLink.map((clz) => {
                        return {
                            "centerId": clz.institution.id,
                            "subjectId": clz.subject.id,
                            "teacherId": clz.teacher.id,
                            "olYear": clz.classYear,
                        }
                    }),
                    "name": values.name,
                    "description": values.description,
                    "isMeeting": true,
                    "isSpaces": true
                },
                    config
                )
                    .then(response => {
                        if (response.data != null) {
                            console.log("user Updated");
                            // console.log(response.data);
                            // setOpen(true);
                            setOpenUpdatedSuccess(true);
                            setValues({
                                ...values, description: '', name: '', link: ''
                            });
                            setAddedClassesLink([]);
                        }
                    }).catch(function (error) {
                        // console.log(error.response);
                        setErrorMsg(error.response.data);
                        setOpenErrorMsg(true);
                    });
            } else {
                setErrorMsg("Enter All Required Field");
                setOpenErrorMsg(true);
            }
        } else {
            if (values.name != '' && values.description != '' && file != '' && addedClassesVideo != [] && addedClassesVideo != null) {
                HttpCommon.post('/api/v1/getELearningVideoURLSpaces', {
                },
                    config
                )
                    .then(response => {

                        if (response.data.url != null) {
                            const url = response.data.url;
                            const key = response.data.key;
                            // console.log("Start52146");
                            setIsUploading(true);
                            xhr.onabort = function (evt) {
                                console.log("Aborted");
                                setIsUploading(false);
                            };
                            xhr.onerror = function (evt) {
                                console.log("error");
                                // console.log(evt);
                                setIsUploading(false);
                            };
                            xhr.upload.onloadstart = function (evt) {
                                console.log("Starting");
                                // console.log(evt);
                            };
                            xhr.upload.onprogress = function (evt) {
                                if (evt.lengthComputable) {
                                    var percentComplete = parseInt((evt.loaded / evt.total) * 100);
                                    console.log("Upload: " + percentComplete + "% complete");
                                    setUploadPrecentage(percentComplete);
                                }
                            };
                            xhr.upload.onloadend = function (evt) {
                                console.log("End");
                                // console.log(evt);
                                HttpCommon.post('/api/v1/addELearningVideo', {
                                    "key": key,
                                    "classes": addedClassesVideo.map((clz) => {
                                        return {
                                            "centerId": clz.institution.id,
                                            "subjectId": clz.subject.id,
                                            "teacherId": clz.teacher.id,
                                            "olYear": clz.classYear,
                                        }
                                    }),
                                    "name": values.name,
                                    "description": values.description,
                                    "isMeeting": false,
                                    "isSpaces": true
                                },
                                    config
                                )
                                    .then(response => {
                                        if (response.data != null) {
                                            console.log("user Updated");
                                            // console.log(response.data);
                                            // setOpen(true);
                                            setOpenUploadSuccess(true);
                                            setIsUploading(false);

                                            setValues({
                                                ...values, description: '', name: '', link: ''
                                            });
                                            setAddedClassesVideo([]);
                                        }
                                    }).catch(function (error) {
                                        console.log(error);
                                        console.log(error.response);
                                        if (error.response != null) {
                                            setErrorMsg(error.response.data);
                                            setOpenErrorMsg(true);
                                        } else {
                                            setErrorMsg("Unknown Error Occured");
                                            setOpenErrorMsg(true);
                                        }

                                    });
                            };
                            xhr.onload = function (evt) {
                                console.log("Loading ...");
                                // console.log(evt);
                            };
                            xhr.open("PUT", url, true);
                            xhr.setRequestHeader("content-type", file.type);
                            xhr.setRequestHeader("x-amz-acl", "public-read");
                            xhr.send(file);

                        }
                    }).catch(function (error) {
                        console.log(error);
                        console.log(error.response);
                        if (error.response != null) {
                            setErrorMsg(error.response.data);
                            setOpenErrorMsg(true);
                        } else {
                            setErrorMsg("Unknown Error Occured");
                            setOpenErrorMsg(true);
                        }
                    });

            } else {
                setErrorMsg("Enter All Required Field");
                setOpenErrorMsg(true);
            }
        }


    };

    const handleOnCancel = () => {
        console.log("Cancled");
        setIsAborting(true);
        xhr.abort();

    }


    const handleClickAddClass = (isMeeting) => {

        if (values.institution.title != null && values.subject.title != null && values.classYear != null) {
            var addedClassesTemp = [];
            if (isMeeting) {
                addedClassesLink.forEach(element => {
                    addedClassesTemp.push(element);
                });
            } else {
                addedClassesVideo.forEach(element => {
                    addedClassesTemp.push(element);
                });
            }

            addedClassesTemp.push({ "institution": values.institution, "subject": values.subject, "classYear": values.classYear, "teacher": { "title": values.fName + " " + values.lName, "id": values.id } })
            // console.log(addedClassesTemp);

            /////// Search for duplicates :START
            var tempArr = addedClassesTemp;
            for (let index = 0; index < addedClassesTemp.length - 1; index++) {
                const element1 = addedClassesTemp[index];
                if (addedClassesTemp.length > 1) {
                    for (let index2 = index + 1; index2 < addedClassesTemp.length; index2++) {
                        const element2 = addedClassesTemp[index2];
                        if (JSON.stringify(element1) === JSON.stringify(element2)) {
                            setErrorMsg('Can not Add Duplicate Classes. This Class Already Added!');
                            setOpenErrorMsg(true);
                            return;
                        }
                    }
                }
            }
            /////// Search for duplicates :END    
            if (isMeeting) {
                setAddedClassesLink(addedClassesTemp);

            } else {
                setAddedClassesVideo(addedClassesTemp);

            }
            // console.log(addedClassesTemp);
            setValues({
                ...values, institution: '', subject: '', classYear: '', institutionKey: Math.random() * (1000 - 1), subjectKey: Math.random() * (1000 - 1), classYearKey: Math.random() * (1000 - 1)
            });
            setRandom1(!random1);
            // console.log(random1);
        } else {
            setErrorMsg("Enter All Required Field for Select Class");
            setOpenErrorMsg(true);
            setValues({
                ...values, institution: '', subject: '', classYear: ''
            });
            setRandom1(!random1);
            // console.log(random1);


        }
    }

    const handleRemove = (index, isMeeting) => {
        var addedClassesTemp = [];
        if (isMeeting) {
            addedClassesLink.forEach(element => {
                addedClassesTemp.push(element);
            });
        } else {
            addedClassesVideo.forEach(element => {
                addedClassesTemp.push(element);
            });
        }
        // console.log(addedClassesTemp);
        delete addedClassesTemp[index];
        if (isMeeting) {
            setAddedClassesLink(addedClassesTemp);

        } else {
            setAddedClassesVideo(addedClassesTemp);

        }
        // setAddedClasses(addedClassesTemp);
        console.log('Removed');
        // console.log(addedClassesTemp);

    }

    const getFile = () => {
        inputFile.current.click();
    }


    const onChangeFile = (event) => {
        // console.log(event.target.files[0]);
        setFile(event.target.files[0]);
    };

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }



    const handleViewVideos = async (event) => {
        isEmpty = true;
        setviewableELearningVideos([]);
        getELearningVideos();
        await sleep(1500); //wait 5 seconds

        setNotValued(!notValued)
        console.log("viewableELearningVideosChecking");
        // console.log(viewableELearningVideos);
        if (isEmpty) {
            setErrorMsg("There are no videos added to view");
            setOpenErrorMsg(true);
        }



    };



    function getELearningVideos() {
        // setviewableELearningVideos([]);
        getData();
        // console.log("token = " + token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        HttpCommon.post('/api/getELearningVideo', {            // not /api/v1/UpdateEmployee
            "teacherId": values.id
        },
            config
        )
            .then(response => {
                // console.log(response.data);

                if (response.data.error != null) {
                    setErrorMsg(response.data.msg);
                    setOpenErrorMsg(true);
                    // setOpen(true);
                } else if (response.data[0] != null) {
                    console.log("user Updated");
                    // console.log(response.data);
                    // setOpen(true);
                    seteLearningVideos(response.data);
                    // setOpen(true);


                    if (values.institution.title != null && values.subject.title != null && values.classYear != null) {
                        var tempArr = [];
                        for (let index = 0; index < response.data.length; index++) {
                            const element = response.data[index];
                            var temp1234 = {
                                "sdffsdfsd1": element.OL_Year + "",
                                "sdffsdfsd2": values.classYear,
                                "sdffsdfsd3": element.class.centerId,
                                "sdffsdfsd4": values.institution.id,
                                "sdffsdfsd5": element.class.subjectId,
                                "sdffsdfsd6": values.subject.id,
                                "sdffsdfsd7": element.class.teacherId,
                                "sdffsdfsd8": values.id
                            }

                            if (element.OL_Year + "" == values.classYear && element.class.centerId == values.institution.id &&
                                element.class.subjectId == values.subject.id && element.class.teacherId == values.id) {
                                isEmpty = false;
                                // console.log(temp1234);
                                // tempArr = viewableELearningVideos;
                                tempArr.push(element);
                                setviewableELearningVideos(tempArr);

                            }

                            if (index == response.data.length - 1) {
                                setviewableELearningVideos(tempArr);
                                // console.log(tempArr);
                                // console.log(viewableELearningVideos);
                            }
                        }
                        // console.log(tempArr);
                        setviewableELearningVideos(tempArr);
                    } else {
                        setErrorMsg("Enter All Required Field");
                        setOpenErrorMsg(true);
                    }
                } else if (response.data[0] == null) {
                    if (values.institution.title != null && values.subject.title != null && values.classYear != null) {
                        setErrorMsg("There are no videos added to view");
                        setOpenErrorMsg(true);
                    } else {
                        setErrorMsg("Enter All Required Field");
                        setOpenErrorMsg(true);
                    }
                }

            }).catch(function (error) {

                console.log(error.response);
                if (error.response.data.error != null) {
                    error.response.data.error.errors.forEach(element => {
                        setErrorMsg(errorMsg + "\n" + element.message);
                    });
                    setOpenErrorMsg(true);
                } else {
                    setErrorMsg("Error Occured");
                    setOpenErrorMsg(true);

                }

            });

    };

    const onCenterChange = (event, value) => {

        if (value != null) {
            // setValues({
            //     ...values, subject: '',  subjectKey: Math.random() * (1000 - 1)
            // });
            setValues({ ...values, subject: '', subjectKey: Math.random() * (1000 - 1), institution: value });
            // console.log("institution = " + value.id + " " + value.title);
            // console.log(value);
            var tempArr = [];
            centersArr.forEach(element => {
                console.log(centersArr);
                if (element.id == value.id) {
                    if (element.subjects == [] ||element.subjects == undefined || element.subjects == null || element.subjects.length == 0) {
                        console.log('Empty');
                    console.log(element.subjects);
                    var tempArr2 = [];
                    tempArr2.push({ "title": "Subjects Not Found. Add Subjects!", "id": 0 });
                    setSubjects(tempArr2);
                    } else {
                        element.subjects.forEach(element2 => {
                            tempArr.push({ "title": element2.Name, "id": element2.id });
                        });
                    }
                    setSubjects(tempArr);
                } 
            });
            // setValues({ ...values, subject: ''});


            setReset1(false);

        }
        setviewableELearningVideos([]);

    };

    const onSubjectChange = (event, value) => {
        if (value != null) {
            setValues({ ...values, subject: value });
            // console.log("subject = " + value.id + " " + value.title);
            // console.log(value);
        }
        setviewableELearningVideos([]);

    };

    const onClassYearChange = (event, value) => {
        if (value != null) {
            setValues({ ...values, classYear: value.title });
            // console.log(value.title);
        }
        setviewableELearningVideos([]);

    };

    async function clearErrorMsg() {
        setErrorMsg('');
        console.log("Cleared Error msg = " + errorMsg);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpenUpdatedSuccess(false);
        setOpenUploadSuccess(false);
        setOpenError(false);
        setOpenErrorMsg(false);
        clearErrorMsg();

    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function getData() {
        token = localStorage.getItem('token') || null;
        roleType = localStorage.getItem('type') || null;
    }

    function getSubjects() {
        // getData();
        // // console.log("token = " + token);
        // const config = {
        //     headers: { Authorization: `Bearer ${token}` }
        // };

        // HttpCommon.post('/api/getsubjects', null,
        //     config
        // )
        //     .then(response => {
        //         console.log(response.data);

        //         if (response.data.error != null) {
        //             setErrorMsg(response.data.msg);
        //             setOpenErrorMsg(true);
        //             // setOpen(true);
        //         } else if (response.data.subjects != null) {
        //             console.log("user subjects");
        //             console.log(response.data);
        //             // setOpen(true);                        
        //             var subjectNames = [];
        //             response.data.subjects.forEach(element => {
        //                 subjectNames.push({ 'title': element.Name, 'id': element.id });
        //             });
        //             console.log(subjectNames);
        //             setSubjects(subjectNames);

        //         }

        //     }).catch(function (error) {

        //         console.log(error.response);
        //         error.response.data.error.errors.forEach(element => {
        //             setErrorMsg(errorMsg + "\n" + element.message);
        //         });
        //         setOpenErrorMsg(true);
        //     });
        var tempArr = [];
        tempArr.push({ "title": "Select a Institution First!", "id": 0 });
        setSubjects(tempArr);


    }

    useEffect(() => {
        getData();
        // console.log("token = " + token);
        getSubjects();

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (token != null && roleType == 'Teacher') {
            HttpCommon.post('/api/verifyUser', null,
                config
            )
                .then(response => {
                    if (response.data.id != null) {
                        console.log("user Verified");
                        // console.log(response.data);
                        responseData = response.data;
                        setValues({
                            ...values, fName: response.data.firstname, lName: response.data.lastname
                            , nic: response.data.nic, address: response.data.address
                            , contactNo: response.data.telno, id: response.data.id, type: response.data.type
                        });
                        setCenterArr(response.data.centers);
                        // console.log(response.data.centers);
                        var tempCenternamesArr = [];
                        response.data.centers.forEach(element => {
                            if (!(element.isTerminated)) {
                                console.log(tempCenternamesArr);
                                tempCenternamesArr.push({ 'title': element.Name, 'id': element.id });

                            }
                        });
                        setCentersNameArr(tempCenternamesArr);
                        setType(response.data.type);
                        console.log(centersNameArr);

                        // console.log(response.data.type);

                    }
                });
        } else {
            history.push({
                pathname: "/login-page"
            });
        }



    }, []);

    return (
        <div>
            <Header
                brand="Class Manager"

                rightLinks={<HeaderLinks />}
                fixed
                color="white"
                changeColorOnScroll={{
                    height: 200,
                    color: "dark"
                }}
                {...rest}
            />

            <Parallax small image={require("assets/img/landing2.jpg")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div className={classes.brand}>
                                <h1 className={classes.title}>eLearning Videos</h1>

                            </div>
                            <br />

                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8}>
                                <h2 className={classes.title1}>View Videos</h2>
                                <h5 className={classes.description}>
                                    In this Add Videos Page you can add videos to ClassManager Platform And Student can see them.
                                </h5>
                                <br />
                                <form className={classes.root} noValidate>

                                    <Autocomplete
                                        key={values.institutionKey}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onCenterChange}
                                        // defaultValue={values.institution.title}
                                        value={values.institution.title}
                                        options={centersNameArr}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Institution"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={centersNameArr.title}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        key={values.subjectKey}
                                        disabled={reset1}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onSubjectChange}
                                        value={values.subject.title}
                                        options={subjects}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Subject"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={subjects.title}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        key={values.classYearKey}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onClassYearChange}
                                        // defaultValue={values.institution.title}
                                        value={classIds.title}
                                        options={classIds}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Year"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={classIds.title}
                                            />
                                        )}
                                    />
                                </form>
                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleViewVideos()}>
                                    View
                                </ButtonMaterial>

                                {viewableELearningVideos != null && viewableELearningVideos != [] ?
                                    <>
                                        {viewableELearningVideos.map((videoTemp, j) => (
                                            <GridItem xs={12} sm={6} md={12} lg={12}>
                                                <div style={{ marginTop: "40px", marginBottom: "10px" }}>
                                                    <Card>
                                                        <CardHeader color="warning" style={{ marginLeft: "-20px", marginRight: "-20px" }}>
                                                            <h5 className={classes.titleCard2}>{videoTemp.class.center.Name} -{">"}
                                                                {videoTemp.class.subject.Name} -{">"} {videoTemp.class.user.firstname} {videoTemp.class.user.lastname}</h5>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Name"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="name"
                                                                fullWidth
                                                                helperText=""
                                                                value={videoTemp.Name}


                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                id="description"
                                                                label="Description"
                                                                required
                                                                fullWidth
                                                                helperText=""
                                                                multiline
                                                                rows={4}
                                                                variant="outlined"
                                                                value={videoTemp.Description}
                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="O/L Year"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="OL_Year"
                                                                fullWidth
                                                                helperText=""
                                                                value={videoTemp.OL_Year}


                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Updated Date"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="UpdatedAt"
                                                                fullWidth
                                                                helperText=""
                                                                value={videoTemp.UpdatedAt}


                                                            />
                                                        </CardBody>

                                                    </Card>

                                                </div>

                                            </GridItem>)
                                        )}
                                    </>
                                    :
                                    <>
                                    </>
                                }





                                <h2 className={classes.title1}>Add Videos</h2>
                                <h5 className={classes.description}>
                                    In this Add Videos Page you can add videos to ClassManager Platform And Student can view them.
                                </h5>

                                <br />
                                <br />
                                <form className={classes.root} noValidate>

                                    <h5 className={classes.description1}>
                                        Select Classes
                                    </h5>
                                    {/* <Divider orientation="vertical" flexItem /> */}
                                    <br />

                                    <Autocomplete
                                        key={values.institutionKey}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onCenterChange}
                                        // defaultValue={values.institution.title}
                                        value={values.institution.title}
                                        options={centersNameArr}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Institution"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={centersNameArr.title}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        key={values.subjectKey}
                                        disabled={reset1}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onSubjectChange}
                                        disabled={reset1}
                                        // defaultValue={values.institution.title}
                                        value={values.subject.title}
                                        options={subjects}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Subject"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={values.subject.title}
                                            />
                                        )}
                                    />
                                    {/* <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}> */}
                                    {/* <GridContainer justify="center"> */}
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Autocomplete
                                            key={values.classYearKey}
                                            className={classes.margin3}
                                            fullWidth
                                            id="combo-box-demo3"
                                            onChange={onClassYearChange}
                                            // defaultValue={values.institution.title}
                                            value={classIds.title}
                                            options={classIds}
                                            getOptionLabel={(option) => option.title}
                                            // options={centersArr.map((option) => option.title)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Year"
                                                    required
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={classIds.title}
                                                />
                                            )}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClickAddClass(false)}>
                                            Add Class
                                        </ButtonMaterial>
                                    </GridItem>
                                    {addedClassesVideo != null && addedClassesVideo != [] ?
                                        <>
                                            {addedClassesVideo.map((classTemp, j) => (
                                                <GridItem key={j} xs={12} sm={12} md={12} lg={6}>
                                                    <div style={{ marginTop: "40px", marginBottom: "10px" }}>
                                                        <CardHeader color="warning" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                            <div style={{
                                                                position: 'absolute', top: "15px", right: "10px", justifyContent: 'center', borderRadius: "10px",
                                                                backgroundColor: "white", color: "black", padding: "5px", height: "28px"
                                                            }}>

                                                                <Close onClick={() => handleRemove(j, false)} style={{ fontSize: 15 }} />
                                                            </div>
                                                            <h4 className={classes.titleCard2}>{classTemp.institution.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.subject.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.teacher.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.classYear}</h4>
                                                        </CardHeader>
                                                    </div>

                                                </GridItem>)
                                            )}
                                        </>
                                        :
                                        <>
                                        </>
                                    }

                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ textAlign: "left", marginTop: "20px" }}>
                                            <h5 className={classes.description1}>
                                                Enter Video Detailes
                                            </h5>
                                        </div>

                                    </GridItem>

                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Name"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="name"
                                        fullWidth
                                        helperText=""
                                        value={values.name}
                                        onChange={handleChange('name')}


                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        id="description"
                                        label="Description"
                                        required
                                        fullWidth
                                        helperText=""
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        value={values.description}
                                        onChange={handleChange('description')}
                                    />

                                </form>
                                <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={onChangeFile} />
                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => getFile()}>
                                    Add File
                                </ButtonMaterial>
                                <br />
                                <br />

                                {file != "" && file != null ? (
                                    <>
                                        <GridItem xs={12} sm={12} md={12} >
                                            <MuiAlert elevation={6} variant="filled" color="warning" icon={false}>
                                                <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>{file.name} File Seleted</div>
                                                </div>
                                            </MuiAlert>
                                            <div style={{ marginBottom: "10px" }}></div>
                                        </GridItem>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )

                                }
                                <br />


                                {uploadPrecentage > 0 && uploadPrecentage < 101 ? (
                                    <>
                                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center', color: "black" }}>
                                            <div>Do Not Interrupt the Upload</div>
                                        </div>
                                        <BorderLinearProgress variant="determinate" value={uploadPrecentage} />
                                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center', color: "black" }}>
                                            <div>{uploadPrecentage}% Uploaded</div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )

                                }

                                {isUploading ? (
                                    <>
                                        {/* <ButtonMaterial
                                            variant="contained"
                                            className={classes.buttonMaterial3}
                                            onClick={() => handleOnCancel()}
                                        >
                                            Cancel Upload
                                        </ButtonMaterial> */}
                                    </>
                                ) : (
                                    <>
                                        <ButtonMaterial
                                            variant="contained"
                                            className={classes.buttonMaterial2}
                                            onClick={() => handleClickAddeLearningVideo(false)}
                                            disabled={isUploading}
                                        >
                                            Upload Video
                                        </ButtonMaterial>
                                    </>
                                )

                                }




                                <h2 className={classes.title1}>Add Links</h2>
                                <h5 className={classes.description}>
                                    In this Add Links Page you can add Links/Urls to ClassManager Platform And Student can view them.
                                </h5>

                                <br />
                                <br />
                                <form className={classes.root} noValidate>

                                    <h5 className={classes.description1}>
                                        Select Classes
                                    </h5>
                                    {/* <Divider orientation="vertical" flexItem /> */}
                                    <br />

                                    <Autocomplete
                                        key={values.institutionKey}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onCenterChange}
                                        // defaultValue={values.institution.title}
                                        value={values.institution.title}
                                        options={centersNameArr}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Institution"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={values.institution.title}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        key={values.subjectKey}
                                        disabled={reset1}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onSubjectChange}
                                        // defaultValue=''
                                        value={values.subject.title}
                                        options={subjects}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Subject"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={values.subject.title}
                                            />
                                        )}
                                    />
                                    {/* <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}> */}
                                    {/* <GridContainer justify="center"> */}
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Autocomplete
                                            key={values.classYearKey}
                                            className={classes.margin3}
                                            fullWidth
                                            id="combo-box-demo3"
                                            onChange={onClassYearChange}
                                            // defaultValue={values.institution.title}
                                            value={values.classYear.title}
                                            options={classIds}
                                            getOptionLabel={(option) => option.title}
                                            // options={centersArr.map((option) => option.title)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Year"
                                                    required
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={values.classYear.title}
                                                />
                                            )}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClickAddClass(true)}>
                                            Add Class
                                        </ButtonMaterial>
                                    </GridItem>
                                    {addedClassesLink != null && addedClassesLink != [] ?
                                        <>
                                            {addedClassesLink.map((classTemp, j) => (
                                                <GridItem key={j} xs={12} sm={6} md={6} lg={6}>
                                                    <div style={{ marginTop: "40px", marginBottom: "10px" }}>
                                                        <CardHeader color="warning" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                            <div style={{
                                                                position: 'absolute', top: "15px", right: "10px", justifyContent: 'center', borderRadius: "10px",
                                                                backgroundColor: "white", color: "black", padding: "5px", height: "28px"
                                                            }}>

                                                                <Close onClick={() => handleRemove(j, true)} style={{ fontSize: 15 }} />
                                                            </div>
                                                            <h4 className={classes.titleCard2}>{classTemp.institution.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.subject.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.teacher.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.classYear}</h4>
                                                        </CardHeader>
                                                    </div>

                                                </GridItem>)
                                            )}
                                        </>
                                        :
                                        <>
                                        </>
                                    }

                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ textAlign: "left", marginTop: "20px" }}>
                                            <h5 className={classes.description1}>
                                                Enter Video Detailes
                                            </h5>
                                        </div>

                                    </GridItem>

                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Name"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="name"
                                        fullWidth
                                        helperText=""
                                        value={values.name}
                                        onChange={handleChange('name')}


                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        id="description"
                                        label="Description"
                                        required
                                        fullWidth
                                        helperText=""
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        value={values.description}
                                        onChange={handleChange('description')}
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Link"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="link"
                                        fullWidth
                                        helperText=""
                                        value={values.link}
                                        onChange={handleChange('link')}


                                    />

                                </form>
                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClickAddeLearningVideo(true)}>
                                    Upload Link
                                </ButtonMaterial>



                                <Snackbar open={openUpdatedSuccess} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        User Detailes Updated Successfully !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openUploadSuccess} autoHideDuration={60000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        eLearning Video Uploaded Successfully !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        New Institution Added Succesfully !

                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openError} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="error">
                                        Error Occured !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openErrorMsg} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="error">
                                        {errorMsg}
                                    </Alert>
                                </Snackbar>


                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

const classIds = [
    { title: "2021" },
    { title: "2022" },
    { title: "2023" },
    { title: "2024" },
    { title: "2025" },
    { title: "2026" },
    { title: "2027" },
];


