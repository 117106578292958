import React, { useEffect } from "react";
// @material-ui/core components
import { withStyles, makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import PreLoader2 from "components/Loading/PreLoader2.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'


import ButtonMaterial from '@material-ui/core/Button';

import image from "assets/img/login.jpg";
import image2 from "assets/img/LOGO-Icon.png";
import { IconButton, Snackbar } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import HttpCommon from "http-common.js";
import { useHistory } from 'react-router-dom';
// import Alert from "@material-ui/lab/Alert";
// import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(styles);

const ValidationTextField = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: '#3952C7',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: '#3952C7',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important', // override inline-style
      borderColor: '#B1129D',
    },
    '& label.Mui-focused': {
      color: '#B1129D',
    },
    //   '& .MuiInput-underline:after': {
    //     borderBottomColor: 'green',
    //   },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#3952C7',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        // borderColor: '#3952C7',
        borderColor: '#B1129D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#B1129D',
      },
    },
    paddingBottom: "20px"
  },
})(TextField);


export default function LoginPage(props) {
  const history = useHistory();

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const [values, setValues] = React.useState({
    nic: '',
    password: '',
    showPassword: false,
  });
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [loading, setLoading] = React.useState(false);


  useEffect(() => {
    localStorage.removeItem("token");

  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setOpen2(false);
    setOpen3(false);

  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    console.log([prop] + ":" + event.target.value);
    // console.log("test1234");
  };

  function keyPress(e){
    if(e.keyCode == 13){
       console.log('value', e.target.value);
       // put the login here
       verifyUser()
    }
 }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  function setData(token) {
    localStorage.setItem('token', token);
    console.log("token set Login = ");
    console.log(token);
  }

  function verifyUser() {
    setLoading(true);
    if (values.nic != '' && values.password != '') {
      HttpCommon.post('/auth/v1/login', null, {
        params: {
          username: values.nic,
          password: values.password
        }
      })
        .then(response => {
          console.log(response);
          console.log("user ID ");
          setLoading(false);

          if (response.data.id != null) {
            console.log(response.data.id);
    localStorage.setItem('type', response.data.type);
            setData(response.data.token);
            history.push({
              pathname: "/landing-page",
              data: response.data.id
            });
          } else {
            setOpen(true);
          }
        }).catch(function (error) {
          setLoading(false);

          console.log(error);
          // console.log(error.data);
          // console.log(error.response.status);
          if (error.response != null && error.response.status == 401) {
            setOpen(true);

          } else {
            setOpen3(true);

          }
        });
    } else {
      setLoading(false);

      setOpen2(true);
    }

  }

  return (
    <>
      { loading ?
        <PreLoader2 />
        :
        <>
          <div>
            <Header
              absolute
              color="dark"
              brand="Class Manager"
              rightLinks={<HeaderLinks />}
              {...rest}
            />
            <div
              className={classes.pageHeader}
              style={{
                backgroundImage: "url(" + image + ")",
                backgroundSize: 'cover',
                overflow: 'hidden',
              }}
            >
              <MediaQuery minWidth={900}>
                <div className={classes.container}>
                  <GridContainer justify="flex-start">
                    <GridItem xs={12} sm={12} md={6}>
                      <Card className={classes[cardAnimaton]}>
                        <form className={classes.form}>
                          <div
                            className={classes.imgContainer}
                            style={{
                              backgroundImage: "url(" + image2 + ")",
                              width: "100px",
                              height: "150px",
                              backgroundSize: "450px",
                              backgroundPosition: "top center",
                            }}
                          />
                          <h4 className={classes.title}>Log in to your Account</h4>
                          <p className={classes.divider}>Log in with your data that you entered during your registration</p>
                          <CardBody>

                            <ValidationTextField
                              // className={classes.margin}
                              label="NIC"
                              variant="outlined"
                              // defaultValue="Success"
                              id="nic"
                              fullWidth="true"
                              value={values.nic}
                              onChange={handleChange('nic')}
                              helperText=""
                              endAdornment={
                                <InputAdornment position="end">
                                  <Email className={classes.inputIconsColor} />
                                </InputAdornment>
                              }
                            />
                            <FormControl fullWidth className={clsx(classes.margin, classes.root1)} variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                required
                                value={values.password}
                                onKeyDown={keyPress}
                                onChange={handleChange('password')}
                                fullWidth="true"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                labelWidth={70}
                              />
                            </FormControl>
                          </CardBody>
                          <CardFooter className={classes.cardFooter}>
                            <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => verifyUser()}>
                              Get Started
                    </ButtonMaterial>
                            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center"
                              }}  >
                              <Alert onClose={handleClose} severity="error">
                                Enter Correct Credentials !
                                    </Alert>
                            </Snackbar>
                            <Snackbar open={open2} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center"
                              }}  >
                              <Alert onClose={handleClose} severity="error">
                                Enter Both NIC And Password !
                                    </Alert>
                            </Snackbar>
                            <Snackbar open={open3} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center"
                              }}  >
                              <Alert onClose={handleClose} severity="error">
                                Unknown Error Occured !
                                    </Alert>
                            </Snackbar>
                          </CardFooter>
                        </form>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
              </MediaQuery>

              <MediaQuery maxWidth={900}>z
          <div className={classes.container2}>
                  <GridContainer justify="flex-start">
                    <GridItem xs={12} sm={12} md={6}>
                      <Card className={classes[cardAnimaton]}>
                        <form className={classes.form}>
                          <div
                            className={classes.imgContainer}
                            style={{
                              backgroundImage: "url(" + image2 + ")",
                              width: "100px",
                              height: "150px",
                              backgroundSize: "450px",
                              backgroundPosition: "top center",
                            }}
                          />
                          <h4 className={classes.title}>Log in to your Account</h4>
                          <p className={classes.divider}>Log in with your data that you entered during your registration</p>
                          <CardBody>

                            <ValidationTextField
                              // className={classes.margin}
                              label="NIC"
                              variant="outlined"
                              // defaultValue="Success"
                              id="nic"
                              fullWidth="true"
                              value={values.nic}
                              onChange={handleChange('nic')}
                              helperText=""
                              endAdornment={
                                <InputAdornment position="end">
                                  <Email className={classes.inputIconsColor} />
                                </InputAdornment>
                              }
                            />
                            <FormControl fullWidth className={clsx(classes.margin, classes.root1)} variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                required
                                value={values.password}
                                onChange={handleChange('password')}
                                fullWidth="true"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                labelWidth={70}
                              />
                            </FormControl>
                          </CardBody>
                          <CardFooter className={classes.cardFooter}>
                            <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => verifyUser()}>
                              Get Started
                    </ButtonMaterial>
                            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center"
                              }}  >
                              <Alert onClose={handleClose} severity="error">
                                Enter Correct Credentials !
                                    </Alert>
                            </Snackbar>
                            <Snackbar open={open2} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center"
                              }}  >
                              <Alert onClose={handleClose} severity="error">
                                Enter Both NIC And Password !
                                    </Alert>
                            </Snackbar>
                            <Snackbar open={open3} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center"
                              }}  >
                              <Alert onClose={handleClose} severity="error">
                                Unknown Error Occured !
                                    </Alert>
                            </Snackbar>
                          </CardFooter>
                        </form>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>

              </MediaQuery>

              <Footer />
            </div>
          </div>

        </>
      }
    </>


  );
}
