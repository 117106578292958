import { container, title } from "assets/jss/material-kit-react.js";

const contactPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "80px",
    color: "black",
    paddingBottom: "200px",
    marginRight: "5%",
    // marginLeft: "100%",
  },
  container2: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: "#FFFFFF",
    paddingBottom: "200px",
    // marginLeft: "50%",
    // marginRight: "100%",
  },

  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(0, 0, 0, 0)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#000000"
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%"
      
    }
  },
  form: {
    margin: "0",
    paddingLeft: "30px",
    paddingRight: "30px"
    
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px"
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },
  divider: {
    marginTop: "0px",
    marginBottom: "-20px",
    textAlign: "center"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0"
  },
  inputIconsColor: {
    color: "#495057"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    marginLeft: "30px",
    minHeight: "32px",
    textDecoration: "none",
    fontSize: "35px",
    textAlign: "center"
  },
  title2: {
    ...title,
    marginBottom: "-20px",
    marginTop: "30px",
    marginLeft: "30px",
    // minHeight: "32px",
    textDecoration: "none",
    fontSize: "25px",
    textAlign: "center"
  },
  imgContainer: {
    height: "225px",
    width: "75px",
    paddingLeft: "50%",
    paddingRight: "50%",
    // paddingTop: "-850px"
    marginTop: "30px"
  },
  buttonMaterial2: {
    marginTop: '10px',
    marginBottom: '10px',
    color: "white",
    width: "150px",
    height: "50px",
    fontSize: "15px",
    marginLeft: "250px" ,
    background: "rgba(60, 95, 217, 0.9)",
    transition: "all 150ms ease 0s",
    "&:hover,&:focus": {
        color: "white",
        background: "rgba(60, 95, 217, 0.7)",
        marginTop: '7px',
        marginBottom: '13px',
    }
},
buttonMaterial3: {
  marginTop: '10px',
  color: "white",
  width: "150px",
  height: "50px",
  fontSize: "15px",
  background: "rgba(60, 95, 217, 0.9)",
  transition: "all 150ms ease 0s",
  "&:hover,&:focus": {
      color: "white",
      background: "rgba(60, 95, 217, 0.7)",
      marginTop: '7px',
      marginBottom: '3px',
  }
},
};

export default contactPageStyle;
