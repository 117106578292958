import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import HttpCommon from "http-common.js";

import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom';



const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {

  const history = useHistory();

  const classes = useStyles();
  const { ...rest } = props;
  var token;
  function getData() {
    token = localStorage.getItem('token') || null;
  }

  useEffect(() => {
    getData();
    // console.log("token = " + token);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    if (token != null) {
      HttpCommon.post('/api/verifyUser', null,
        config
      )
        .then(response => {
          if (response.data.id != null) {
            console.log("user Verified");
            console.log(response.data);
            localStorage.setItem('type', response.data.type);

          }
        });
    } else {
      // history.push({
      //   pathname: "/login-page"
      // });
    }



  }, []);


  return (
    <div>
      <Header
        brand="Class Manager"
        rightLinks={<HeaderLinks />}
        fixed
        color="dark"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <MediaQuery minWidth={900}>
        <Parallax image={require("assets/img/landing3.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.brand}>
                  <h1 className={classes.title}>Welcome To</h1>
                  <br />
                  <h1 className={classes.title}>Class Manager</h1>
                  <h3 className={classes.subtitle}>
                    A Perfect Online Platform for Students And Teachers.
                </h3>
                </div>
                {/* <h1 className={classes.title}>Welcome To</h1>
            <br />
              <h1 className={classes.title}>Class Manager</h1> */}
                {/* <h4 className={classes.title}>
                Class Manager is a online teaching platform that provide with
                many benifits. Every one can access ClassManager via Mobile, Laptop,
                Desktop. Teachers and Students can teach and learn any where they like. */}
                {/* 
                Every landing page needs a small description after the big bold
                title, that{"'"}s why we added this text here. Add here all the
                information that can make you or your product create the first
                impression. */}
                {/* </h4> */}
                <br />

              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
      </MediaQuery>

      <MediaQuery maxWidth={900}>
        <Parallax image={require("assets/img/landing5.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.brand}>
                  <h1 className={classes.title2}>Welcome To</h1>
                  <br />
                  <h1 className={classes.title2}>Class Manager</h1>
                  <h3 className={classes.subtitle2}>
                    A Perfect Online Platform for Students And Teachers.
                </h3>
                </div>
                {/* <h1 className={classes.title}>Welcome To</h1>
            <br />
              <h1 className={classes.title}>Class Manager</h1> */}
                {/* <h4 className={classes.title}>
                Class Manager is a online teaching platform that provide with
                many benifits. Every one can access ClassManager via Mobile, Laptop,
                Desktop. Teachers and Students can teach and learn any where they like. */}
                {/* 
                Every landing page needs a small description after the big bold
                title, that{"'"}s why we added this text here. Add here all the
                information that can make you or your product create the first
                impression. */}
                {/* </h4> */}
                <br />

              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
      </MediaQuery>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          {/* <TeamSection /> */}
          <WorkSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
