import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';


import ButtonMaterial from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';

import styles from "assets/jss/material-kit-react/views/signUpPage.js";

// Sections for this page
import ProductSection from "../LandingPage/Sections/ProductSection.js";
import TeamSection from "../LandingPage/Sections/TeamSection.js";
import WorkSection from "../LandingPage/Sections/WorkSection.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddAlert from "@material-ui/icons/AddAlert";
import { Cancel } from "@material-ui/icons";

const dashboardRoutes = [];

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: '#3952C7',
            },
            // '&.Mui-focused fieldset': {
            //   borderColor: 'green',
            // },
        },
    },
})(TextField);

const useStyles = makeStyles(styles);
// const useStyles = makeStyles((theme) => ({
//     root: {
//       '& .MuiTextField-root': {
//         margin: theme.spacing(1),
//         width: '25ch',
//       },
//     },
//   }));

export default function AddWorkersPage(props) {
    const classes = useStyles();
    const { ...rest } = props;

    const [values, setValues] = React.useState({
        institution: '',
        addressLine1IN: '',
        addressLine2IN: '',
        addressLine3IN: '',
        cityIN: '',
        provinceIN: '',
        contactNoIN: '',
        fName: '',
        lName: '',
        nic: '',
        password: '',
        gender: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        province: '',
        contactNo: '',
        showPassword: false,
    });
    const [selectedDate, setSelectedDate] = React.useState();

    const [open, setOpen] = React.useState(false);


    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <Header
                brand="Class Manager"
                rightLinks={<HeaderLinks />}
                fixed
                color="black"
                changeColorOnScroll={{
                    height: 300,
                    color: "white"
                }}
                {...rest}
            />

            <Parallax small image={require("assets/img/landing2.jpg")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div className={classes.brand}>
                                <h1 className={classes.title}>Add Workers</h1>

                            </div>
                            {/* <h1 className={classes.title}>Welcome To</h1>
            <br />
              <h1 className={classes.title}>Class Manager</h1> */}
                            {/* <h4 className={classes.title}>
                Class Manager is a online teaching platform that provide with
                many benifits. Every one can access ClassManager via Mobile, Laptop,
                Desktop. Teachers and Students can teach and learn any where they like. */}
                            {/* 
                Every landing page needs a small description after the big bold
                title, that{"'"}s why we added this text here. Add here all the
                information that can make you or your product create the first
                impression. */}
                            {/* </h4> */}
                            <br />

                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8}>
                                <h2 className={classes.title1}>Let{"'"}s start Registration</h2>
                                <h5 className={classes.description}>
                                    Class Manager is a online teaching platform that provide with
                                    many benifits. Every one can access ClassManager via Mobile, Laptop,
                                    Desktop. Teachers and Students can teach and learn any where they like.
          </h5>

                                <br />
                                <br />
                                <form className={classes.root} noValidate>

                                    {/* <h5 className={classes.description1}>
                                        Owner{"'"}s Detailes
                                     </h5> */}
                                    {/* <Divider orientation="vertical" flexItem /> */}
                                    <br />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="First Name"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="fName"
                                        fullWidth="true"
                                        helperText=""

                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Last Name"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="lName"
                                        fullWidth="true"
                                        helperText=""
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="NIC"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="nic"
                                        fullWidth="true"
                                        helperText=""
                                    />
                                    <FormControl fullWidth className={clsx(classes.margin, classes.root1)} variant="outlined">
                                    <InputLabel required htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        required
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        fullWidth="true"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                    </FormControl>

                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Gender"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="gender"
                                        fullWidth="true"
                                        helperText=""
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Address Line 1"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="addressline1"
                                        fullWidth="true"
                                        helperText=""
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Address Line 2"
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="addressline2"
                                        fullWidth="true"
                                        helperText=""
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Address Line 3"
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="addressline3"
                                        fullWidth="true"
                                        helperText=""
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="City"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="city"
                                        fullWidth="true"
                                        helperText=""
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Province"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="province"
                                        fullWidth="true"
                                        helperText=""
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Contact No"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="contactNo"
                                        fullWidth="true"
                                        helperText=""
                                    />
                                    <Autocomplete
                                        className={classes.margin2}
                                        fullWidth="true"
                                        id="combo-box-demo"
                                        options={workerTypes}
                                        getOptionLabel={(option) => option.title}
                                        // style={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Worker Type" required variant="outlined" />}
                                    />
                                </form>
                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClick()}>
                                    Add Worker
                                        </ButtonMaterial>
                                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        This is a success message !
                                    </Alert>
                                </Snackbar>

                            </GridItem>
                        </GridContainer>

                    </div>


                </div>
            </div>
            <Footer />
        </div>
    );
}

const workerTypes = [
    { title: 'Admin', centerId: 12 },
    { title: 'Manager', year: 22 },
    { title: 'High Level Worker', year: 32 },
    { title: 'Middle Level Worker', year: 42 },
    { title: 'Low Level Worker', year: 52 },

];
