import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/christian.jpg";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import ButtonMaterial from '@material-ui/core/Button';


import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import styles from "assets/jss/material-kit-react/views/pricePage.js";

const useStyles = makeStyles(styles);


function CardList(props) {
    return (
        <ListItem>
            <ListItemIcon>
                <FiberManualRecordIcon style={{ fontSize: 12, color: "black" }} />
            </ListItemIcon>
            <ListItemText
                primary={props.listName}
            />
            <ListItemSecondaryAction>
                <ListItemIcon edge="end" aria-label="delete">
                    {props.icon}
                </ListItemIcon>
            </ListItemSecondaryAction>
        </ListItem>
    );

}


export default function PricePage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);



    return (
        <div>
            <Header
                color="dark"
                brand="Class Manager"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container2}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <h2 className={classes.title}>Choose a Plan</h2>
                                <h5 className={classes.description}>
                                    Class Manager is a online teaching platform that provide with
                                    many benifits. Every one can access ClassManager via Mobile, Laptop,
                                    Desktop. Teachers and Students can teach and learn any where they like.
          </h5>
                            </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={5}>
                                <div className={classes.card}>
                                    <div>
                                        <h4 className={classes.title_card}>Start Up</h4>
                                        <h4 className={classes.description_card}>For Small Group Classes</h4>
                                        <List >

                                            <CardList listName={"Add Upto 100 Students"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Exam Reports"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Homeworks"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Students Attendence"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"View Payments"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"E-Learning Videos"} icon={<CloseIcon style={{ color: "red" }} />} />
                                            <CardList listName={"Sending SMS"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Progress Reports"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Zoom Links"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Other URL Links"} icon={<DoneIcon style={{ color: "green" }} />} />

                                        </List>
                                        <div >
                                        <h5 style={{ fontSize: "14px", color: "red", marginLeft: "20px"}}>* If you added E Learning Videos it'll cost extra money.</h5>
                                        </div>
                                        <br/>
                                        <div className={classes.centerContainer}>
                                        <h5 style={{ fontSize: "14px" }}>Starting at</h5>
                                        </div>
                                        <div className={classes.centerContainer}>
                                        <h4 style={{ color: "#39C755" , fontSize: "35px", fontWeight: "400" }}>Free</h4>
                                        </div>
                                        <div className={classes.centerContainer}>
                                        <ButtonMaterial variant="outlined" className={classes.buttonMaterial}>
                                            Start Free
                                        </ButtonMaterial>
                                        </div>
                                        
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={5}>
                            <div className={classes.card}>
                                    <div>
                                        <h4 className={classes.title_card}>Pay As You Go</h4>
                                        <h4 className={classes.description_card}>For Open Classes</h4>
                                        <List >

                                        <CardList listName={"Unlimited Students"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Exam Reports"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Homeworks"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Students Attendence"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"View Payments"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"E-Learning Videos"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Sending SMS"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Progress Reports"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Zoom Links"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Other URL Links"} icon={<DoneIcon style={{ color: "green" }} />} />

                                        </List>
                                        {/* <br/> */}
                                        <div className={classes.centerContainer}>
                                        <h5 style={{ fontSize: "14px" }}>As Low as</h5>
                                        </div>
                                        <div className={classes.centerContainer}>
                                        <h4 style={{ color: "#39C755" , fontSize: "25px", fontWeight: "400" }}>Rs 10/Student</h4>
                                        </div>
                                        <div className={classes.centerContainer}>
                                        <h4 style={{ color: "#39C755" , fontSize: "25px", fontWeight: "400" }}>Rs 2/E-Learning Video</h4>
                                        </div>
                                        <div className={classes.centerContainer}>
                                        <h4 style={{ color: "#39C755" , fontSize: "20px", fontWeight: "400" }}>Per Month</h4>
                                        </div>
                                        <div className={classes.centerContainer}>
                                        <ButtonMaterial variant="contained" className={classes.buttonMaterial2}>
                                            Get Started
                                        </ButtonMaterial>
                                        </div>
                                        
                                    </div>
                                </div>
                            </GridItem>
                            {/* <GridItem xs={12} sm={12} md={4}>
                            <div className={classes.card}>
                                    <div>
                                        <h4 className={classes.title_card}>INSTITUTION</h4>
                                        <h4 className={classes.description_card}>For Large Institutions And Classes</h4>
                                        <List >

                                        <CardList listName={"No Limit for Student"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Exam Reports"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Homeworks"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Students Attendence"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"View Payments"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"E-Learning Videos"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Sending SMS"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Progress Reports"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Zoom Links"} icon={<DoneIcon style={{ color: "green" }} />} />
                                            <CardList listName={"Other URL Links"} icon={<DoneIcon style={{ color: "green" }} />} />

                                        </List>
                                        <br/>
                                        <br/>
                                        <div className={classes.centerContainer}>
                                        <h5 style={{ fontSize: "14px" }}>As Low as</h5>
                                        </div>
                                        <div className={classes.centerContainer}>
                                        <h4 style={{ color: "#39C755" , fontSize: "35px", fontWeight: "400" }}>$25/mo</h4>
                                        </div>
                                        <div className={classes.centerContainer}>
                                        <ButtonMaterial variant="outlined" className={classes.buttonMaterial}>
                                        Get Started
                                        </ButtonMaterial>
                                        </div>
                                        
                                    </div>
                                </div>
                            </GridItem> */}
                        </GridContainer>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}


