import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';

import ButtonMaterial from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';

import styles from "assets/jss/material-kit-react/views/signUpPage.js";

import ReCAPTCHA from "react-google-recaptcha";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import HttpCommon from "http-common.js";
import { useHistory } from "react-router";
import Lottie from "react-lottie";

// import * as location from "./jsons/38435-register.json";
import * as location from "../../components/Loading/jsons/38435-register.json";

import * as success from "../../components/Loading/jsons/8603-profile.json";

const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const dashboardRoutes = [];


const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: '#3952C7',
            },

        },
    },
})(TextField);

const useStyles = makeStyles(styles);




export default function SignUpPage(props) {
    const classes = useStyles();
    let history = useHistory();

    const { ...rest } = props;

    const [values, setValues] = React.useState({
        institution: '',
        addressLineIN: '',
        // addressLine2IN: '',
        // addressLine3IN: '',
        // cityIN: '',
        // provinceIN: '',
        contactNoIN: '',
        fName: '',
        lName: '',
        nic: '',
        password: '',
        // gender: '',
        addressLine: '',
        // addressLine2: '',
        // addressLine3: '',
        // city: '',
        // province: '',
        contactNo: '',
        showPassword: false,
    });

    const [capthchcha, setCapthchcha] = React.useState('');

    const [errorMsg, setErrorMsg] = React.useState(' ');

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [openErrorMsg, setOpenErrorMsg] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);

    function onChange(value) {
        // console.log("Captcha value:", value);
        setCapthchcha(value);

    }

    const handleClickOpen6 = () => {
        if (values.contactNo.length > 10 || values.contactNo.length < 10) {
            setOpen5(true);

        } else {
            if (capthchcha != null && capthchcha != "") {
                if (values.nic != '' && values.password != '' && values.fName != ''
                    && values.lName != '' && values.addressLine != '' && values.contactNo != ''
                    && values.institution != '' && values.addressLineIN != '' && values.contactNoIN != ''
                ) {
                    handleClose();
                    setOpen6(true);

                } else {
                    setOpen2(true);
                }
            } else {
                setOpen4(true);
            }
        }
    };
    const handleClose6 = () => {
        setOpen6(false);
    };

    async function clearErrorMsg() {
        setErrorMsg('');
        console.log("Cleared Error msg = " + errorMsg);
    }

    const handleClick = () => {
        SignUp();
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
        setOpen5(false);
        setOpenErrorMsg(false);
        setOpenSuccess(false);
        clearErrorMsg();
    };


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        console.log([prop] + " = " + event.target.value);

    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function SignUp() {
        setErrorMsg(" ");
        var temp = {
            "nic": values.nic,
            "password": values.password,
            "fname": values.fName,
            "lname": values.lName,
            "address": values.addressLine,
            "tel": values.contactNo + "",
            "centerName": values.institution,
            "centerAddress": values.addressLineIN,
            "centerTel": values.contactNoIN,
        };
        console.log(temp);
        console.log('errorMsg = ' + errorMsg);
        if (values.contactNo.length > 10 || values.contactNo.length < 10 || values.contactNoIN.length > 10 || values.contactNoIN.length < 10) {
            setOpen5(true);

        } else {
            if (capthchcha != null && capthchcha != "") {
                if (values.nic != '' && values.password != '' && values.fName != ''
                    && values.lName != '' && values.addressLine != '' && values.contactNo != ''
                    && values.institution != '' && values.addressLineIN != '' && values.contactNoIN != ''
                ) {
                    setLoading(true);
                    HttpCommon.post('/lgo/createOwner', {

                        "nic": values.nic,
                        "password": values.password,
                        "fname": values.fName,
                        "lname": values.lName,
                        "address": values.addressLine,
                        "tel": values.contactNo + "",
                        "centerName": values.institution,
                        "centerAddress": values.addressLineIN,
                        "centerTel": values.contactNoIN,

                    }).then(response => {
                        console.log(response);
                        console.log("user ID ");
                        if (response.data.owner != null) {
                            // setData(response.data.token);
                            setLoading(false);

                            history.replace({
                                pathname: "/login-page",
                                // state: {
                                //     name: values.name,
                                //     barcode: response.data.barcode,
                                //     birthDay: values.birthDay
                                // }
                            });
                            setOpenSuccess(true);
                            console.log("Student Added");
                        } else if (response.data.error != null) {
                            setLoading(false);
                            setCapthchcha(null);
                            setOpen6(false);
                            setErrorMsg();

                            response.data.error.errors.forEach(element => {
                                setErrorMsg(errorMsg + "\n" + element.message);
                            });
                            setOpenErrorMsg(true);
                        } else if (response.data.errors != null) {
                            setLoading(false);
                            setOpen6(false);
                            setCapthchcha(null);

                            setErrorMsg(" ");

                            response.data.errors.errors.forEach(element => {
                                setErrorMsg(errorMsg + "\n" + element.message);

                            });
                            setOpenErrorMsg(true);
                        } else {
                            setLoading(false);
                            setOpen6(false);
                            setOpen(true);
                            setCapthchcha(null);

                        }
                    }).catch(async function (error) {
                        setLoading(false);
                        setOpen6(false);
                        setCapthchcha(null);

                        console.log(error.response);
                        await clearErrorMsg();
                        if (error.response.data != null) {
                            error.response.data.error.errors.forEach(element => {
                                setErrorMsg(errorMsg + "\n" + element.message);
                            });
                            setOpenErrorMsg(true);
                        }
                        
                    });
                } else {
                    setLoading(false);
                    setOpen6(false);
                    setOpen2(true);
                    setCapthchcha(null);
                }
            } else {
                setLoading(false);
                setOpen6(false);
                setOpen4(true);
                setCapthchcha(null);
            }
        }
    }

    return (

        <>
            { loading ?
                (<div style={{ position: 'absolute', top: '0', left: '0', backgroundColor: '#282c34', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: "white", height: "100%", width: "100%" }}>
                    <Lottie options={defaultOptions1} height={400} width={400} />
                </div>)
                :
                <>

                    <div>
                        <Header
                            brand="Class Manager"
                            rightLinks={<HeaderLinks />}
                            fixed
                            color="white"
                            changeColorOnScroll={{
                                height: 300,
                                color: "dark"
                            }}
                            {...rest}
                        />
                        <Parallax small image={require("assets/img/landing2.jpg")}>
                            <div className={classes.container}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <div className={classes.brand}>
                                            <h1 className={classes.title}>Register In</h1>
                                            <br />
                                            <h1 className={classes.title}>Class Manager</h1>

                                        </div>
                                        <br />

                                    </GridItem>
                                </GridContainer>
                            </div>
                        </Parallax>
                        <div className={classNames(classes.main, classes.mainRaised)}>
                            <div className={classes.container}>
                                <div className={classes.section}>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={8}>
                                            <h2 className={classes.title1}>Let{"'"}s start Registration</h2>
                                            <h5 className={classes.description}>
                                                Class Manager is a online teaching platform that provide with
                                                many benifits. Every one can access ClassManager via Mobile, Laptop,
                                                Desktop. Teachers and Students can teach and learn any where they like.
          </h5>

                                            <br />
                                            <br />
                                            <form className={classes.root} noValidate>
                                                <h5 className={classes.description1}>
                                                    Institution{"'"}s Detailes
                                     </h5>
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Name Of Your Own Institution"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="institution"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.institution}
                                                    onChange={handleChange('institution')}

                                                />
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Address"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="addressLineIN"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.addressLineIN}
                                                    onChange={handleChange('addressLineIN')}
                                                />
                                                
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Contact No"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="contactNoIN"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.contactNoIN}
                                                    onChange={handleChange('contactNoIN')}
                                                />
                                                <h5 className={classes.description1}>
                                                    Owner{"'"}s Detailes
                                     </h5>
                                                {/* <Divider orientation="vertical" flexItem /> */}
                                                <br />
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="First Name"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="fName"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.fName}
                                                    onChange={handleChange('fName')}

                                                />
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Last Name"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="lName"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.lName}
                                                    onChange={handleChange('lName')}
                                                />
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="NIC"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="nic"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.nic}
                                                    onChange={handleChange('nic')}
                                                />
                                                <FormControl fullWidth className={clsx(classes.margin, classes.root1)} variant="outlined">
                                                    <InputLabel required htmlFor="outlined-adornment-password">Password</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        type={values.showPassword ? 'text' : 'password'}
                                                        required
                                                        value={values.password}
                                                        onChange={handleChange('password')}
                                                        fullWidth
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        labelWidth={70}
                                                    />
                                                </FormControl>

                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Address"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="addressline"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.addressLine}
                                                    onChange={handleChange('addressLine')}
                                                />
                                               
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Contact No"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="contactNo"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.contactNo}
                                                    onChange={handleChange('contactNo')}
                                                />
                                            </form>
                                            <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                                                <ReCAPTCHA
                                                    sitekey="6LfEmcgaAAAAAFxHHwbMoxJT1CRH5tyVRAmBYHhg"
                                                    onChange={onChange}
                                                />
                                            </div>

                                            <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => SignUp()}>
                                                Sign Up
                                        </ButtonMaterial>

                                            <Snackbar open={openSuccess} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="success">
                                                    Student Added Success Fully! Barcode No is.
                                        </Alert>
                                            </Snackbar>
                                            <Snackbar open={openErrorMsg} autoHideDuration={60000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    {errorMsg}
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Enter Correct Credentials !
                                        </Alert>
                                            </Snackbar>
                                            <Snackbar open={open2} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Enter All *Required Fields !
                                        </Alert>
                                            </Snackbar>
                                            <Snackbar open={open3} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Unknown Error Occured !
                                        </Alert>
                                            </Snackbar>
                                            <Snackbar open={open4} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Check I'm not a robot Box !
                                        </Alert>
                                            </Snackbar>
                                            <Snackbar open={open5} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Contact No Required 10 Digits Only !
                                        </Alert>
                                            </Snackbar>

                                        </GridItem>
                                    </GridContainer>

                                </div>


                            </div>
                        </div>
                        <Footer />
                    </div>
                </>
            }
        </>
    );
}
