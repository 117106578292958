import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';


import ButtonMaterial from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';

import styles from "assets/jss/material-kit-react/views/signUpPage.js";

// Sections for this page
import ProductSection from "../LandingPage/Sections/ProductSection.js";
import TeamSection from "../LandingPage/Sections/TeamSection.js";
import WorkSection from "../LandingPage/Sections/WorkSection.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddAlert from "@material-ui/icons/AddAlert";
import { Cancel } from "@material-ui/icons";

import HttpCommon from "http-common.js";
import { useHistory } from 'react-router-dom';


const dashboardRoutes = [];

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: '#3952C7',
            },
            // '&.Mui-focused fieldset': {
            //   borderColor: 'green',
            // },
        },
    },
})(TextField);

const useStyles = makeStyles(styles);


export default function AddTeachersPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const history = useHistory();

    const [values, setValues] = React.useState({

        fName: '',
        lName: '',
        nic: '',
        password: '',
        address: '',
        contactNo: '',
        showPassword: false,
    });

    const [errorMsg, setErrorMsg] = React.useState(' ');


    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openErrorMsg, setOpenErrorMsg] = React.useState(false);
    const [openUpdatedSuccess, setOpenUpdatedSuccess] = React.useState(false);

    var token;
    function getData() {
        token = localStorage.getItem('token') || null;
    }

    useEffect(() => {
        getData();
        // console.log("token = " + token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (token != null) {
            HttpCommon.post('/api/verifyUser', null,
                config
            )
                .then(response => {
                    if (response.data.id != null) {
                        console.log("user Verified");
                        console.log(response.data);
                    }
                });
        } else {
            history.push({
                pathname: "/login-page"
            });
        }
    }, []);

    const handleClickAddTeacher = () => {
        getData();
        // console.log("token = " + token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (values.nic != '' && values.password != '' && values.fName != ''
        && values.lName != '' && values.address != '' && values.contactNo != ''
        ) {
            HttpCommon.post('/api/register', {            
                "nic": values.nic,
                "password": values.password,
                "firstname": values.fName,
                "lastname": values.lName,
                "address": values.address,
                "telno": values.contactNo,
                "type": "Teacher"
            },
                config
            )
                .then(response => {
                    console.log(response.data);
                    if (response.data.error != null) {
                        setErrorMsg(response.data.msg);
                        setOpenErrorMsg(true);
                        // setOpen(true);
                    } else if (response.data.user != null) {
                        console.log("user Updated");
                        console.log(response.data);
                        setOpen(true);
                        // setOpen(true);
                    }
                    setValues({ ...values, nic: '', password: '', fName: '', lName: '', address: '', contactNo: ''  });

                }).catch(function (error) {

                    console.log(error.response);
                    if (error.response.data.error != null) {
                        error.response.data.error.errors.forEach(element => {
                            setErrorMsg(errorMsg + "\n" + element.message);
                        });
                        setOpenErrorMsg(true);
                    } else if(error.response.data != null) {
                        if (error.response.data == 'Not Found') {
                            setErrorMsg("This Teacher Already Exists");
                        setOpenErrorMsg(true);
                        } else {
                            setErrorMsg(error.response.data);
                        setOpenErrorMsg(true);
                        }
                        

                    } else {
                        setErrorMsg("Unknown Error Occured");
                        setOpenErrorMsg(true);
                    }
                    
                });
        } else {
            setErrorMsg("Enter All Required Field");
            setOpenErrorMsg(true);
        }
    };


    const handleClick = () => {
        setOpen(true);
    };

    async function clearErrorMsg() {
        setErrorMsg('');
        console.log("Cleared Error msg = " + errorMsg);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpenUpdatedSuccess(false);
        setOpenError(false);
        setOpenErrorMsg(false);
        clearErrorMsg();
    };


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <Header
                brand="Class Manager"
                rightLinks={<HeaderLinks />}
                fixed
                color="white"
                changeColorOnScroll={{
                    height: 300,
                    color: "dark"
                }}
                {...rest}
            />

            <Parallax small image={require("assets/img/landing2.jpg")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div className={classes.brand}>
                                <h1 className={classes.title}>Add Teachers</h1>

                            </div>
                            <br />

                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8}>
                                <h2 className={classes.title1}>Let{"'"}s start Registration</h2>
                                <h5 className={classes.description}>
                                    Class Manager is a online teaching platform that provide with
                                    many benifits. Every one can access ClassManager via Mobile, Laptop,
                                    Desktop. Teachers and Students can teach and learn any where they like.
          </h5>

                                <br />
                                <br />
                                <form className={classes.root} noValidate>

                                    <br />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="First Name"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="fName"
                                        fullWidth
                                        helperText=""
                                        value={values.fName}
                                        onChange={handleChange('fName')}

                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Last Name"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="lName"
                                        fullWidth
                                        helperText=""
                                        value={values.lName}
                                        onChange={handleChange('lName')}
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="NIC"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="nic"
                                        fullWidth
                                        helperText=""
                                        value={values.nic}
                                        onChange={handleChange('nic')}
                                    />
                                    <FormControl fullWidth className={clsx(classes.margin, classes.root1)} variant="outlined">
                                        <InputLabel required htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            required
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            fullWidth
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                    </FormControl>

                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Address"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="address"
                                        fullWidth
                                        helperText=""
                                        value={values.address}
                                        onChange={handleChange('address')}
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Contact No"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="contactNo"
                                        fullWidth
                                        helperText=""
                                        value={values.contactNo}
                                        onChange={handleChange('contactNo')}
                                    />
                                    {/* <Autocomplete
                                        className={classes.margin2}
                                        fullWidth="true"
                                        id="combo-box-demo"
                                        options={subjects}
                                        getOptionLabel={(option) => option.title}
                                        // style={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Subject" required variant="outlined" />}
                                    /> */}
                                </form>
                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClickAddTeacher()}>
                                    Add Teacher
                                        </ButtonMaterial>
                                <Snackbar open={openUpdatedSuccess} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        User Detailes Updated Successfully !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        New Teacher Added Succesfully !

                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openError} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="error">
                                        Error Occured !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openErrorMsg} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="error">
                                        {errorMsg}
                                    </Alert>
                                </Snackbar>

                            </GridItem>
                        </GridContainer>

                    </div>


                </div>
            </div>
            <Footer />
        </div>
    );
}

const centers = [
    { title: 'Sasip', centerId: 12 },
    { title: 'Waruna', year: 22 },
    { title: 'Leon', year: 32 },
    { title: 'Sigma', year: 42 },
    { title: 'Guru Gedara', year: 52 },

];

const subjects = [
    { title: 'Maths', centerId: 12 },
    { title: 'Science', year: 22 },
    { title: 'Health', year: 32 },
    { title: 'Sinhala', year: 42 },
    { title: 'History', year: 52 },

];
