import React, { useEffect, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/signUpPage.js";

import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'


import ButtonMaterial from '@material-ui/core/Button';

import image from "assets/img/contactUs2.jpg";
import image2 from "assets/img/LOGO-Icon.png";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
import InvoiceTable from "views/Components/InvoiceTable/InvoiceTable";
import ReactToPrint from "react-to-print";
init("user_Lhc4fNTO1I46QOt1zLM3K");

const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function BillingSuccessPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [open2, setOpen2] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [detailesData, setDetailesData] = React.useState(null);
  const componentRef = useRef();



  let history = useHistory();
  let detailes = ''


  const [values, setValues] = React.useState({

    name: '',
    email: '',
    message: '',
  });

  function getData() {
    detailes = JSON.parse(localStorage.getItem('detailes')) || null;
    setDetailesData(detailes);
    console.log(detailes)
    console.log(detailes.data)
    console.log(detailes.name)
  }

  useEffect(() => {
    getData();

  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
    setOpenSuccess(false);
  }

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })



  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Class Manager"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      {/* <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: 'cover',
          overflow: 'hidden',
        }}
      > */}
      {/* <MediaQuery minWidth={900}> */}
      <div>
        {detailesData != null ?
          <div style={{ padding: "20px", paddingTop: "80px" }}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card className={classes[cardAnimaton]}>
                  <div style={{ padding: "20px" }}>
                    <InvoiceTable isReciept={true} centerPayData={detailesData.centerPayData} name={detailesData.name} address={detailesData.address} />

                  </div>

                  <div>

                    <ReactToPrint documentTitle='Class Manager Invoice'
                      trigger={() => 
                        <div style={{display:"flex", justifyContent:"center", marginBottom:"50px"}} >

                      <ButtonMaterial variant="contained" className={classes.buttonMaterial2} >
                        Print
                      </ButtonMaterial>
                      </div>
                      }
                      content={() => componentRef.current}
                    />
                    <div style={{ display: "none" }}>
                      <ComponentToPrint centerPayData={detailesData.centerPayData} name={detailesData.name} address={detailesData.address} ref={componentRef} />
                      {/* <InvoiceTable centerPayData={detailesData.centerPayData} name={detailesData.name} address={detailesData.address} ref={componentRef} /> */}
                    </div>
                  </div>


                </Card>
              </GridItem>
            </GridContainer>


            <Snackbar open={open2} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}  >
              <Alert onClose={handleClose} severity="error">
                Enter All Fields !
            </Alert>
            </Snackbar>
            <Snackbar open={openSuccess} onClose={handleClose} style={{ marginTop: "70px" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}  >
              <Alert onClose={handleClose} severity="success">
                Message Complete Successfully.
          </Alert>
            </Snackbar>
          </div>
          :
          <div style={{ padding: "20px", paddingTop: "80px" }}>
            Error Occoured
          </div>

        }
      </div>


      <Footer />
      {/* </div> */}
    </div>
  );
}

export class ComponentToPrint extends React.PureComponent {

  render() {
    const classes = this.props;
    console.log(this.props.name); // result: 'some_value'
    console.log(this.props.centerPayData); // result: 'some_value'
    console.log(this.props); // result: 'some_value'
    return (
      <>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card >
              <div style={{ padding: "20px" }}>
                <InvoiceTable isReciept={true} centerPayData={this.props.centerPayData} name={this.props.name} address={this.props.address} />


              </div>
            </Card>
          </GridItem>
        </GridContainer>

        {/* <InvoiceTable centerPayData={this.props.centerPayData} name={this.props.name} address={this.props.address} /> */}
      </>

    );
  }
}

