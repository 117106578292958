import React, { useEffect } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import { Divider, Paper, TableContainer } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import Typography from '@material-ui/core/Typography';
import GridItem from 'components/Grid/GridItem';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PaymentButton from '../PaymentButton/PaymentButton';


// Generate Order Data
// function createData(id, date, center, teacher, subject, students, eLearningVideos, amount) {
//     return { id, date, center, teacher, subject, students, eLearningVideos, amount };
// }

// const rows = [
//     createData(0, '16 Mar, 2019', "Sasip", 'Elvis Presley', 'Tupelo, MS', 312, 56, 312.44),
//     createData(1, '16 Mar, 2019', "Sasip", 'Paul McCartney', 'London, UK', 312, 56, 866.99),
//     createData(2, '16 Mar, 2019', "Sasip", 'Tom Scholz', 'Boston, MA', 312, 56, 100.81),
//     createData(3, '16 Mar, 2019', "Sasip", 'Michael Jackson', 'Gary, IN', 312, 56, 654.39),
//     createData(4, '15 Mar, 2019', "Sasip", 'Bruce Springsteen', 'Long Branch, NJ', 312, 56, 212.79),
// ];

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

// history: [
//     { date: '2021', videoCount: 21, studentCount: 41 },
//     { date: '2022', videoCount: 10, studentCount: 121 },
//     { date: '2023', videoCount: 22, studentCount: 21 },
//     { date: '2024', videoCount: 5, studentCount: 52 },
//     { date: '2025', videoCount: 3, studentCount: 62 },

// ],

function createData(id, date, center, teacher, subject, students, eLearningVideos, amount, pricePerStudent, pricePerELearning, history) {
    return {
        id, date, center, teacher, subject, students, eLearningVideos, amount, pricePerStudent, pricePerELearning, history: history
    };
}

// function createData(name, calories, fat, carbs, protein, price) {
//     return {
//         name,
//         calories,
//         fat,
//         carbs,
//         protein,
//         price,
//         history: [
//             { date: '2020-01-05', customerId: '11091700', amount: 3 },
//             { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
//         ],
//     };
// }

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(props.isReciept);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {/* <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell> */}
                <TableCell component="th" scope="row">
                    {row.date}
                </TableCell>
                <TableCell>{row.center}</TableCell>
                <TableCell>{row.teacher}</TableCell>
                <TableCell>{row.subject}</TableCell>
                <TableCell align="center">{row.students}</TableCell>
                <TableCell align="center">{row.eLearningVideos}</TableCell>
                <TableCell align="right">{row.amount}.00</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Class Detailes
                </Typography>
                            <Table size="medium" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>O/L Year</TableCell>
                                        <TableCell align="center">Video Count</TableCell>
                                        <TableCell align="center">Student Count</TableCell>
                                        <TableCell align="center">Total price (Rs)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.id + historyRow.date + historyRow.studentCount}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell align="center">{historyRow.videoCount}</TableCell>
                                            <TableCell align="center">{historyRow.studentCount}</TableCell>
                                            <TableCell align="center">
                                                {(historyRow.studentCount * historyRow.videoCount * row.pricePerELearning) + (historyRow.studentCount * row.pricePerStudent)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

// Row.propTypes = {
//     row: PropTypes.shape({
//         calories: PropTypes.number.isRequired,
//         carbs: PropTypes.number.isRequired,
//         fat: PropTypes.number.isRequired,
//         history: PropTypes.arrayOf(
//             PropTypes.shape({
//                 amount: PropTypes.number.isRequired,
//                 customerId: PropTypes.string.isRequired,
//                 date: PropTypes.string.isRequired,
//             }),
//         ).isRequired,
//         name: PropTypes.string.isRequired,
//         price: PropTypes.number.isRequired,
//         protein: PropTypes.number.isRequired,
//     }).isRequired,
// };

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
//     createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
//     createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
//     createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
// ];

let rows = [];

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

export default function InvoiceTable(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const [rowsData, setRowsData] = React.useState([]);
    const [centers, setCenters] = React.useState([]);
    const [totStudent, setTotStudent] = React.useState(0);
    const [totVideos, setTotVideos] = React.useState(0);

    console.log(props.centerPayData)
    console.log(props)

    var d = new Date();
    var n = d.getUTCDate()
    console.log(d.toUTCString())
    var myArray = d.toUTCString().split(" ");


    function calculateTotal() {

        var totStudentCount = 0;
        var totVideoCount = 0;
        var tempCenterArr = [];

        for (let index = 0; index < props.centerPayData.details.length; index++) {
            const element = props.centerPayData.details[index];
            tempCenterArr.push({ id: element.id, name: element.Name })

            for (let index2 = 0; index2 < element.months.length; index2++) {
                const element2 = element.months[index2];

                totStudentCount += element2.data.payments;
                totVideoCount += element2.data.eLearning;
                console.log(totStudentCount)
                console.log(totVideoCount)


            }

            if (index == props.centerPayData.details.length - 1) {
                setTotStudent(totStudentCount);
                setTotVideos(totVideoCount);
                setCenters(tempCenterArr);
            }


        }

    }

    useEffect(() => {
        setRowsData(rows)

    }, [rows]);
    useEffect(() => {
        rows = []
        calculateTotal();

        for (let index = 0; index < props.centerPayData.details.length; index++) {
            const element = props.centerPayData.details[index];

            for (let index2 = 0; index2 < element.months.length; index2++) {
                const element2 = element.months[index2];


                for (let index3 = 0; index3 < element2.data.detial.length; index3++) {
                    const element3 = element2.data.detial[index3];
                    console.log('start')


                    for (let index4 = 0; index4 < element3.classes.length; index4++) {
                        const element4 = element3.classes[index4];
                        var tempHistory = [];
                        var paymentCount = 0;
                        var eLearningVideosCount = 0;
                    console.log('start2')


                        if (element4.paymentCount.length == 0) {
                            rows.push(createData(index4, element2.year + '-' + (("0" + element2.month).slice(-2)), element.Name, (element4.user.firstname + ' ' + element4.user.lastname), element4.subject.Name, 0, 0,
                                0,
                                props.centerPayData.pricePerStudent, props.centerPayData.pricePerELearning, tempHistory));
                    console.log('start3')

                        }

                        for (let index6 = 0; index6 < element4.paymentCount.length; index6++) {
                            const element5 = element4.paymentCount[index6];
                            var tempKey = Object.keys(element5)[0]

                            paymentCount += element5[tempKey];

                            for (let index5 = 0; index5 < element4.videoCount.length; index5++) {
                                const element6 = element4.videoCount[index5];
                                console.log('eLearningVideosCount')
                                console.log(eLearningVideosCount)


                                if (Object.keys(element5)[0] == Object.keys(element6)[0]) {
                                    eLearningVideosCount += (element5[tempKey] * element6[tempKey]);

                                    tempHistory.push({ date: tempKey, videoCount: element6[tempKey], studentCount: element5[tempKey] });
                                    break;
                                } else if (index5 == element4.videoCount.length - 1) {
                                    tempHistory.push({ date: tempKey, videoCount: 0, studentCount: element5[tempKey] });

                                }

                            }
                            if (index6 == element4.paymentCount.length - 1) {
                                console.log('sdfsf')
                                console.log('tempHistory')
                                console.log(tempHistory)
                                rows.push(createData(index4, element2.year + '-' + (("0" + element2.month).slice(-2)), element.Name, (element4.user.firstname + ' ' + element4.user.lastname), element4.subject.Name, paymentCount, eLearningVideosCount,
                                    (paymentCount * props.centerPayData.pricePerStudent) + (eLearningVideosCount * props.centerPayData.pricePerELearning),
                                    props.centerPayData.pricePerStudent, props.centerPayData.pricePerELearning, tempHistory));
                                    console.log('start4')

                            }

                        }
                        if (index4 == element3.classes.length - 1) {
                            //     console.log('tempHistory')
                            //     console.log(tempHistory)
                            //     rows.push(createData(index3, element2.year + '-' + element2.month, element.Name, (element4.user.firstname + ' ' + element4.user.lastname), element4.subject.Name, element2.data.payments, element2.data.eLearning,
                            //         (element2.data.payments * props.centerPayData.pricePerStudent) + (element2.data.eLearning * props.centerPayData.pricePerELearning),
                            //         props.centerPayData.pricePerStudent, props.centerPayData.pricePerELearning, tempHistory));

                        }



                    }


                }

            }

        }

    }, []);

    return (
        <React.Fragment>
            <Title>ClassManager Billing Detailes</Title>
            <div style={{ color: "black" }}>
                <GridContainer justify="space-between" style={{ margin: "10px", marginTop: "10px" }}>
                    <GridItem xs={12} sm={12} md={3}>
                        <Typography display="block" align="left" component="h2" variant="subtitle1" >
                            ClassManager,
                         </Typography>
                        <Typography display="block" align="left" component="h2" variant="subtitle1" >
                            Niladeniya,
                         </Typography>
                        <Typography display="block" align="left" component="h2" variant="subtitle1" >
                            Hapugala,
                         </Typography>
                        <Typography display="block" align="left" component="h2" variant="subtitle1" >
                            Wackwella,
                         </Typography>
                        <Typography display="block" align="left" component="h2" variant="subtitle1" >
                            Galle.
                         </Typography>
                        <Typography display="block" align="left" component="h2" variant="subtitle1" gutterBottom>
                            +94 768099225
                         </Typography>

                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <Typography display='inline' component="h2" variant="body2" gutterBottom>
                            Date Issued&nbsp;&nbsp;:&nbsp;&nbsp;
                        </Typography>
                        <Typography display='inline' component="h2" variant="subtitle2" gutterBottom>
                            {myArray[1]} {myArray[2]} {myArray[3]}
                        </Typography>
                    </GridItem>
                </GridContainer>

                <Divider />

                <GridContainer justify="space-between" style={{ margin: "10px", marginTop: "10px" }}>
                    <GridItem xs={12} sm={12} md={3}>
                        <Typography align="left" component="h2" variant="subtitle2" gutterBottom>
                            Invoice To&nbsp;&nbsp;:
                         </Typography>
                        <Typography display="block" align="left" component="h2" variant="subtitle1" >
                            {props.name}
                        </Typography>
                        <Typography display="block" align="left" component="h2" variant="subtitle1" >
                            {props.address != null && props.address.split(',')[0] != '' ? props.address.split(',')[0] + ',' : ''}
                        </Typography>
                        <Typography display="block" align="left" component="h2" variant="subtitle1" >
                            {props.address != null && props.address.split(',')[1] != undefined ? props.address.split(',')[1] + ',' : ''}

                        </Typography>
                        <Typography display="block" align="left" component="h2" variant="subtitle1" >
                            {props.address != null && props.address.split(',')[2] != undefined ? props.address.split(',')[2] + '.' : ''}


                        </Typography>
                        {/* <Typography display="block" align="left" component="h2" variant="subtitle1" >
                            City.
                         </Typography> */}
                    </GridItem>
                    <GridItem justify="flex-start" xs={12} sm={12} md={4}>
                        <Typography align="left" component="h2" variant="subtitle2" gutterBottom>
                            Payment Detailes&nbsp;&nbsp;:
                         </Typography>
                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            <Typography align="left" display='inline' component="h2" variant="body2" gutterBottom>
                                Total Payment &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                        </Typography>
                            <Typography align="left" display='inline' component="h2" variant="subtitle2" gutterBottom>
                                Rs {(totStudent * props.centerPayData.pricePerStudent) + (totVideos * props.centerPayData.pricePerELearning)}.00
                        </Typography>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            <Typography align="left" display='inline' component="h2" variant="body2" gutterBottom>
                                Total Student Count &nbsp;&nbsp;:&nbsp;&nbsp;
                        </Typography>
                            <Typography align="left" display='inline' component="h2" variant="subtitle2" gutterBottom>
                                {totStudent}
                            </Typography>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            <Typography align="left" display='inline' component="h2" variant="body2" gutterBottom>
                                Total Videos Count &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                        </Typography>
                            <Typography align="left" display='inline' component="h2" variant="subtitle2" gutterBottom>
                                {totVideos}
                            </Typography>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>

            <div >
                {/* <div style={{marginLeft:"-60px", marginRight:"-60px"}}> */}
                {/* <TableContainer component={Paper}>
                    <Table size="medium">
                        <TableHead >
                            <TableRow style={{backgroundColor:"#ECEBEB"}} >
                                <TableCell>Date</TableCell>
                                <TableCell>Center</TableCell>
                                <TableCell>Teacher</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell align="center">Students</TableCell>
                                <TableCell align="center">eLearning Videos</TableCell>
                                <TableCell align="right">Total Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow hover key={row.id}>
                                    <TableCell >{row.date}</TableCell>
                                    <TableCell>{row.center}</TableCell>
                                    <TableCell>{row.teacher}</TableCell>
                                    <TableCell>{row.subject}</TableCell>
                                    <TableCell align="center">{row.students}</TableCell>
                                    <TableCell align="center">{row.eLearningVideos}</TableCell>
                                    <TableCell align="right">{row.amount}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> */}


                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: "#ECEBEB" }} >
                                <TableCell />
                                <TableCell>Date</TableCell>
                                <TableCell>Center</TableCell>
                                <TableCell>Teacher</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell align="center">Students</TableCell>
                                <TableCell align="center">eLearning Videos</TableCell>
                                <TableCell align="right">Total Amount (Rs)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {rows.map((row) => (
                                <Row key={row.date} row={row} />
                            ))} */}
                            {rowsData.map((row) => (
                                <Row isReciept={props.isReciept} key={row.date + row.center + row.teacher + row.subject} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Typography />
                <Typography align="left" component="h2" variant="subtitle2" gutterBottom>
                    Note *
                </Typography>
                <Typography align="left" component="h2" variant="subtitle2" gutterBottom>
                    ELearning Videos Amount calculation can be confusing. The calculation is right below.
                </Typography>
                <Typography align="left" component="h2" variant="subtitle2" gutterBottom>
                    For a One Class
                </Typography>
                <Typography align="left" component="h2" variant="body2" gutterBottom>
                    ELearning Videos =  Total Video Count * Total Student Count For That Class
                </Typography>
                <Typography align="left" component="h2" variant="subtitle2" gutterBottom>
                    For a One Institution
                </Typography>
                <Typography align="left" component="h2" variant="body2" gutterBottom>
                    ELearning Videos =  Total Video Count * Total Student Count For That Class
                </Typography>
                <Typography align="left" component="h2" variant="body2" gutterBottom>
                    Total Amount =  ( Total Student Count * Price Per Student ) + ( Total ELearning Videos * Price For eLearning Video Per Student )
                </Typography>
                <Typography align="left" component="h2" variant="subtitle2" gutterBottom>
                    Example :-
                </Typography>
                <Typography align="left" component="h2" variant="subtitle2" gutterBottom>
                    For a One Class
                </Typography>
                <Typography align="left" component="h2" variant="body2" gutterBottom>
                    ELearning Videos = Total Video Count * Total Student Count For That One Class
                </Typography>
                <Typography align="left" component="h2" variant="body2" gutterBottom>
                    ELearning Videos = 7 * 200
                </Typography>
                <Typography align="left" component="h2" variant="body2" gutterBottom>
                    ELearning Videos = 1400
                </Typography>

            </div>
            { props.isReciept ?
                <>
                </>
                :
                <PaymentButton name={props.name} centers={centers} amount={(totStudent * props.centerPayData.pricePerStudent) + (totVideos * props.centerPayData.pricePerELearning)} />

            }

        </React.Fragment>
    );
}