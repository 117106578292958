import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const pricePageStyle = {
    container,
    profile: {
        textAlign: "center",
        "& img": {
            maxWidth: "160px",
            width: "100%",
            margin: "0 auto",
            transform: "translate3d(0, -50%, 0)"
        }
    },
    description: {
        margin: "1.071rem auto 0",
        maxWidth: "600px",
        color: "#999",
        textAlign: "center !important"
    },
    name: {
        marginTop: "-80px"
    },
    ...imagesStyle,
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign: "center"
    },
    socials: {
        marginTop: "0",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
        color: "#999"
    },
    navWrapper: {
        margin: "20px auto 50px auto",
        textAlign: "center"
    },
    card: {
        paddingLeft: "20px",
        paddingTop: "5px",
        marginTop: "30px",
        marginBottom: "30px",
        //   marginLeft: "10px",
        //   marginRight: "10px",
        borderRadius: "5px",
        //   width: "500px",
        // height: "800px",
        boxShadow:
            "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",

    },
    container2: {
        width: "100%",
        padding: "20px",
    },
    title_card: {
        ...title,
        // marginBottom: "1rem",
        // marginTop: "30px",
        // minHeight: "32px",
        color: "grey",
        textDecoration: "none",
        textAlign: "left",

    },
    description_card: {
        margin: "1.071rem auto 0",
        maxWidth: "600px",
        color: "black",
        textAlign: "left !important",
        fontSize: "16px"
    },
    buttonMaterial: {
        marginTop: '10px',
        color: "#39C755",
        width: "150px",
        height: "50px",
        fontSize: "15px",
        border: '2px solid',
        marginBottom: '10px',

    },
    buttonMaterial2: {
        marginTop: '10px',
        color: "white",
        width: "150px",
        height: "50px",
        fontSize: "15px",
        backgroundColor: "#39C755",
        marginBottom: '10px',

    },
    buttonMaterial2: {
        marginTop: '15px',
        marginBottom: '17px',
        color: "white",
        width: "150px",
        height: "50px",
        fontSize: "15px",
        backgroundColor: "#39C755",
        transition: "all 150ms ease 0s",
        "&:hover,&:focus": {
            color: "white",
            background: "#39C755",
            marginTop: '12px',
            marginBottom: '20px',
        }
    },
    centerContainer: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '-20px',
    }
};

export default pricePageStyle;
