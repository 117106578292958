import { container, title } from "assets/jss/material-kit-react.js";
// import { theme } from "@material-ui/core/styles";

const signUpPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  root: {
    //   alignItem: "center",
    //   maxWidth: "400px",
    //   justify: "center"
    display: 'flex',
    flexWrap: 'wrap',
  },
  root1: {
    '& input:valid + fieldset': {
        borderColor: 'grey',
        borderWidth: 2,
    },
    '& input:invalid + fieldset': {
        borderColor: 'grey',
        borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
        padding: '4px !important', // override inline-style
    },
    //   '& label.Mui-focused': {
    //     color: 'green',
    //   },
    //   '& .MuiInput-underline:after': {
    //     borderBottomColor: 'green',
    //   },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '&:hover fieldset': {
            borderColor: '#3952C7',
        },
        // '&.Mui-focused fieldset': {
        //   borderColor: 'green',
        // },
    },
  },
  textField: {
    color:'black',
    borderWidth: "1px",
    borderColor: "yellow !important"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    // marginTop: "30px",
    marginBottom: "-20px",
    // minHeight: "32px",
    color: "black",
    textDecoration: "none"

    // fontSize: "4.2rem",
    // fontWeight: "600",
    // display: "inline-block",
    // position: "relative",
    // color: "black"
  },
  title1: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  title2: {
    ...title,
    marginBottom: "1rem",
    marginTop: "-5px",
    marginBottom: "-10px",
    minHeight: "-32px",
    textDecoration: "none"
  },
  titleCard: {
    ...title,
    marginBottom: "-10px",
    marginTop: "-10px",
    // minHeight: "32px",
    textDecoration: "none",
    color: "white"
  },
  titleCard2: {
    // ...title,
    fontWeight:"300",
    marginBottom: "0px",
    marginTop: "0px",
    // minHeight: "32px",
    textDecoration: "none",
    color: "white"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    // margin: "10px auto 0",
    fontWeight: "400",
    marginTop: "30px",
    color: "black"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  brand: {
    color: "#FFFFFF",
    textAlign: "left"
  },
  description: {
    color: "#999"
  },
  description1: {
    color: "black",
    fontWeight: "500"
  },
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  margin: {
    //   padding: "10px"
    margin: "10px"
  },
  margin2: {
    //   padding: "10px"
    margin: "10px",
    '& input:valid + fieldset': {
        borderColor: 'grey',
        borderWidth: 2,
    },
    '& input:invalid + fieldset': {
        borderColor: 'grey',
        borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
        padding: '4px !important', // override inline-style
    },
    //   '& label.Mui-focused': {
    //     color: 'green',
    //   },
    //   '& .MuiInput-underline:after': {
    //     borderBottomColor: 'green',
    //   },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '&:hover fieldset': {
            borderColor: '#3952C7',
        },
        // '&.Mui-focused fieldset': {
        //   borderColor: 'green',
        // },
    },
  },
  margin3: {
    //   padding: "10px"
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "-5px",
    marginBottom: "0px",
    '& input:valid + fieldset': {
        borderColor: 'grey',
        borderWidth: 2,
    },
    '& input:invalid + fieldset': {
        borderColor: 'grey',
        borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
        padding: '4px !important', // override inline-style
    },
    //   '& label.Mui-focused': {
    //     color: 'green',
    //   },
    //   '& .MuiInput-underline:after': {
    //     borderBottomColor: 'green',
    //   },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '&:hover fieldset': {
            borderColor: '#3952C7',
        },
        // '&.Mui-focused fieldset': {
        //   borderColor: 'green',
        // },
    },
  },
  buttonMaterial2: {
    marginTop: '10px',
    color: "white",
    width: "150px",
    height: "50px",
    fontSize: "15px",
    backgroundColor: "#3BB138",
    transition: "all 150ms ease 0s",
    "&:hover,&:focus": {
        color: "white",
        background: "#3BB138",
        marginTop: '7px',
        marginBottom: '3px',
    }
},
buttonMaterial3: {
  marginTop: '10px',
  color: "white",
  width: "150px",
  height: "50px",
  fontSize: "15px",
  backgroundColor: "#F24C20",
  transition: "all 150ms ease 0s",
  "&:hover,&:focus": {
      color: "white",
      background: "#F24C20",
      marginTop: '7px',
      marginBottom: '3px',
  }
},
alert: {
    alignItem: "left"
}
};

export default signUpPageStyle;