import React, { useEffect, useRef } from "react";
import HttpCommon from "http-common.js";
import { useHistory } from 'react-router-dom';


// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import 'date-fns';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


import ButtonMaterial from '@material-ui/core/Button';


import styles from "assets/jss/material-kit-react/views/signUpPage.js";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddAlert from "@material-ui/icons/AddAlert";
import { Cancel, Close } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Chip, LinearProgress } from "@material-ui/core";
import { AlertTitle } from '@material-ui/lab';
import LinearProgress2 from '@material-ui/core/LinearProgress';



var FormData = require('form-data');


const dashboardRoutes = [];

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: 2,
                borderColor: 'grey',
            },
            '&:hover fieldset': {
                borderColor: '#3952C7',
            },

        },
        "& .MuiInputBase-root.Mui-disabled": {
            color: "black"
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "black"
        },
    },
})(TextField);

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const useStyles = makeStyles(styles);

var token;
var roleType;


export default function Exam(props) {

    const history = useHistory();

    const classes = useStyles();
    const { ...rest } = props;

    var responseData;
    var institution;
    var addressLineIN;
    var contactNoIN;
    var isEmpty = true;



    const [centersArr, setCenterArr] = React.useState([]);
    const [centersNameArr, setCentersNameArr] = React.useState([]);
    const [eLearningVideos, seteLearningVideos] = React.useState([]);
    const [viewableELearningVideos, setviewableELearningVideos] = React.useState([]);
    const [type, setType] = React.useState('');
    const [notValued, setNotValued] = React.useState(true);
    const [reset1, setReset1] = React.useState(true);
    const [random1, setRandom1] = React.useState(true);
    const [subjects, setSubjects] = React.useState([]);
    const [addedClassesVideo, setAddedClassesVideo] = React.useState([]);
    const [addedClassesLink, setAddedClassesLink] = React.useState([]);
    const inputFile = useRef(null);
    const [file, setFile] = React.useState('');
    const resettingRef = useRef(false);


    const [values, setValues] = React.useState({
        institution: '',
        classYear: '',
        subject: '',
        teacherId: '',
        name: '',
        description: '',
        link: '',
        institutionKey: '1',
        subjectKey: '2',
        classYearKey: '3',
        resultResponse: '',
    });
    const [result2, setResult2] = React.useState('');
    const [errorMsg, setErrorMsg] = React.useState(' ');
    const [uploadPrecentage, setUploadPrecentage] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isAborting, setIsAborting] = React.useState(false);



    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openFieldError, setOpenFieldError] = React.useState(false);
    const [openErrorMsg, setOpenErrorMsg] = React.useState(false);
    const [openUpdatedSuccess, setOpenUpdatedSuccess] = React.useState(false);
    const [openUploadSuccess, setOpenUploadSuccess] = React.useState(false);

    const xhr = new XMLHttpRequest();

    const handleExamReportUpload = () => {
        setIsUploading(true);


        var data = new FormData();
        data.append('file', file);
        data.append('title', values.name);

        for (let index = 0; index < addedClassesVideo.length; index++) {
            const element = addedClassesVideo[index];
            data.append('classes[' + index + '][centerId]', element.institution.id);
            data.append('classes[' + index + '][subjectId]', element.subject.id);
            data.append('classes[' + index + '][teacherId]', element.teacher.id);
            data.append('classes[' + index + '][olYear]', element.classYear);

        }

        var config = {
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        };

        HttpCommon.post('/api/v1/uploadExamForClasses', data,
            config
        )
            .then(response => {
                if (response.data != null) {
                    console.log("user Updated");
                    // console.log(response.data);
                    // setOpen(true);
                    var result = response.data
                    setValues({
                        ...values, description: '', name: '', link: ''
                    });
                    setResult2(result)
                    setIsUploading(false);
                    setOpenUploadSuccess(true);
                    setAddedClassesVideo([]);
                }
            }).catch(function (error) {
                setIsUploading(false);
                console.log(error);
                console.log(error.response);
                if (error.response != null) {
                    setErrorMsg(error.response.data);
                    setOpenErrorMsg(true);

                } else {
                    setErrorMsg("Unknown Error Occured");
                    setOpenErrorMsg(true);
                }

            });
    }

    const handleClickAddClass = (isMeeting) => {

        if (values.institution.title != null && values.subject.title != null && values.classYear != null) {
            var addedClassesTemp = [];
            if (isMeeting) {
                addedClassesLink.forEach(element => {
                    addedClassesTemp.push(element);
                });
            } else {
                addedClassesVideo.forEach(element => {
                    addedClassesTemp.push(element);
                });
            }

            addedClassesTemp.push({ "institution": values.institution, "subject": values.subject, "classYear": values.classYear, "teacher": { "title": values.fName + " " + values.lName, "id": values.id } })
            // console.log(addedClassesTemp);

            /////// Search for duplicates :START
            var tempArr = addedClassesTemp;
            for (let index = 0; index < addedClassesTemp.length - 1; index++) {
                const element1 = addedClassesTemp[index];
                if (addedClassesTemp.length > 1) {
                    for (let index2 = index + 1; index2 < addedClassesTemp.length; index2++) {
                        const element2 = addedClassesTemp[index2];
                        if (JSON.stringify(element1) === JSON.stringify(element2)) {
                            setErrorMsg('Can not Add Duplicate Classes. This Class Already Added!');
                            setOpenErrorMsg(true);
                            return;
                        }
                    }
                }
            }
            /////// Search for duplicates :END    
            if (isMeeting) {
                setAddedClassesLink(addedClassesTemp);

            } else {
                setAddedClassesVideo(addedClassesTemp);

            }
            // console.log(addedClassesTemp);
            setValues({
                ...values, institution: '', subject: '', classYear: '', institutionKey: Math.random() * (1000 - 1), subjectKey: Math.random() * (1000 - 1), classYearKey: Math.random() * (1000 - 1)
            });
            setRandom1(!random1);
            // console.log(random1);
        } else {
            setErrorMsg("Enter All Required Field for Select Class");
            setOpenErrorMsg(true);
            setValues({
                ...values, institution: '', subject: '', classYear: ''
            });
            setRandom1(!random1);
            // console.log(random1);


        }
    }

    const handleRemove = (index, isMeeting) => {
        var addedClassesTemp = [];
        if (isMeeting) {
            addedClassesLink.forEach(element => {
                addedClassesTemp.push(element);
            });
        } else {
            addedClassesVideo.forEach(element => {
                addedClassesTemp.push(element);
            });
        }
        // console.log(addedClassesTemp);
        delete addedClassesTemp[index];
        if (isMeeting) {
            setAddedClassesLink(addedClassesTemp);

        } else {
            setAddedClassesVideo(addedClassesTemp);

        }
        // setAddedClasses(addedClassesTemp);
        console.log('Removed');
        // console.log(addedClassesTemp);

    }

    const getFile = () => {
        inputFile.current.click();
    }


    const onChangeFile = (event) => {
        // console.log(event.target.files[0]);
        setFile(event.target.files[0]);
    };

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    const onCenterChange = (event, value) => {

        if (value != null) {
            // setValues({
            //     ...values, subject: '',  subjectKey: Math.random() * (1000 - 1)
            // });
            setValues({ ...values, subject: '', subjectKey: Math.random() * (1000 - 1), institution: value });
            // console.log("institution = " + value.id + " " + value.title);
            // console.log(value);
            var tempArr = [];
            centersArr.forEach(element => {
                console.log(centersArr);
                if (element.id == value.id) {
                    if (element.subjects == [] || element.subjects == undefined || element.subjects == null || element.subjects.length == 0) {
                        console.log('Empty');
                        console.log(element.subjects);
                        var tempArr2 = [];
                        tempArr2.push({ "title": "Subjects Not Found. Add Subjects!", "id": 0 });
                        setSubjects(tempArr2);
                    } else {
                        element.subjects.forEach(element2 => {
                            tempArr.push({ "title": element2.Name, "id": element2.id });
                        });
                    }
                    setSubjects(tempArr);
                }
            });
            // setValues({ ...values, subject: ''});


            setReset1(false);

        }
        setviewableELearningVideos([]);

    };

    const onSubjectChange = (event, value) => {
        if (value != null) {
            setValues({ ...values, subject: value });
            // console.log("subject = " + value.id + " " + value.title);
            // console.log(value);
        }
        setviewableELearningVideos([]);

    };

    const onClassYearChange = (event, value) => {
        if (value != null) {
            setValues({ ...values, classYear: value.title });
            // console.log(value.title);
        }
        setviewableELearningVideos([]);

    };

    async function clearErrorMsg() {
        setErrorMsg('');
        console.log("Cleared Error msg = " + errorMsg);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpenUpdatedSuccess(false);
        setOpenUploadSuccess(false);
        setOpenError(false);
        setOpenErrorMsg(false);
        clearErrorMsg();

    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    function getData() {
        token = localStorage.getItem('token') || null;
        roleType = localStorage.getItem('type') || null;
    }

    function getSubjects() {
        // getData();
        var tempArr = [];
        tempArr.push({ "title": "Select a Institution First!", "id": 0 });
        setSubjects(tempArr);


    }

    useEffect(() => {
        getData();
        // console.log("token = " + token);
        getSubjects();

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (token != null && roleType == 'Teacher') {
            HttpCommon.post('/api/verifyUser', null,
                config
            )
                .then(response => {
                    if (response.data.id != null) {
                        console.log("user Verified");
                        // console.log(response.data);
                        responseData = response.data;
                        setValues({
                            ...values, fName: response.data.firstname, lName: response.data.lastname
                            , nic: response.data.nic, address: response.data.address
                            , contactNo: response.data.telno, id: response.data.id, type: response.data.type
                        });
                        setCenterArr(response.data.centers);
                        console.log(response.data);
                        var tempCenternamesArr = [];
                        response.data.centers.forEach(element => {
                            if (!(element.isTerminated)) {
                                tempCenternamesArr.push({ 'title': element.Name, 'id': element.id });

                            }
                        });
                        setCentersNameArr(tempCenternamesArr);
                        setType(response.data.type);
                        // console.log(response.data.type);

                    }
                });
        } else {
            history.push({
                pathname: "/login-page"
            });
        }



    }, []);

    return (
        <div>
            <Header
                brand="Class Manager"

                rightLinks={<HeaderLinks />}
                fixed
                color="white"
                changeColorOnScroll={{
                    height: 200,
                    color: "dark"
                }}
                {...rest}
            />

            <Parallax small image={require("assets/img/landing2.jpg")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div className={classes.brand}>
                                <h1 className={classes.title}>eLearning Videos</h1>

                            </div>
                            <br />

                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8}>
                                {/* <h2 className={classes.title1}>View Videos</h2>
                                <h5 className={classes.description}>
                                    In this Add Videos Page you can add videos to ClassManager Platform And Student can see them.
                                </h5>
                                <br />
                                <form className={classes.root} noValidate>

                                    <Autocomplete
                                        key={values.institutionKey}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onCenterChange}
                                        // defaultValue={values.institution.title}
                                        value={values.institution.title}
                                        options={centersNameArr}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Institution"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={centersNameArr.title}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        key={values.subjectKey}
                                        disabled={reset1}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onSubjectChange}
                                        value={values.subject.title}
                                        options={subjects}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Subject"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={subjects.title}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        key={values.classYearKey}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onClassYearChange}
                                        // defaultValue={values.institution.title}
                                        value={classIds.title}
                                        options={classIds}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Year"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={classIds.title}
                                            />
                                        )}
                                    />
                                </form>
                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleViewVideos()}>
                                    View
                                </ButtonMaterial>

                                {viewableELearningVideos != null && viewableELearningVideos != [] ?
                                    <>
                                        {viewableELearningVideos.map((videoTemp, j) => (
                                            <GridItem xs={12} sm={6} md={12} lg={12}>
                                                <div style={{ marginTop: "40px", marginBottom: "10px" }}>
                                                    <Card>
                                                        <CardHeader color="warning" style={{ marginLeft: "-20px", marginRight: "-20px" }}>
                                                            <h5 className={classes.titleCard2}>{videoTemp.class.center.Name} -{">"}
                                                                {videoTemp.class.subject.Name} -{">"} {videoTemp.class.user.firstname} {videoTemp.class.user.lastname}</h5>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Name"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="name"
                                                                fullWidth
                                                                helperText=""
                                                                value={videoTemp.Name}


                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                id="description"
                                                                label="Description"
                                                                required
                                                                fullWidth
                                                                helperText=""
                                                                multiline
                                                                rows={4}
                                                                variant="outlined"
                                                                value={videoTemp.Description}
                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="O/L Year"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="OL_Year"
                                                                fullWidth
                                                                helperText=""
                                                                value={videoTemp.OL_Year}


                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Updated Date"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="UpdatedAt"
                                                                fullWidth
                                                                helperText=""
                                                                value={videoTemp.UpdatedAt}


                                                            />
                                                        </CardBody>

                                                    </Card>

                                                </div>

                                            </GridItem>)
                                        )}
                                    </>
                                    :
                                    <>
                                    </>
                                } */}





                                <h2 className={classes.title1}>Add Exam Result Sheet</h2>
                                <h5 className={classes.description}>
                                    In this Exam Result Page you can add result of exams(Excel Sheet) to ClassManager Platform And Student can view them.
                                </h5>

                                <br />
                                <br />
                                <form className={classes.root} noValidate>

                                    <h5 className={classes.description1}>
                                        Select Classes
                                    </h5>
                                    {/* <Divider orientation="vertical" flexItem /> */}
                                    <br />

                                    <Autocomplete
                                        key={values.institutionKey}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onCenterChange}
                                        // defaultValue={values.institution.title}
                                        value={values.institution.title}
                                        options={centersNameArr}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Institution"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={centersNameArr.title}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        key={values.subjectKey}
                                        disabled={reset1}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onSubjectChange}
                                        disabled={reset1}
                                        // defaultValue={values.institution.title}
                                        value={values.subject.title}
                                        options={subjects}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Subject"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={values.subject.title}
                                            />
                                        )}
                                    />
                                    {/* <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}> */}
                                    {/* <GridContainer justify="center"> */}
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Autocomplete
                                            key={values.classYearKey}
                                            className={classes.margin3}
                                            fullWidth
                                            id="combo-box-demo3"
                                            onChange={onClassYearChange}
                                            // defaultValue={values.institution.title}
                                            value={classIds.title}
                                            options={classIds}
                                            getOptionLabel={(option) => option.title}
                                            // options={centersArr.map((option) => option.title)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Year"
                                                    required
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={classIds.title}
                                                />
                                            )}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClickAddClass(false)}>
                                            Add Class
                                        </ButtonMaterial>
                                    </GridItem>
                                    {addedClassesVideo != null && addedClassesVideo != [] ?
                                        <>
                                            {addedClassesVideo.map((classTemp, j) => (
                                                <GridItem key={j} xs={12} sm={12} md={12} lg={6}>
                                                    <div style={{ marginTop: "40px", marginBottom: "10px" }}>
                                                        <CardHeader color="warning" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                            <div style={{
                                                                position: 'absolute', top: "15px", right: "10px", justifyContent: 'center', borderRadius: "10px",
                                                                backgroundColor: "white", color: "black", padding: "5px", height: "28px"
                                                            }}>

                                                                <Close onClick={() => handleRemove(j, false)} style={{ fontSize: 15 }} />
                                                            </div>
                                                            <h4 className={classes.titleCard2}>{classTemp.institution.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.subject.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.teacher.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.classYear}</h4>
                                                        </CardHeader>
                                                    </div>

                                                </GridItem>)
                                            )}
                                        </>
                                        :
                                        <>
                                        </>
                                    }

                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ textAlign: "left", marginTop: "20px" }}>
                                            <h5 className={classes.description1}>
                                                Enter Excel File Detailes
                                            </h5>
                                        </div>

                                    </GridItem>

                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Title"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="name"
                                        fullWidth
                                        helperText=""
                                        value={values.name}
                                        onChange={handleChange('name')}


                                    />

                                </form>
                                <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={onChangeFile} />
                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => getFile()}>
                                    Add File
                                </ButtonMaterial>
                                <br />
                                <br />

                                {file != "" && file != null ? (
                                    <>
                                        <GridItem xs={12} sm={12} md={12} >
                                            <MuiAlert elevation={6} variant="filled" color="warning" icon={false}>
                                                <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>{file.name} File Seleted</div>
                                                </div>
                                            </MuiAlert>
                                            <div style={{ marginBottom: "10px" }}></div>
                                        </GridItem>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )

                                }
                                <br />


                                {uploadPrecentage > 0 && uploadPrecentage < 101 ? (
                                    <>
                                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center', color: "black" }}>
                                            <div>Do Not Interrupt the Upload</div>
                                        </div>
                                        <BorderLinearProgress variant="determinate" value={uploadPrecentage} />
                                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center', color: "black" }}>
                                            <div>{uploadPrecentage}% Uploaded</div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )

                                }

                                {isUploading ? (
                                    <>
                                        <BorderLinearProgress />
                                        <ButtonMaterial
                                            variant="contained"
                                            className={classes.buttonMaterial2}
                                            onClick={() => handleExamReportUpload()}
                                            disabled={isUploading}
                                        >
                                            Upload
                                        </ButtonMaterial>
                                    </>
                                ) : (
                                    <>
                                        <ButtonMaterial
                                            variant="contained"
                                            className={classes.buttonMaterial2}
                                            onClick={() => handleExamReportUpload()}
                                            disabled={isUploading}
                                        >
                                            Upload
                                        </ButtonMaterial>
                                    </>
                                )

                                }




                                {/* <h2 className={classes.title1}>Add Links</h2>
                                <h5 className={classes.description}>
                                    In this Add Links Page you can add Links/Urls to ClassManager Platform And Student can view them.
                                </h5>

                                <br />
                                <br />
                                <form className={classes.root} noValidate>

                                    <h5 className={classes.description1}>
                                        Select Classes
                                    </h5>
                                    <br />

                                    <Autocomplete
                                        key={values.institutionKey}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onCenterChange}
                                        // defaultValue={values.institution.title}
                                        value={values.institution.title}
                                        options={centersNameArr}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Institution"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={values.institution.title}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        key={values.subjectKey}
                                        disabled={reset1}
                                        className={classes.margin3}
                                        fullWidth
                                        id="combo-box-demo3"
                                        onChange={onSubjectChange}
                                        // defaultValue=''
                                        value={values.subject.title}
                                        options={subjects}
                                        getOptionLabel={(option) => option.title}
                                        // options={centersArr.map((option) => option.title)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Subject"
                                                required
                                                margin="normal"
                                                variant="outlined"
                                                value={values.subject.title}
                                            />
                                        )}
                                    />
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Autocomplete
                                            key={values.classYearKey}
                                            className={classes.margin3}
                                            fullWidth
                                            id="combo-box-demo3"
                                            onChange={onClassYearChange}
                                            // defaultValue={values.institution.title}
                                            value={values.classYear.title}
                                            options={classIds}
                                            getOptionLabel={(option) => option.title}
                                            // options={centersArr.map((option) => option.title)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Year"
                                                    required
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={values.classYear.title}
                                                />
                                            )}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClickAddClass(true)}>
                                            Add Class
                                        </ButtonMaterial>
                                    </GridItem>
                                    {addedClassesLink != null && addedClassesLink != [] ?
                                        <>
                                            {addedClassesLink.map((classTemp, j) => (
                                                <GridItem key={j} xs={12} sm={6} md={6} lg={6}>
                                                    <div style={{ marginTop: "40px", marginBottom: "10px" }}>
                                                        <CardHeader color="warning" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                            <div style={{
                                                                position: 'absolute', top: "15px", right: "10px", justifyContent: 'center', borderRadius: "10px",
                                                                backgroundColor: "white", color: "black", padding: "5px", height: "28px"
                                                            }}>

                                                                <Close onClick={() => handleRemove(j, true)} style={{ fontSize: 15 }} />
                                                            </div>
                                                            <h4 className={classes.titleCard2}>{classTemp.institution.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.subject.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.teacher.title}</h4>
                                                            <h4 className={classes.titleCard2}>{classTemp.classYear}</h4>
                                                        </CardHeader>
                                                    </div>

                                                </GridItem>)
                                            )}
                                        </>
                                        :
                                        <>
                                        </>
                                    }

                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ textAlign: "left", marginTop: "20px" }}>
                                            <h5 className={classes.description1}>
                                                Enter Video Detailes
                                            </h5>
                                        </div>

                                    </GridItem>

                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Name"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="name"
                                        fullWidth
                                        helperText=""
                                        value={values.name}
                                        onChange={handleChange('name')}


                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        id="description"
                                        label="Description"
                                        required
                                        fullWidth
                                        helperText=""
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        value={values.description}
                                        onChange={handleChange('description')}
                                    />
                                    <ValidationTextField
                                        className={classes.margin}
                                        label="Link"
                                        required
                                        variant="outlined"
                                        // defaultValue="Success"
                                        id="link"
                                        fullWidth
                                        helperText=""
                                        value={values.link}
                                        onChange={handleChange('link')}


                                    />

                                </form>
                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClickAddeLearningVideo(true)}>
                                    Upload Link
                                </ButtonMaterial> */}



                                <Snackbar open={openUpdatedSuccess} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        User Detailes Updated Successfully !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openUploadSuccess} autoHideDuration={60000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                    <AlertTitle>Exam Result Uploaded Successfully !</AlertTitle>
                                    <strong>{result2.success} Successed, {result2.fail} Failed, {result2.total} Total participants</strong>
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        New Institution Added Succesfully !

                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openError} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="error">
                                        Error Occured !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openErrorMsg} autoHideDuration={5000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="error">
                                        {errorMsg}
                                    </Alert>
                                </Snackbar>


                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

const classIds = [
    { title: "2021" },
    { title: "2022" },
    { title: "2023" },
    { title: "2024" },
    { title: "2025" },
    { title: "2026" },
    { title: "2027" },
];


