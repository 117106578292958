import { container } from "assets/jss/material-kit-react.js";

const carouselStyle = {
  section: {
    padding: "0px 0"
  },
  container: {
    height: "100%",
    width: "122%",
    justifyContent: 'center',
    // display: 'flex',

  },
  marginAuto: {
    marginLeft: "-80px",
    // marginRight: "auto !important"
  },
  container2: {
    height: "100%",
    width: "100%",
    justifyContent: 'center',
    // display: 'flex',

  },
  marginAuto2: {
    // marginLeft: "-10px",
    // marginRight: "auto !important"
  }
};

export default carouselStyle;
