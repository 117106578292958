import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

// @material-ui/icons

// core components
import TextField from '@material-ui/core/TextField';


import ButtonMaterial from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';

import styles from "assets/jss/material-kit-react/views/signUpPage.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddAlert from "@material-ui/icons/AddAlert";
import { Cancel } from "@material-ui/icons";

import HttpCommon from "http-common.js";
import { useHistory } from "react-router";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Lottie from "react-lottie";

import { Customer, CurrencyType, PayhereCheckout, CheckoutParams } from 'payhere-js-sdk'

import { Payhere, AccountCategory } from "payhere-js-sdk"



// Sandbox 
Payhere.init("1217402", AccountCategory.SANDBOX)

// Live
// Payhere.init("12xxxxx",AccountCategory.LIVE)

const dashboardRoutes = [];


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: '#3952C7',
            },
            // '&.Mui-focused fieldset': {
            //   borderColor: 'green',
            // },
        },
    },
})(TextField);

const useStyles = makeStyles(styles);
// const useStyles = makeStyles((theme) => ({
//     root: {
//       '& .MuiTextField-root': {
//         margin: theme.spacing(1),
//         width: '25ch',
//       },
//     },
//   }));

export default function PaymentButton(props) {
    const classes = useStyles();
    const { ...rest } = props;
    var temp;
    var token;
    const history = useHistory();

    return (
        <>

            <form method="post" action="https://sandbox.payhere.lk/pay/checkout" >
                <div style={{ display: "none" }}>
                    <input type="hidden" name="merchant_id" value="1217402" />
                    <input type="hidden" name="return_url" value="http://localhost:3000/billingSuccess-page" />
                    <input type="hidden" name="cancel_url" value="http://localhost:3000/billing-page" />
                    <input type="hidden" name="notify_url" value="https://classmanager.jupiter.lk/payhere/notify" />
                    <br /><br />Item Details<br />
                    <input type="hidden" name="order_id" value="65321" />
                    <input type="hidden" name="items" value="Institution Monthly Payment" /><br />
                    <input type="hidden" name="currency" value="LKR" />
                    <input type="hidden" name="amount" value={props.amount} />
                    <br /><br />Customer Details<br />
                    <input type="hidden" name="first_name" value={props.name} />
                    <input type="hidden" name="last_name" value="" /><br />
                    <input type="hidden" name="email" value="" />
                    <input type="hidden" name="phone" value="" /><br />
                    <input type="hidden" name="address" value={JSON.stringify(props.centers)} />
                    <input type="hidden" name="city" value='' />
                    <input type="hidden" name="country" value='' />
                    <input type="hidden" name="custom_1" value={JSON.stringify(props.centers)} />
                    <br /><br />
                    {/* <input type="submit" value="Buy Now" /> */}
                </div>
                { props.amount < 1 ? 
                <>
                </> 
                : 
                <ButtonMaterial type="submit" variant="contained" className={classes.buttonMaterial2} >
                    Payment
                </ButtonMaterial>
                
                }
                
            </form>

        </>

    );
}
