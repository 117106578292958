import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import styles from "assets/jss/material-kit-react/views/aboutPage.js";

// Sections for this page
import ProductSection from "../LandingPage/Sections/ProductSection.js";
import TeamSection from "../LandingPage/Sections/TeamSection.js";
import WorkSection from "../LandingPage/Sections/WorkSection.js";

import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'

import Timeline from "@material-ui/icons/Timeline";
import OndemandVideo from "@material-ui/icons/OndemandVideo";
import EmojiPeople from "@material-ui/icons/EmojiPeople";
import Payment from "@material-ui/icons/Payment";
import Assignment from "@material-ui/icons/Assignment";
import Assessment from "@material-ui/icons/Assessment";
// core components
import InfoArea from "components/InfoArea/InfoArea.js";

import { title } from "assets/jss/material-kit-react.js";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';


import SectionCarousel from "../Components/Sections/SectionCarousel.js";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import image1 from "assets/img/bg.jpg";
import image2 from "assets/img/bg2.jpg";
import image3 from "assets/img/bg3.jpg";

import FirstOne1 from "assets/img/FirstOne1.PNG";
import FirstOne2 from "assets/img/FirstOne2.PNG";
import FirstOne3 from "assets/img/FirstOne3.PNG";
import FirstOne4 from "assets/img/FirstOne4.PNG";

import SecondOne1 from "assets/img/SecondOne_1.jpg";
import SecondOne2 from "assets/img/SecondOne_2.jpg";
import SecondOne3 from "assets/img/SecondOne_3.jpg";
import SecondOne4 from "assets/img/SecondOne4.jpg";
import SecondOne5 from "assets/img/SecondOne5.jpg";

import video from "assets/img/Test.mp4";

import { AudioCard, VideoCard } from 'material-ui-player';
import CardMedia from '@material-ui/core/CardMedia';
import ReactPlayer from 'react-player'


const firstOneImages = [FirstOne1, FirstOne2, FirstOne3, FirstOne4];
// const secondOneImages = [SecondOne1,SecondOne2,SecondOne3,SecondOne4,SecondOne5];
const secondOneImages = [SecondOne1, SecondOne2, SecondOne3];

// const secondOneImages = [image1,image2,image3];
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const productStyle = {
    section: {
        textAlign: "center"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
    },
    description: {
        textAlign: "left",
        color: "black",
        fontWeight: "400",
        fontSize: "20px"
    },
    description2: {
        textAlign: "left",
        color: "black",
        fontWeight: "400",
        fontSize: "16px",
        marginTop: "4px"
    },
    video: {
        borderRadius: "30px",
        marginTop: "4px",
        height: "900px"
    },
};

function getSteps() {
    return ['Sign Up & Create a Institute', 'Download ClassManager App', 'Add Teachers & Create Classes', 'Add Students', 'Add Employees', 'Finish'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return '';
        case 1:
            return '';
        case 2:
            return '';
        case 3:
            return '';
        case 4:
            return '';
        case 5:
            return '';
        default:
            return '';
    }
}

function FirstOne() {
    return (
        <div style={productStyle.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 style={productStyle.title} >Sign Up {"&"} Create a Institution</h2>
                    {/* <SectionCarousel images={firstOneImages} /> */}
                    {/* <CardMedia component="video" src={video} allow="autoPlay"/> */}
                    {/* <video loop autoPlay>
                            <source src={require(video)} type="video/avi" />
                        </video> */}
                    <SectionCarousel images={secondOneImages} />


                    <List >
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="If you already Sign Up and Log in you can go to next step. If not you need to go to Sign Up Page and Register. You need to add required detailes to Sign Up."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="One User can create many institutuion. If you want to create a another you have to login and goto My Account."
                            />
                        </ListItem>
                    </List>
                </GridItem>
            </GridContainer>
        </div>
    );
}

function SecondOne() {
    return (
        <div style={productStyle.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 style={productStyle.title} >Download ClassManager Mobile App {"&"} Parent Mobile App</h2>

                    <MediaQuery minWidth={900}>
                        <ReactPlayer style={{ borderRadius: "50px" }} width="800px" height="500px" url={"https://www.youtube.com/watch?v=L9th-ASXUw8"} playing='true' loop='true' muted='true' controls='true' volume='1' />
                    </MediaQuery>
                    <MediaQuery maxWidth={900}>
                        <ReactPlayer style={{ borderRadius: "50px" }} width="100%"  url={"https://www.youtube.com/watch?v=L9th-ASXUw8"} playing='true' loop='true' muted='true' controls='true' volume='1' />
                    </MediaQuery>
                    <List >
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="Class manager App for Institution Owners and Teachers. With this App you can Add Homeworks, E-Learning vidoes, View Monthly Payments and many other features."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <h1 style={productStyle.description2}>
                                You can Download Class Manager App for Android Devices With <a href="https://play.google.com/store/apps/details?id=com.jupitor.classmanager" target="_blank">This</a> link.
                </h1>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <h1 style={productStyle.description2}>
                                For ios Devices you can Download Class Manager App With <a href="https://www.apple.com/app-store/" target="_blank">This</a> link.
                </h1>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <h1 style={productStyle.description2}>
                                You can Download Parent App for Android Devices With <a href="https://play.google.com/store/apps/details?id=com.jupiter.class_manager_parent" target="_blank">This</a> link.
                </h1>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <h1 style={productStyle.description2}>
                                For ios Devices you can Download Parent App With <a href="https://www.apple.com/app-store/" target="_blank">This</a> link.
                </h1>
                        </ListItem>
                    </List>
                </GridItem>
            </GridContainer>
        </div>
    );
}

function ThirdOne() {
    return (
        <div style={productStyle.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 style={productStyle.title} >Add Teachers {"&"} Create Classes</h2>

                    <MediaQuery minWidth={900}>
                        <ReactPlayer style={{ borderRadius: "50px" }} width="800px" height="500px" url={"https://www.youtube.com/watch?v=61_LSO-52PI"} playing='true' loop='true' muted='true' controls='true' volume='1' />
                    </MediaQuery>
                    <MediaQuery maxWidth={900}>
                        <ReactPlayer style={{ borderRadius: "50px" }} width="100%"  url={"https://www.youtube.com/watch?v=61_LSO-52PI"} playing='true' loop='true' muted='true' controls='true' volume='1' />
                    </MediaQuery>
                    <List >
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="You can add Teachers and Classes as many as you want."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="To add Classes you have to install ClassManager Mobile App"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <h1 style={productStyle.description2}>
                                For Additional Videos Check <a href="https://www.youtube.com/watch?v=WovIs3litpw&list=PLgI8lBnes1YsjAzgcfJYAMF-y7HXK3jdS" target="_blank">This</a> Link.
                </h1>
                        </ListItem>
                    </List>
                </GridItem>
            </GridContainer>
        </div>
    );
}

function FourthOne() {
    return (
        <div style={productStyle.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 style={productStyle.title} >Add Students</h2>
                    <MediaQuery minWidth={900}>
                        <ReactPlayer style={{ borderRadius: "50px" }} width="800px" height="500px" url={"https://www.youtube.com/watch?v=WovIs3litpw"} playing='true' loop='true' muted='true' controls='true' volume='1' />
                    </MediaQuery>
                    <MediaQuery maxWidth={900}>
                        <ReactPlayer style={{ borderRadius: "50px" }} width="100%"  url={"https://www.youtube.com/watch?v=L9th-ASXUw8"} playing='true' loop='true' muted='true' controls='true' volume='1' />
                    </MediaQuery>
                    <List >
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="You can add Students relevent to your Plan."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="To add Student more than your limit you have to upgrade your plan."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <h1 style={productStyle.description2}>
                                For Additional Videos Check <a href="https://www.youtube.com/watch?v=WovIs3litpw&list=PLgI8lBnes1YsjAzgcfJYAMF-y7HXK3jdS" target="_blank">This</a> Link.
                </h1>
                        </ListItem>
                    </List>
                </GridItem>
            </GridContainer>
        </div>
    );
}

function FifthOne() {
    return (
        <div style={productStyle.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 style={productStyle.title} >Add Employees</h2>
                    <MediaQuery minWidth={900}>
                        <ReactPlayer style={{ borderRadius: "50px" }} width="800px" height="500px" url={"https://www.youtube.com/watch?v=rAnr3WGWDQg"} playing='true' loop='true' muted='true' controls='true' volume='1' />
                    </MediaQuery>
                    <MediaQuery maxWidth={900}>
                        <ReactPlayer style={{ borderRadius: "50px" }} width="100%"  url={"https://www.youtube.com/watch?v=L9th-ASXUw8"} playing='true' loop='true' muted='true' controls='true' volume='1' />
                    </MediaQuery>
                    <List >
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="You can add Employees/Workers as many as you want."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="There are many types of Worker Levels available"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <h1 style={productStyle.description2}>
                                For Additional Videos Check <a href="https://www.youtube.com/watch?v=WovIs3litpw&list=PLgI8lBnes1YsjAzgcfJYAMF-y7HXK3jdS" target="_blank">This</a> Link.
                </h1>
                        </ListItem>
                    </List>
                </GridItem>
            </GridContainer>
        </div>
    );
}

function SixthOne() {
    return (
        <div style={productStyle.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 style={productStyle.title} >Congratulations You have succesfuly set up ClassManager</h2>
                    <List >
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="Now You can use Class Manager plateform for online teaching."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                            </ListItemIcon>
                            <ListItemText style={productStyle.description}
                                primary="Enjoy !"
                            />
                        </ListItem>
                    </List>
                </GridItem>
            </GridContainer>
        </div>
    );
}

function GetStepComponentContent(params) {
    switch (params.stepIndex) {
        case 0:
            return <FirstOne />;
        case 1:
            return <SecondOne />;
        case 2:
            return <ThirdOne />;
        case 3:
            return <FourthOne />;
        case 4:
            return <FifthOne />;
        default:
            return <SixthOne />;
    }
}

export default function LandingPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    return (
        <div>
            <Header
                brand="Class Manager"
                rightLinks={<HeaderLinks />}
                fixed
                color="dark"
                changeColorOnScroll={{
                    height: 300,
                    color: "white"
                }}
                {...rest}
            />
            <MediaQuery minWidth={900}>
                <Parallax small image={require("assets/img/landing3.jpg")}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <div className={classes.brand}>
                                    <h1 className={classes.title}>About</h1>
                                    <br />
                                    <h1 className={classes.title}>Class Manager</h1>

                                </div>
                                {/* <h1 className={classes.title}>Welcome To</h1>
            <br />
              <h1 className={classes.title}>Class Manager</h1> */}
                                {/* <h4 className={classes.title}>
                Class Manager is a online teaching platform that provide with
                many benifits. Every one can access ClassManager via Mobile, Laptop,
                Desktop. Teachers and Students can teach and learn any where they like. */}
                                {/* 
                Every landing page needs a small description after the big bold
                title, that{"'"}s why we added this text here. Add here all the
                information that can make you or your product create the first
                impression. */}
                                {/* </h4> */}
                                <br />

                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>

                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <div className={classes.root}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div>
                                {activeStep === steps.length ? (
                                    <>
                                    <div style={productStyle.section}>
                                        <GridContainer justify="center">
                                            <GridItem xs={12} sm={12} md={8}>
                                                <h2 style={productStyle.title} >Congratulations You have succesfuly set up ClassManager</h2>
                                                <List >
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                                                        </ListItemIcon>
                                                        <ListItemText style={productStyle.description}
                                                            primary="Now You can use Class Manager plateform for online teaching."
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                                                        </ListItemIcon>
                                                        <ListItemText style={productStyle.description}
                                                            primary="Enjoy !"
                                                        />
                                                    </ListItem>
                                                </List>
                                            </GridItem>
                                        </GridContainer>
                                        <div className={classes.buttonContainer}>
                                        <Typography className={classes.instructions}></Typography>
                                        <Button onClick={handleReset}>Back To Start</Button>
                                    </div>
                                    </div>
                                    
                                </>
                                ) : (
                                    <div>
                                        <GetStepComponentContent stepIndex={activeStep} />
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                        <div className={classes.buttonContainer}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Back
              </Button>
                                            <Button variant="contained" color="primary" onClick={handleNext}>
                                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </MediaQuery>

            <MediaQuery maxWidth={900}>
                <Parallax image={require("assets/img/landing5.jpg")}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <div className={classes.brand}>
                                    <h1 className={classes.title2}>About</h1>
                                    <br />
                                    <h1 className={classes.title2}>Class Manager</h1>

                                </div>
                                {/* <h1 className={classes.title}>Welcome To</h1>
            <br />
              <h1 className={classes.title}>Class Manager</h1> */}
                                {/* <h4 className={classes.title}>
                Class Manager is a online teaching platform that provide with
                many benifits. Every one can access ClassManager via Mobile, Laptop,
                Desktop. Teachers and Students can teach and learn any where they like. */}
                                {/* 
                Every landing page needs a small description after the big bold
                title, that{"'"}s why we added this text here. Add here all the
                information that can make you or your product create the first
                impression. */}
                                {/* </h4> */}
                                <br />

                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>

                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <div className={classes.root}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div>
                                {activeStep === steps.length ? (
                                    <>
                                        <div style={productStyle.section}>
                                            <GridContainer justify="center">
                                                <GridItem xs={12} sm={12} md={8}>
                                                    <h2 style={productStyle.title} >Congratulations You have succesfuly set up ClassManager</h2>
                                                    <List >
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                                                            </ListItemIcon>
                                                            <ListItemText style={productStyle.description}
                                                                primary="Now You can use Class Manager plateform for online teaching."
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <ControlCameraIcon style={{ fontSize: 25, color: "gray" }} />
                                                            </ListItemIcon>
                                                            <ListItemText style={productStyle.description}
                                                                primary="Enjoy !"
                                                            />
                                                        </ListItem>
                                                    </List>
                                                </GridItem>
                                            </GridContainer>
                                            <div className={classes.buttonContainer}>
                                            <Typography className={classes.instructions}></Typography>
                                            <Button onClick={handleReset}>Back To Start</Button>
                                        </div>
                                        </div>
                                        
                                    </>
                                ) : (
                                    <div>
                                        <GetStepComponentContent stepIndex={activeStep} />
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                        <div className={classes.buttonContainer}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Back
              </Button>
                                            <Button variant="contained" color="primary" onClick={handleNext}>
                                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </MediaQuery>


            <Footer />
        </div>
    );
}
