import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';


import ButtonMaterial from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';

import styles from "assets/jss/material-kit-react/views/signUpPage.js";

// Sections for this page
import ProductSection from "../LandingPage/Sections/ProductSection.js";
import TeamSection from "../LandingPage/Sections/TeamSection.js";
import WorkSection from "../LandingPage/Sections/WorkSection.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddAlert from "@material-ui/icons/AddAlert";
import { Cancel } from "@material-ui/icons";

import HttpCommon from "http-common.js";
import { useHistory } from "react-router";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Lottie from "react-lottie";

import { Customer, CurrencyType, PayhereCheckout, CheckoutParams } from 'payhere-js-sdk'

// import * as location from "./jsons/38435-register.json";
import * as location from "../../components/Loading/jsons/38435-register.json";

import * as success from "../../components/Loading/jsons/8603-profile.json";

import { Payhere, AccountCategory } from "payhere-js-sdk"
import InvoiceTable from "views/Components/InvoiceTable/InvoiceTable.js";
import Card from "components/Card/Card.js";



// Sandbox 
Payhere.init("1217402", AccountCategory.SANDBOX)

// Live
// Payhere.init("12xxxxx",AccountCategory.LIVE)

const dashboardRoutes = [];

const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: '#3952C7',
            },
            // '&.Mui-focused fieldset': {
            //   borderColor: 'green',
            // },
        },
    },
})(TextField);

const useStyles = makeStyles(styles);
// const useStyles = makeStyles((theme) => ({
//     root: {
//       '& .MuiTextField-root': {
//         margin: theme.spacing(1),
//         width: '25ch',
//       },
//     },
//   }));

export default function BillingPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    var temp;
    var token;
    const history = useHistory();




    const [values, setValues] = React.useState({
        institution: '',
        name: '',
        parentName: '',
        birthDay: '',
        olYear: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        school: '',
        fName: '',
        lName: '',
        nic: '',
        gender: '',
        address: '',
        city: '',
        province: '',
        contactNo: '',
        id: '',
        type: '',
    });
    const [olYear, setSelectedOlYear] = React.useState(null);

    const [birthDay, setSelectedBirthDay] = React.useState(null);

    const [barcode, setBarcode] = React.useState('2021');
    const [errorMsg, setErrorMsg] = React.useState(' ');

    const [capthchcha, setCapthchcha] = React.useState('');

    const [loading, setLoading] = useState(true);

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [openErrorMsg, setOpenErrorMsg] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);

    const [centersArr, setCenterArr] = React.useState([]);
    const [centersPayArr, setCenterPayArr] = React.useState([]);



    function onPayhereCheckoutError(errorMsg) {
        alert(errorMsg)
    }

    function checkout() {
        const customer = new Customer({
            first_name: "Pavindu",
            last_name: "Lakshan",
            phone: "+94771234567",
            email: "plumberhl@gmail.com",
            address: "No. 50, Highlevel Road",
            city: "Panadura",
            country: "Sri Lanka",
        })

        const checkoutData = new CheckoutParams({
            returnUrl: 'http://localhost:3000/return',
            cancelUrl: 'http://localhost:3000/cancel',
            notifyUrl: 'http://localhost:8080/notify',
            order_id: '112233',
            itemTitle: 'Demo Item',
            currency: CurrencyType.LKR,
            amount: 100
        })

        const checkout = new PayhereCheckout(customer, checkoutData, onPayhereCheckoutError)
        checkout.start()
    }




    async function clearErrorMsg() {
        setErrorMsg('');
        console.log("Cleared Error msg = " + errorMsg);
    }

    const handleClick = () => {
        // AddStudent();
        // checkout();
        var http = new XMLHttpRequest();
        var url = 'https://sandbox.payhere.lk/pay/checkout';
        // var params = 'orem=ipsum&name=binny';
        var params = new Object();
        params.first_name = "Pavindu";
        params.address = "No. 50, Highlevel Road";

        // Turn the data object into an array of URL-encoded key/value pairs.
        let urlEncodedData = "", urlEncodedDataPairs = [], name;
        for (name in params) {
            urlEncodedDataPairs.push(encodeURIComponent(name) + '=' + encodeURIComponent(params[name]));
        }
        http.open('POST', url, true);

        //Send the proper header information along with the request
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        // http.onreadystatechange = function () {//Call a function when the state changes.
        //     if (http.readyState == 4 && http.status == 200) {
        //         alert(http.responseText);
        //     }
        // }
        http.send(params);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
        setOpen5(false);
        setOpenErrorMsg(false);
        setOpenSuccess(false);
        clearErrorMsg();
    };

    

    function getData() {
        token = localStorage.getItem('token') || null;
    }

    useEffect(() => {
        setLoading(true);
        localStorage.removeItem("detailes");

        getData();
        // console.log("token = " + token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (token != null) {
            HttpCommon.post('/api/verifyUser', null,
                config
            )
                .then(response => {
                    if (response.data.id != null) {
                        console.log("user Verified");
                        console.log(response.data);
                        // responseData = response.data;
                        setValues({
                            ...values, fName: response.data.firstname, lName: response.data.lastname
                            , nic: response.data.nic, address: response.data.address
                            , contactNo: response.data.telno, id: response.data.id
                        });
                        var centerNames = [];
                        response.data.centers.forEach(element => {
                            centerNames.push({ 'title': element.Name, 'id': element.id });
                        });
                        console.log(centerNames);
                        setCenterArr(centerNames);
                        console.log(response.data.centers);

                        HttpCommon.get('/api/v1/invoice',
                            config
                        )
                            .then(response1 => {
                                if (response1.data.details != null) {
                                    console.log("user Verified");
                                    console.log(response1.data);
                                    setCenterPayArr(response1.data);
                                    console.log(response1.data);

                                    var detailes = {
                                        centerPayData: response1.data, 
                                        name: response.data.firstname + " " + response.data.lastname, 
                                        address: response.data.address
                                    }
                        console.log(detailes);

                                    localStorage.setItem('detailes', JSON.stringify(detailes));
                                    setLoading(false);

                                }
                            }).catch(async function (error) {
                                console.log(error);
                            });

                    }
                }).catch(async function (error) {
                    console.log(error);
                });
        } else {
            history.push({
                pathname: "/login-page"
            });
        }



    }, []);

    return (
        <>
            { loading ?
                (<div style={{ position: 'absolute', top: '0', left: '0', backgroundColor: '#282c34', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: "white", height: "100%", width: "100%" }}>
                    <Lottie options={defaultOptions1} height={400} width={400} />
                </div>)
                :
                <>

                    <div>
                        <Header
                            brand="Class Manager"
                            rightLinks={<HeaderLinks />}
                            fixed
                            color="white"
                            changeColorOnScroll={{
                                height: 200,
                                color: "dark"
                            }}
                            {...rest}
                        />
                        <Parallax small image={require("assets/img/landing2.jpg")}>
                            <div className={classes.container}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <div className={classes.brand}>
                                            <h1 className={classes.title}>Billing Detailes</h1>

                                        </div>
                                        <br />

                                    </GridItem>
                                </GridContainer>
                            </div>
                        </Parallax>
                        <div className={classNames(classes.main, classes.mainRaised)}>
                            <div className={classes.container}>
                                <div className={classes.section}>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={12}>
                                            <h2 className={classes.title1}>Account Billing Detailes</h2>
                                            <h5 className={classes.description}>
                                                Class Manager is a online teaching platform that provide with
                                                many benifits. Every one can access ClassManager via Mobile, Laptop,
                                                Desktop. Teachers and Students can teach and learn any where they like.
                                             </h5>
                                            <br />
                                            <br />
                                            <Card style={{ padding: "20px" }}>
                                                <InvoiceTable isReciept={false} centerPayData={centersPayArr} name={values.fName + " " + values.lName} address={values.address} />

                                            </Card>

                                            


{/* 
                                            <form method="post" action="https://sandbox.payhere.lk/pay/checkout" >
                                                <div style={{ display: "none" }}>
                                                    <input type="hidden" name="merchant_id" value="1217402" />
                                                    <input type="hidden" name="return_url" value="https://beta.classmanager.jupiter.lk/billing-page" />
                                                    <input type="hidden" name="cancel_url" value="https://beta.classmanager.jupiter.lk/billing-page" />
                                                    <input type="hidden" name="notify_url" value="https://beta.classmanager.jupiter.lk/billing-page" />
                                                    <br /><br />Item Details<br />
                                                    <input type="hidden" name="order_id" value="ItemNo12345" />
                                                    <input type="hidden" name="items" value="Door bell wireless" /><br />
                                                    <input type="hidden" name="currency" value="LKR" />
                                                    <input type="hidden" name="amount" value="1000" />
                                                    <br /><br />Customer Details<br />
                                                    <input type="hidden" name="first_name" value="Saman" />
                                                    <input type="hidden" name="last_name" value="Perera" /><br />
                                                    <input type="hidden" name="email" value="samanp@gmail.com" />
                                                    <input type="hidden" name="phone" value="0771234567" /><br />
                                                    <input type="hidden" name="address" value="No.1, Galle Road" />
                                                    <input type="hidden" name="city" value="Colombo" />
                                                    <input type="hidden" name="country" value="Sri Lanka" /><br /><br />
                                                    <input type="submit" value="Buy Now" />
                                                </div>
                                                <ButtonMaterial type="submit" variant="contained" className={classes.buttonMaterial2} >
                                                    Payment
                                            </ButtonMaterial>
                                            </form> */}


                                            {/* <div>
                                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClick()}>
                                                    Add Student
                                            </ButtonMaterial>
                                            </div> */}

                                            <Snackbar open={openSuccess} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="success">
                                                    Student Added Success Fully! Barcode No is {barcode}.
                                        </Alert>
                                            </Snackbar>
                                            <Snackbar open={openErrorMsg} autoHideDuration={3000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    {errorMsg}
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Enter Correct Credentials !
                                        </Alert>
                                            </Snackbar>
                                            <Snackbar open={open2} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Enter All *Required Fields !
                                        </Alert>
                                            </Snackbar>
                                            <Snackbar open={open3} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Unknown Error Occured !
                                        </Alert>
                                            </Snackbar>
                                            <Snackbar open={open4} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Check I'm not a robot Box !
                                        </Alert>
                                            </Snackbar>
                                            <Snackbar open={open5} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Contact No Required 10 Digits Only !
                                        </Alert>
                                            </Snackbar>

                                        </GridItem>
                                    </GridContainer>

                                </div>


                            </div>
                        </div>
                        <Footer />
                    </div>

                </>
            }
        </>

    );
}

const schools = [

    { title: 'Vidyaloka College Galle' },
    { title: 'Sudharma Navodya College, Galle' },
    { title: 'Malharusulhiya Boys College, Thalapitiya, Galle' },
    { title: 'Richmond College Galle' },
    { title: "Rippon Girls' College Galle" },
    { title: "Sanghamitta Girls College Galle" },
    { title: "Southlands College Galle" },
    { title: "St. Aloysius' College Galle" },
    { title: "Mahinda College Galle" },
    { title: "Zahira Central College Ginthota" },
    { title: "Anuladevi Girls′ college Galle" },
    { title: "Sacred Heart Convent Galle" },
    { title: "Olcott College, Galle" },
    { title: "Nagoda Royal National College Nagoda" },
    { title: "Vidyaraja National School Thawalama" },
    { title: "All Saints College, Galle" },
    { title: "Rohana Vidyalaya, Ahangama" },
    { title: "Devapathiraja College, Rathgama" },
    { title: "Udugama Maha Vidyalaya, Udugama" },
    { title: "Siri Sunanda Maha Vidyalaya, Mapalagama" },

];

const provinces = [
    { title: "Central" },
    { title: "Eastern" },
    { title: "Northern" },
    { title: "Southern" },
    { title: "Western" },
    { title: "North Western" },
    { title: "North Central" },
    { title: "Uva" },
    { title: "Sabaragamuwa" },
];

const gender = [
    { title: "Male" },
    { title: "Female" },
];