import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

import SectionCarousel from "../../Components/Sections/SectionCarousel.js";


import SecondOne1 from "assets/img/SecondOne_1.jpg";
import SecondOne2 from "assets/img/SecondOne_2.jpg";
import SecondOne3 from "assets/img/SecondOne_3.jpg";
import SecondOne4 from "assets/img/SecondOne4.jpg";
import SecondOne5 from "assets/img/SecondOne5.jpg";

const secondOneImages = [SecondOne1,SecondOne2,SecondOne3];

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Come Join Us</h2>
          <h4 className={classes.description}>
            With ClassManager You can take your teaching experience to another level. You can start now only for Rs 10. Let's Sign Up now.
          </h4>
          <SectionCarousel images={secondOneImages} />
          
        </GridItem>
      </GridContainer>
    </div>
  );
}
