/*eslint-disable*/
import React, { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);



export default function HeaderLinks(props) {
  const classes = useStyles();

  const [token, setToken] = React.useState(null);
  const [type, setType] = React.useState(null);



  function getData() {
    setToken(localStorage.getItem('token') || null);
    setType(localStorage.getItem('type') || null);
  }

  function removeToken() {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("type");
  }

  useEffect(() => {
    getData();
    console.log("type = " + type);


  }, []);

  return (
    <List className={classes.list}>

      {token == null ?
        <>
          <ListItem className={classes.listItem}>
          </ListItem>
        </>
        :
        <>
          { type == "Teacher" ? (
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                buttonText="Business"
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent"
                }}
                buttonIcon={Apps}
                dropdownList={[
                  <Link to="/myAccount" className={classes.dropdownLink}>
                    My Account
            </Link>,
                  <Link to="/addStudents-page" className={classes.dropdownLink}>
                    Add Students
                </Link>,
                  <Link to="/addTeachers-page" className={classes.dropdownLink}>
                    Add Teachers
              </Link>,
                  <Link to="/eLearning-page" className={classes.dropdownLink}>
                    eLearning Videos
               </Link>,
               <Link to="/exam-page" className={classes.dropdownLink}>
               Exams
          </Link>,
                ]}
              />
            </ListItem>
          ) : (
            <>
            { type == "Owner" ? (
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                buttonText="Business"
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent"
                }}
                buttonIcon={Apps}
                dropdownList={[
                  <Link to="/myAccount" className={classes.dropdownLink}>
                    My Account
            </Link>,
                  <Link to="/addStudents-page" className={classes.dropdownLink}>
                    Add Students
                </Link>,
                  <Link to="/addTeachers-page" className={classes.dropdownLink}>
                    Add Teachers
              </Link>,
              //     <Link to="/billing-page" className={classes.dropdownLink}>
              //       Billing
              // </Link>,
                ]}
              />
            </ListItem>
          ) : (
            <>
            <ListItem className={classes.listItem}>
                <CustomDropdown
                  noLiPadding
                  buttonText="Business"
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent"
                  }}
                  buttonIcon={Apps}
                  dropdownList={[
                    <Link to="/myAccount" className={classes.dropdownLink}>
                      My Account
                    </Link>,
                    <Link to="/addStudents-page" className={classes.dropdownLink}>
                      Add Students
                    </Link>,
                    <Link to="/addTeachers-page" className={classes.dropdownLink}>
                      Add Teachers
                   </Link>,
                  ]}
                />
              </ListItem>
            </>
          )
          }
            </>
          )
          }
        </>

      }

      <ListItem className={classes.listItem}>
        <Button
          href="/landing-page"
          color="transparent"
          target="_self"
          className={classes.navLink}
        >
          {/* <Link to="/profile-page" className={classes.dropdownLink}>
            </Link>, */}
          {/* <CloudDownload className={classes.icons} /> Download */}
          HOME
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/price-page"
          color="transparent"
          target="_self"
          className={classes.navLink}
        >
          {/* <CloudDownload className={classes.icons} /> Download */}
          PRICING
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/about-page"
          color="transparent"
          target="_self"
          className={classes.navLink}
        >
          Support
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/contact-page"
          color="transparent"
          target="_self"
          className={classes.navLink}
        >
          {/* <CloudDownload className={classes.icons} />  */}
          CONTACT
        </Button>
      </ListItem>
      {token == null ?
        <>
          <ListItem className={classes.listItem}>
            <Button
              href="/login-page"
              color="transparent"
              target="_self"
              // target="_blank"
              className={classes.navLink3}
            >
              {/* <CloudDownload className={classes.icons} />  */}
                      LOG IN
                    </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/signUp-page"
              // color="transparent"
              target="_self"
              className={classes.navLink2}
            >
              {/* <CloudDownload className={classes.icons} />  */}
                      SIGN UP
                    </Button>
          </ListItem>
        </>

        :

        <ListItem className={classes.listItem}>
          <Button
            href="/login-page"
            // color="transparent"
            target="_self"
            className={classes.navLink2}
            onClick={() => removeToken()}
          >
            {/* <CloudDownload className={classes.icons} />  */}
          LOG Out
          </Button>
        </ListItem>
      }




      {/* <ListItem className={classes.listItem}> */}
      {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
      {/* <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/CreativeTim?ref=creativetim"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/CreativeTim?ref=creativetim"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem> */}
    </List>
  );
}
