import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from 'react-router-dom';


// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import 'date-fns';

// @material-ui/icons

// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';


import ButtonMaterial from '@material-ui/core/Button';


import styles2 from "assets/jss/material-kit-react/views/signUpPage.js";


import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import App from "components/IDCard/App.jsx";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ReactToPdf from "react-to-pdf";
import { container, title } from "assets/jss/material-kit-react.js";
import Lottie from "react-lottie";

// import * as location from "./jsons/38435-register.json";
import * as location from "../../components/Loading/jsons/38435-register.json";

import * as success from "../../components/Loading/jsons/8603-profile.json";

const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const dashboardRoutes = [];

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
    title1: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        fontWeight: "500"

    },

    description: {
        color: "#999"
    },
});

const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: '#3952C7',
            },
        },
    },
})(TextField);

const useStyles = makeStyles(styles2);


var namePrint = '';
var barCodePrint = '';
var birthDayPrint = '';

export default function SuccessPage(props) {

    const history = useHistory();
    const location = useLocation();

    const componentRef = useRef();
    const refPDF = React.createRef();

    const [name, setName] = React.useState('');
    const [barCode, setBarCode] = React.useState('');
    const [birthDay, setBirthDay] = React.useState('');
    const [allData, setAllData] = React.useState();


    const classes = useStyles();
    const { ...rest } = props;



    useEffect(() => {
        if (location.state != null) {
            console.log(location.pathname); // result: '/secondpage'
            console.log(location.state.name); // result: 'some_value'
            console.log(location.state.barcode); // result: 'some_value'
            setName(location.state.name);
            setBarCode(location.state.barcode);
            setBirthDay(location.state.birthDay);
            setAllData(location.state.allData);
            namePrint = location.state.name;
            barCodePrint = location.state.barcode;
            birthDayPrint = location.state.birthDay;
        } else {
            history.replace({
                pathname: "/id2b6sh603mfh69ma5vbt54ps7/addStudents-page",
                // pathname: "/",

            });
        }

    }, [location]);

    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    return (
        <div>
            <Parallax small image={require("assets/img/landing2.jpg")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div className={classes.brand}>
                                <h1 className={classes.title}>ID Detailes</h1>

                            </div>
                            <br />

                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8}>
                                <Lottie options={defaultOptions2} height={250} width={250} />

                                <h2 className={classes.title1}>Student Added Successfully</h2>
                                <h5 className={classes.description}>
                                    Send below detailes to your teacher and teacher will enroll you for relavent classroom. Remember BirthDay And Barcode And Contact No that you fill before.
                                    It is need to login to Parent App.
          </h5>


                                <br />
                                <br />
                                <form className={classes.root} noValidate>
                                    <br />
                                    <App name={name} barCode={barCode} birthDay={birthDay} />
                                    <br />


                                </form>

                                <div>

                                    <ReactToPrint
                                        trigger={() => <ButtonMaterial variant="contained" className={classes.buttonMaterial2} >
                                            Print
                                            </ButtonMaterial>}
                                        content={() => componentRef.current}
                                    />
                                    <div style={{ display: "none" }}><ComponentToPrint name={name} barCode={barCode} birthDay={birthDay} allData={allData} ref={componentRef} /></div>
                                </div>
                                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} style={{ marginTop: "70px" }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                    }}  >
                                    <Alert onClose={handleClose} severity="success">
                                        This is a success message !
                                    </Alert>
                                </Snackbar>

                            </GridItem>
                        </GridContainer>

                    </div>


                </div>
            </div>
            <Footer />
        </div >
    );
}

export class ComponentToPrint extends React.PureComponent {

    render() {
        const classes = this.props;
        console.log(this.props.name); // result: 'some_value'
        console.log(this.props.allData); // result: 'some_value'
        console.log(this.props); // result: 'some_value'
        return (
            <>
                <div>
                    <table style={{ justifyContent: "center", marginLeft: "auto", marginRight: "auto", border: "5px solid black", padding: "10px" }}>
                        <br />
                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>

                            <h1 className={classes.title1}>Class Manager Registration</h1>
                        </div>
                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                            <CheckCircleOutlineIcon style={{ color: "green", fontSize: 250, justifyContent: "center" }} />

                        </div>
                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center', marginBottom: "16px" }}>

                            <h2 className={classes.title1}>Student Added Successfully</h2>
                        </div>

                        <App name={this.props.name} barCode={this.props.barCode} birthDay={this.props.birthDay} />
                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center', margin: "50px" }}>

                            <h4 className={classes.description}>
                                Send above detailes to your teacher and teacher will enroll you for relavent classroom. Remember BirthDay And Barcode And Contact No that you fill before.
                                It is need to login to Parent App.
                    </h4>
                        </div>

                        <div style={{ marginBottom: "40px" }}>

                        </div>

                    </table>
                    <div style={{ marginBottom: "2px" }}></div>

                    <table style={{ justifyContent: "center", marginLeft: "auto", marginRight: "auto", border: "5px solid black", padding: "10px", width: "100%" }}>
                        <br />
                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>

                            <h1 className={classes.title1}>Filled Registration Form</h1>
                        </div>
                        {/* <form className={classes.root} noValidate> */}

                        <br />
                        <ValidationTextField
                            className={classes.margin}
                            label="Student Name"
                            required
                            variant="outlined"
                            // defaultValue="Success"
                            id="name"
                            fullWidth
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.name : ''}

                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="Birth Day(YYYY/MM/DD)"
                            required
                            variant="outlined"
                            // defaultValue="Success"
                            id="birthDay2"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.birthday : ''}

                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="Gender"
                            required
                            variant="outlined"
                            // defaultValue="Success"
                            id="gender2"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.gender : ''}
                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="Parent Name"
                            required
                            variant="outlined"
                            // defaultValue="Success"
                            id="parentName"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.parentName : ''}
                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="O/L Year"
                            required
                            variant="outlined"
                            // defaultValue="Success"
                            id="olYear2"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.olyear : ''}
                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="Contact No"
                            required
                            variant="outlined"
                            // defaultValue="Success"
                            id="phoneNo"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.phoneNo : ''}
                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="Address Line 1"
                            required
                            variant="outlined"
                            // defaultValue="Success"
                            id="address1"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.address1 : ''}
                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="Address Line 2"
                            variant="outlined"
                            // defaultValue="Success"
                            id="address2"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.address2 : ''}
                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="Address Line 3"
                            variant="outlined"
                            // defaultValue="Success"
                            id="address3"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.address3 : ''}
                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="City"
                            required
                            variant="outlined"
                            // defaultValue="Success"
                            id="city"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.city : ''}
                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="Province"
                            required
                            variant="outlined"
                            // defaultValue="Success"
                            id="province2"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.province : ''}

                        />
                        <div style={{ marginBottom: "20px" }}></div>
                        <ValidationTextField
                            className={classes.margin}
                            label="School"
                            required
                            variant="outlined"
                            // defaultValue="Success"
                            id="school2"
                            fullWidth="true"
                            helperText=""
                            value={this.props.allData != null ? this.props.allData.school : ''}
                        />
                        <div style={{ marginBottom: "2px" }}></div>


                        {/* </form> */}
                    </table>
                </div>

            </>

        );
    }
}


