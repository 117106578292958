import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/contactPage.js";

import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'


import ButtonMaterial from '@material-ui/core/Button';

import image from "assets/img/contactUs2.jpg";
import image2 from "assets/img/LOGO-Icon.png";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_Lhc4fNTO1I46QOt1zLM3K");

const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [open2, setOpen2] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);


  let history = useHistory();


  const [values, setValues] = React.useState({

    name: '',
    email: '',
    message: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
    setOpenSuccess(false);
  }

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })



  function sendEmails() {
    if (values.email != '' || values.name != '' || values.message != '') {
      emailjs.send("service_qyj148i", "template_76b4kfu", {
        from_name: values.name,
        message: values.message,
        from_email: values.email,
      }).then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
        history.replace({
          pathname: "/",
        });
     }, function(err) {
        console.log('FAILED...', err);
     });
      // sleep(5000).then(r => {
      //   // do something
      //   history.replace({
      //     pathname: "/",
      //   });
      // })

    } else {
      setOpen2(true);
    }

  }

  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Class Manager"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: 'cover',
          overflow: 'hidden',
        }}
      >
        <MediaQuery minWidth={900}>
          <div className={classes.container}>
            <GridContainer justify="flex-end">
              <GridItem xs={12} sm={12} md={6}>
                <Card className={classes[cardAnimaton]}>
                  <form className={classes.form}>

                    <h4 className={classes.title}>Connect with Us</h4>
                    <p className={classes.divider}>Log in with your data that you entered during your registration</p>
                    <CardBody>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div style={{ marginBottom: "20px" }}></div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                            id="outlined-multiline-flexible"
                            label="Name"
                            fullWidth
                            value={values.name}
                            onChange={handleChange('name')}
                            variant="outlined"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <div style={{ marginBottom: "20px" }}></div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                            id="outlined-multiline-flexible"
                            label="Email"
                            fullWidth
                            value={values.email}
                            onChange={handleChange('email')}
                            variant="outlined"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <div style={{ marginBottom: "20px" }}></div>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Message"
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            value={values.message}
                            onChange={handleChange('message')}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => sendEmails()}>
                            Send Message
              </ButtonMaterial>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <h3 className={classes.divider}>Or</h3>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>

                          <h5 className={classes.title2}>Call Us</h5>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>

                          <h5 className={classes.title2}>+94 768099225</h5>
                        </GridItem>
                      </GridContainer>
                      {/* <br /> */}


                    </CardBody>
                    <CardFooter className={classes.cardFooter}>

                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
            <Snackbar open={open2} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}  >
              <Alert onClose={handleClose} severity="error">
                Enter All Fields !
                </Alert>
            </Snackbar>
            <Snackbar open={openSuccess} onClose={handleClose} style={{ marginTop: "70px" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}  >
              <Alert onClose={handleClose} severity="success">
                Message Complete Successfully.
              </Alert>
            </Snackbar>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={900}>
          <div className={classes.container2}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <Card className={classes[cardAnimaton]}>
                  <form className={classes.form}>

                    <h4 className={classes.title}>Connect with Us</h4>
                    <p className={classes.divider}>You can send messages and request and we will send reply to you as soon as possible</p>
                    <CardBody>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div style={{ marginBottom: "20px" }}></div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                            id="outlined-multiline-flexible"
                            label="Name"
                            fullWidth
                            value={values.name}
                            onChange={handleChange('name')}
                            variant="outlined"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <div style={{ marginBottom: "20px" }}></div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                            id="outlined-multiline-flexible"
                            label="Email"
                            fullWidth
                            value={values.email}
                            onChange={handleChange('email')}
                            variant="outlined"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <div style={{ marginBottom: "20px" }}></div>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Message"
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            value={values.message}
                            onChange={handleChange('message')}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <ButtonMaterial variant="contained" className={classes.buttonMaterial3} onClick={() => sendEmails()}>
                            Send Message
              </ButtonMaterial>
                        </GridItem>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <GridItem xs={12} sm={12} md={12}>
                          <h3 className={classes.divider}>Or</h3>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>

                          <h5 className={classes.title2}>Call Us</h5>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>

                          <h5 className={classes.title2}>+94 123456789</h5>
                        </GridItem>
                      </GridContainer>
                      {/* <br /> */}


                    </CardBody>
                    <CardFooter className={classes.cardFooter}>

                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
            <Snackbar open={open2} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}  >
              <Alert onClose={handleClose} severity="error">
                Enter All Fields !
                </Alert>
            </Snackbar>
            <Snackbar open={openSuccess} onClose={handleClose} style={{ marginTop: "70px" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}  >
              <Alert onClose={handleClose} severity="success">
                Message Complete Successfully.
              </Alert>
            </Snackbar>

          </div>

        </MediaQuery>

        <Footer />
      </div>
    </div>
  );
}
