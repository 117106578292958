import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";

import * as location from "./jsons/38435-register.json";
import * as success from "./jsons/8603-profile.json";

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: success.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function PreLoader2(props) {
  

  return (
    <div style={{ position: 'absolute', top: '0', left: '0', backgroundColor: '#282c34', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: "white", height: "100%", width: "100%" }}>
    <Lottie options={defaultOptions1} height={400} width={400} />
</div>
  );
}

export default PreLoader2;
