import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';


import ButtonMaterial from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';

import styles from "assets/jss/material-kit-react/views/signUpPage.js";

// Sections for this page
import ProductSection from "../LandingPage/Sections/ProductSection.js";
import TeamSection from "../LandingPage/Sections/TeamSection.js";
import WorkSection from "../LandingPage/Sections/WorkSection.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddAlert from "@material-ui/icons/AddAlert";
import { Cancel } from "@material-ui/icons";

import HttpCommon from "http-common.js";
import { useHistory } from "react-router";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Lottie from "react-lottie";

// import * as location from "./jsons/38435-register.json";
import * as location from "../../components/Loading/jsons/38435-register.json";

import * as success from "../../components/Loading/jsons/8603-profile.json";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";

const dashboardRoutes = [];

const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'grey',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },
        //   '& label.Mui-focused': {
        //     color: 'green',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        //   },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: '#3952C7',
            },
            // '&.Mui-focused fieldset': {
            //   borderColor: 'green',
            // },
        },
    },
})(TextField);

const useStyles = makeStyles(styles);
// const useStyles = makeStyles((theme) => ({
//     root: {
//       '& .MuiTextField-root': {
//         margin: theme.spacing(1),
//         width: '25ch',
//       },
//     },
//   }));

export default function AddStudentsPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    var temp;
    var token;
    const history = useHistory();




    const [values, setValues] = React.useState({
        institution: '',
        name: '',
        parentName: '',
        gender: '',
        birthDay: '',
        olYear: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        province: '',
        contactNo: '',
        school: '',
    });
    const [olYear, setSelectedOlYear] = React.useState(null);

    const [birthDay, setSelectedBirthDay] = React.useState(null);

    const [barcode, setBarcode] = React.useState('2021');
    const [errorMsg, setErrorMsg] = React.useState(' ');

    const [capthchcha, setCapthchcha] = React.useState('');

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [openErrorMsg, setOpenErrorMsg] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);

    const [centersArr, setCenterArr] = React.useState([]);
    const [checked, setChecked] = React.useState(true);

    const handleChangeSwitch = (event) => {
        setChecked(event.target.checked);
        console.log(event.target.checked);
    };

    async function clearErrorMsg() {
        setErrorMsg('');
        console.log("Cleared Error msg = " + errorMsg);
    }

    const handleClick = () => {
        AddStudent();
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
        setOpen5(false);
        setOpenErrorMsg(false);
        setOpenSuccess(false);
        clearErrorMsg();
    };

    const handleOlYearChange = (date) => {
        setSelectedOlYear(date);
        setValues({ ...values, olYear: date.getFullYear() });
        console.log("olYear = " + date.getFullYear());
    };

    const handleBirthDayChange = (date) => {
        setSelectedBirthDay(date);
        console.log("birthDay = " + date);
        var tempDate = date;
        if (date == 'Invalid Date' || date == null) {
            setValues({ ...values, birthDay: "" });
        } else {
            setValues({ ...values, birthDay: date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2) });
            // console.log("birthDay = " + date.setDate(date.getDate()+1).toISOString().slice(0, 10));
            console.log("birthDay = " + date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2));
        }
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        console.log([prop] + " = " + event.target.value);
    };

    const onProvinceChange = (event, value) => {
        setValues({ ...values, province: value });
        console.log("province = " + value);
    };

    const onInstituteChange = (event, value) => {
        if (value != null) {
            setValues({ ...values, institution: value });
            console.log("institution = " + value.id + " " + value.title);
            console.log(value);
        }

    };

    const onGenderChange = (event, value) => {
        setValues({ ...values, gender: value });
        console.log("gender = " + value);
    };

    const onSchoolChange = (event, value) => {
        setValues({ ...values, school: value });
        console.log("school = " + value);
    };


    function AddStudent() {
        setErrorMsg(" ");
        temp = {
            "name": values.name,
            "gender": values.gender,
            "parentName": values.parentName,
            "phoneNo": values.contactNo,
            "birthday": values.birthDay,
            "olyear": values.olYear + "",
            "address1": values.addressLine1,
            "address2": values.addressLine2,
            "address3": values.addressLine3,
            "city": values.city,
            "province": values.province,
            "school": values.school,
            "photo": "",
            "centerId": values.institution.id
        };
        console.log(temp);
        console.log('errorMsg = ' + errorMsg);
        if (values.contactNo.length > 10 || values.contactNo.length < 10) {
            setOpen5(true);

        } else {
            if (values.name != '' && values.parentName != '' && values.gender != ''
                && values.birthDay != '' && values.olYear != '' && values.addressLine1 != ''
                && values.city != '' && values.province != '' && values.contactNo != ''
                && values.school != '' && values.institution != '') {
                setLoading(true);

                HttpCommon.post('/lgo/addStudent', {

                    "name": values.name,
                    "gender": values.gender,
                    "parentName": values.parentName,
                    "phoneNo": values.contactNo,
                    "birthday": values.birthDay,
                    "olyear": values.olYear + "",
                    "address1": values.addressLine1,
                    "address2": values.addressLine2,
                    "address3": values.addressLine3,
                    "city": values.city,
                    "province": values.province,
                    "school": values.school,
                    "photo": "",
                    "centerId": values.institution.id

                }).then(response => {
                    console.log(response);
                    console.log("user ID ");

                    if (response.data.barcode != null) {
                        console.log(response.data.barcode);
                        getData();
                        const config = {
                            headers: { Authorization: `Bearer ${token}` }
                        };
                        if (checked) {
                            HttpCommon.post('/api/v1/requestIdForStudent', {

                                "type": "NFCCard",
                                "stdId": response.data.barcode,
                                "centerId": values.institution.id

                            }, config).then(response2 => {
                                console.log(response);
                                console.log(response2);
                                console.log("user ID ");
                                if (response.data.barcode != null) {
                                    console.log(response.data.barcode);
                                    console.log("ID request added");
                                    // setData(response.data.token);
                                    setLoading(false);

                                    setBarcode(response.data.barcode);
                                    setOpenSuccess(true);
                                    setValues({
                                        ...values, name: '', gender: '', parentName: '', birthDay: '', olYear: '', addressLine1: '',
                                        addressLine2: '', addressLine3: '', city: '', province: '', contactNo: '', school: '', institution: '',
                                    });
                                    setSelectedOlYear(null);
                                    setSelectedBirthDay(null);

                                    console.log("Student Added");
                                } else if (response2.data.error != null) {
                                    setLoading(false);
                                    setOpen6(false);
                                    setErrorMsg();

                                    response2.data.error.errors.forEach(element => {
                                        setErrorMsg(errorMsg + "\n" + element.message);
                                    });
                                    setOpenErrorMsg(true);
                                } else if (response2.data.errors != null) {
                                    setLoading(false);
                                    setOpen6(false);

                                    setErrorMsg(" ");

                                    response2.data.errors.errors.forEach(element => {
                                        setErrorMsg(errorMsg + "\n" + element.message);

                                    });
                                    setOpenErrorMsg(true);
                                } else {
                                    setLoading(false);
                                    setOpen6(false);
                                    setOpen(true);

                                }
                            }).catch(async function (error) {
                                setLoading(false);
                                setOpen6(false);

                                console.log(error.response);
                                await clearErrorMsg();
                                if (error.response.data.error !== undefined) {
                                    error.response.data.error.errors.forEach(element => {
                                        setErrorMsg(errorMsg + "\n" + element.message);

                                    });
                                } else {
                                    setErrorMsg("Error Occured");
                                }

                                setOpenErrorMsg(true);
                            });
                        } else {
                            setLoading(false);

                            setBarcode(response.data.barcode);
                            setOpenSuccess(true);
                            setValues({
                                ...values, name: '', gender: '', parentName: '', birthDay: '', olYear: '', addressLine1: '',
                                addressLine2: '', addressLine3: '', city: '', province: '', contactNo: '', school: '', institution: '',
                            });
                            setSelectedOlYear(null);
                            setSelectedBirthDay(null);

                            console.log("Student Added");
                        }




                        console.log("Student Added");
                    } else if (response.data.error != null) {
                        setLoading(false);
                        setOpen6(false);
                        setErrorMsg();

                        response.data.error.errors.forEach(element => {
                            setErrorMsg(errorMsg + "\n" + element.message);
                        });
                        setOpenErrorMsg(true);
                    } else if (response.data.errors != null) {
                        setLoading(false);
                        setOpen6(false);

                        setErrorMsg(" ");

                        response.data.errors.errors.forEach(element => {
                            setErrorMsg(errorMsg + "\n" + element.message);

                        });
                        setOpenErrorMsg(true);
                    } else {
                        setLoading(false);
                        setOpen6(false);
                        setOpen(true);

                    }
                }).catch(async function (error) {
                    setLoading(false);
                    setOpen6(false);

                    console.log(error.response);
                    await clearErrorMsg();
                    error.response.data.error.errors.forEach(element => {
                        setErrorMsg(errorMsg + "\n" + element.message);

                    });
                    setOpenErrorMsg(true);
                });
            } else {
                setLoading(false);
                setOpen6(false);
                setOpen2(true);

            }
        }
    }

    function getData() {
        token = localStorage.getItem('token') || null;
    }

    useEffect(() => {
        getData();
        // console.log("token = " + token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        if (token != null) {
            HttpCommon.post('/api/verifyUser', null,
                config
            )
                .then(response => {
                    if (response.data.id != null) {
                        console.log("user Verified");
                        console.log(response.data);
                        // responseData = response.data;
                        // setValues({
                        //     ...values, fName: response.data.firstname, lName: response.data.lastname
                        //     , nic: response.data.nic, address: response.data.address
                        //     , contactNo: response.data.telno, id: response.data.id
                        // });
                        var centerNames = [];
                        response.data.centers.forEach(element => {
                            centerNames.push({ 'title': element.Name, 'id': element.id });
                        });
                        console.log(centerNames);
                        setCenterArr(centerNames);
                        console.log(response.data.centers);
                    }
                });
        } else {
            history.push({
                pathname: "/login-page"
            });
        }



    }, []);

    return (
        <>
            {loading ?
                (<div style={{ position: 'absolute', top: '0', left: '0', backgroundColor: '#282c34', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: "white", height: "100%", width: "100%" }}>
                    <Lottie options={defaultOptions1} height={400} width={400} />
                </div>)
                :
                <>

                    <div>
                        <Header
                            brand="Class Manager"
                            rightLinks={<HeaderLinks />}
                            fixed
                            color="white"
                            changeColorOnScroll={{
                                height: 200,
                                color: "dark"
                            }}
                            {...rest}
                        />
                        <Parallax small image={require("assets/img/landing2.jpg")}>
                            <div className={classes.container}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <div className={classes.brand}>
                                            <h1 className={classes.title}>Add Students</h1>

                                        </div>
                                        <br />

                                    </GridItem>
                                </GridContainer>
                            </div>
                        </Parallax>
                        <div className={classNames(classes.main, classes.mainRaised)}>
                            <div className={classes.container}>
                                <div className={classes.section}>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={8}>
                                            <h2 className={classes.title1}>Let{"'"}s start Registration</h2>
                                            <h5 className={classes.description}>
                                                Class Manager is a online teaching platform that provide with
                                                many benifits. Every one can access ClassManager via Mobile, Laptop,
                                                Desktop. Teachers and Students can teach and learn any where they like.
                                            </h5>
                                            {/* <Typography color='primary' gutterBottom>
                                                Check the detailes that you enterd. You need to remember entered BirthDay, O/L Year, Barcode No, Contact No to log in to Parent App.
                                                To register to ClassManager App you will need to pay Rs 100 registration fee.
                                                </Typography> */}
                                            <br />
                                            <br />
                                            <form className={classes.root} noValidate>

                                                <br />
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Student Name"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="name"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.name}
                                                    onChange={handleChange('name')}

                                                />


                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container justify="center">

                                                        <KeyboardDatePicker
                                                            className={classes.margin2}
                                                            inputVariant="outlined"
                                                            autoOk
                                                            required
                                                            variant="inline"
                                                            margin="normal"
                                                            id="date-picker-dialog1"
                                                            label="Birth Day(YYYY/MM/DD)"
                                                            format="yyyy/MM/dd"
                                                            value={birthDay}
                                                            // value={values.birthDay}
                                                            fullWidth
                                                            // onAccept={handleBirthDayChange}
                                                            onChange={handleBirthDayChange}
                                                            // onChange={handleChange('birthDay')}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                                <Autocomplete
                                                    className={classes.margin3}
                                                    fullWidth
                                                    id="combo-box-demo1"
                                                    value={values.gender}
                                                    onChange={onGenderChange}
                                                    options={gender.map((option) => option.title)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Gender"
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={values.gender}
                                                        />
                                                    )}
                                                />
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Parent Name"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="parentName"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.parentName}
                                                    onChange={handleChange('parentName')}

                                                />
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container justify="center">

                                                        <KeyboardDatePicker
                                                            className={classes.margin2}
                                                            minDate={new Date().getFullYear() - 1 + '-01-01'}
                                                            maxDate={new Date().getFullYear() + 8 + '-01-01'}
                                                            inputVariant="outlined"
                                                            autoOk
                                                            required
                                                            views={['year']}
                                                            variant="inline"
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            label="O/L Year"
                                                            format="yyyy"
                                                            fullWidth
                                                            value={olYear}
                                                            onChange={handleOlYearChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Contact No"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="contactNo"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.contactNo}
                                                    onChange={handleChange('contactNo')}
                                                />
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Address Line 1"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="addressLine1"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.addressLine1}
                                                    onChange={handleChange('addressLine1')}
                                                />
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Address Line 2"
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="addressLine2"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.addressLine2}
                                                    onChange={handleChange('addressLine2')}
                                                />
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="Address Line 3"
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="addressLine3"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.addressLine3}
                                                    onChange={handleChange('addressLine3')}
                                                />
                                                <ValidationTextField
                                                    className={classes.margin}
                                                    label="City"
                                                    required
                                                    variant="outlined"
                                                    // defaultValue="Success"
                                                    id="city"
                                                    fullWidth
                                                    helperText=""
                                                    value={values.city}
                                                    onChange={handleChange('city')}
                                                />
                                                <Autocomplete
                                                    className={classes.margin3}
                                                    fullWidth
                                                    id="combo-box-demo"
                                                    onChange={onProvinceChange}
                                                    value={values.province}
                                                    options={provinces.map((option) => option.title)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Province"
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={values.province}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    freeSolo
                                                    className={classes.margin3}
                                                    id="free-solo-3-demo"
                                                    fullWidth
                                                    disableClearable
                                                    onChange={onSchoolChange}
                                                    value={values.school}
                                                    options={schools.map((option) => option.title)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="School"
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={values.school}
                                                            onChange={handleChange('school')}
                                                            InputProps={{ ...params.InputProps, type: 'search' }}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    className={classes.margin3}
                                                    fullWidth
                                                    id="combo-box-demo2"
                                                    onChange={onInstituteChange}
                                                    // defaultValue={values.institution.title}
                                                    value={{ title: values.institution.title }}
                                                    options={centersArr}
                                                    getOptionLabel={(option) => option.title}
                                                    // options={centersArr.map((option) => option.title)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Institiution"
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={values.institution.title}
                                                        />
                                                    )}
                                                />
                                                <div style={{ display: 'flex', width: "100%", position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                                                    <h5 className={classes.description}>
                                                        Is NFC ID Card Need.
                                                    </h5>
                                                    <Switch
                                                        color="primary"
                                                        checked={checked}
                                                        onChange={handleChangeSwitch}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </div>

                                            </form>
                                            {/* <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                                                <ReCAPTCHA
                                                    sitekey="6LfEmcgaAAAAAFxHHwbMoxJT1CRH5tyVRAmBYHhg"
                                                    onChange={onChange}
                                                />
                                            </div> */}
                                            <div>
                                                <ButtonMaterial variant="contained" className={classes.buttonMaterial2} onClick={() => handleClick()}>
                                                    Add Student
                                                </ButtonMaterial>
                                                {/* <Dialog onClose={handleClose6} aria-labelledby="customized-dialog-title" open={open6}>
                                                    <DialogTitle id="customized-dialog-title" onClose={handleClose6}>
                                                        Confirmation Page
                                            </DialogTitle>
                                                    <DialogContent dividers>
                                                        <Typography gutterBottom>
                                                            Check the detailes that you enterd. You need to remember entered BirthDay, O/L Year, Barcode No, Contact No to log in to Parent App.
                                                </Typography>
                                                        <form className={classes.root} noValidate>

                                                            <br />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Student Name"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="name2"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.name}

                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Birth Day(YYYY/MM/DD)"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="birthDay2"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.birthDay}

                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Gender"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="gender2"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.gender}

                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Parent Name"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="parentName2"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.parentName}

                                                            />

                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="O/L Year"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="olYear2"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.olYear}
                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Contact No"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="contactNo2"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.contactNo}
                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Address Line 1"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="addressLine12"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.addressLine1}
                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Address Line 2"
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="addressLine22"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.addressLine2}
                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Address Line 3"
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="addressLine32"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.addressLine3}
                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="City"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="city2"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.city}
                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="Province"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="province2"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.province}
                                                            />
                                                            <ValidationTextField
                                                                className={classes.margin}
                                                                label="School"
                                                                required
                                                                variant="outlined"
                                                                // defaultValue="Success"
                                                                id="school2"
                                                                fullWidth="true"
                                                                helperText=""
                                                                value={values.school}
                                                            />


                                                        </form>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Button autoFocus onClick={() => handleClick()} color="primary">
                                                                Register
                                                </Button>
                                                        </div>

                                                    </DialogActions>
                                                </Dialog> */}
                                            </div>

                                            <Snackbar open={openSuccess} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="success">
                                                    Student Added Success Fully! Barcode No is {barcode}.
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={openErrorMsg} autoHideDuration={3000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    {errorMsg}
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Enter Correct Credentials !
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={open2} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Enter All *Required Fields !
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={open3} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Unknown Error Occured !
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={open4} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Check I'm not a robot Box !
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={open5} autoHideDuration={1000} onClose={handleClose} style={{ marginTop: "70px" }}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}  >
                                                <Alert onClose={handleClose} severity="error">
                                                    Contact No Required 10 Digits Only !
                                                </Alert>
                                            </Snackbar>

                                        </GridItem>
                                    </GridContainer>

                                </div>


                            </div>
                        </div>
                        <Footer />
                    </div>

                </>
            }
        </>

    );
}

const schools = [

    { title: 'Vidyaloka College Galle' },
    { title: 'Sudharma Navodya College, Galle' },
    { title: 'Malharusulhiya Boys College, Thalapitiya, Galle' },
    { title: 'Richmond College Galle' },
    { title: "Rippon Girls' College Galle" },
    { title: "Sanghamitta Girls College Galle" },
    { title: "Southlands College Galle" },
    { title: "St. Aloysius' College Galle" },
    { title: "Mahinda College Galle" },
    { title: "Zahira Central College Ginthota" },
    { title: "Anuladevi Girls′ college Galle" },
    { title: "Sacred Heart Convent Galle" },
    { title: "Olcott College, Galle" },
    { title: "Nagoda Royal National College Nagoda" },
    { title: "Vidyaraja National School Thawalama" },
    { title: "All Saints College, Galle" },
    { title: "Rohana Vidyalaya, Ahangama" },
    { title: "Devapathiraja College, Rathgama" },
    { title: "Udugama Maha Vidyalaya, Udugama" },
    { title: "Siri Sunanda Maha Vidyalaya, Mapalagama" },

];

const provinces = [
    { title: "Central" },
    { title: "Eastern" },
    { title: "Northern" },
    { title: "Southern" },
    { title: "Western" },
    { title: "North Western" },
    { title: "North Central" },
    { title: "Uva" },
    { title: "Sabaragamuwa" },
];

const gender = [
    { title: "Male" },
    { title: "Female" },
];