import React, { Component }  from 'react';
import "./style/App.css";
import styled from "styled-components";
import MediaQuery from 'react-responsive'
import { NikeCard } from "./nikeCard";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function App(props) {
  // const { ...rest } = props;

  return (
    <AppContainer>
      <NikeCard name= {props.name} barCode= {props.barCode} birthDay= {props.birthDay} />
    </AppContainer>
  );
}

export default App;
