import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import image from "assets/img/LOGO1.png";
import styles from "assets/jss/material-kit-react/views/signUpPage.js";
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(styles);

export default function Title(props) {

    const classes = useStyles();

    return (
        <div style={{display:"flex", justifyContent:"center"}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems:"center", justifyContent:"center"}}>
        <img src={image} height="30px" style={{marginTop:"5px", marginRight:"10px"}}/>
            <h2 className={classes.title2}>Class Manager</h2>
            <h3 className={classes.title2}>Billing Detailes</h3>
            {/* <Typography display="block" component="h2" variant="h6" color="dark" gutterBottom>
                Billing Detailes
            </Typography> */}
        </div>
        </div>

    );
}

Title.propTypes = {
    children: PropTypes.node,
};