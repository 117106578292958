import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import image1 from "assets/img/bg.jpg";
import image2 from "assets/img/bg2.jpg";
import image3 from "assets/img/bg3.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(styles);

function GetImages(params2) {
  const divs = [];
  params2.images.forEach(element => {
    divs.push(<div>
      <img src={element} alt="First slide" className="slick-image" />
      <div className="slick-caption">
      </div>
    </div>)
  });
  return divs;
}

export default function SectionCarousel(params) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,

  };
  return (
    <div className={classes.section}>

      <MediaQuery minWidth={900}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
              <Card carousel>
                <Carousel {...settings}>
                   {GetImages(params)}
                </Carousel>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={900}>
        <div className={classes.container2}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className={classes.marginAuto2}>
              <Card carousel>
                <Carousel {...settings}>
                {GetImages(params)}

                </Carousel>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </MediaQuery>


    </div>
  );
}
