import { container, title } from "assets/jss/material-kit-react.js";

const aboutPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    // marginTop: "30px",
    marginBottom: "-20px",
    // minHeight: "32px",
    color: "black",
    textDecoration: "none"

    // fontSize: "4.2rem",
    // fontWeight: "600",
    // display: "inline-block",
    // position: "relative",
    // color: "black"
  },
  title2: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginBottom: "-20px",
    color: "#535251",
    textDecoration: "none"

  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    // margin: "10px auto 0",
    fontWeight: "400",
    marginTop: "30px",
    color: "black"
  },
  subtitle2: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    fontWeight: "400",
    marginTop: "30px",
    color: "black"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  brand: {
    color: "#FFFFFF",
    textAlign: "left"
  },
  instructions: {
    marginTop: "10px",
    marginBottom: "10px",
    color: "black"
  },
  buttonContainer:{
    justifyContent: 'flex-end',
    display: 'flex',
  },
  button: {
    marginTop: '10px',
    marginBottom: '10px',
    color: "white",
    width: "150px",
    height: "50px",
    fontSize: "15px",
    marginLeft: "250px" ,
    // background: "rgba(60, 95, 217, 0.9)",
    // transition: "all 150ms ease 0s",
    // "&:hover,&:focus": {
    //     color: "white",
    //     background: "rgba(60, 95, 217, 0.7)",
    //     marginTop: '7px',
    //     marginBottom: '13px',
    // }
},
button2: {
    marginTop: '10px',
    marginBottom: '10px',
    color: "white",
    width: "150px",
    height: "50px",
    fontSize: "15px",
    
    // background: "rgba(60, 95, 217, 0.9)",
    // transition: "all 150ms ease 0s",
    // "&:hover,&:focus": {
    //     color: "white",
    //     background: "rgba(60, 95, 217, 0.7)",
    //     marginTop: '7px',
    //     marginBottom: '13px',
    // }
},
};

export default aboutPageStyle;
