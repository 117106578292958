import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import PricePage from "views/PricePage/PricePage.js";
import SignUpPage from "views/SignUpPage/SignUpPage.js";
import AddStudentsPage from "views/BusinessPage/AddStudentsPage.js";
import AddTeachersPage from "views/BusinessPage/AddTeachersPage.js";
import AddWorkersPage from "views/BusinessPage/AddWorkersPage.js";
import ContactPage from "views/ContactPage/ContactPage.js";
import AboutPage from "views/AboutPage/AboutPage.js";
import MyAccount from "views/BusinessPage/MyAccount.js";
import AddStuWithoutToken from "views/BusinessPage/AddStuWithoutToken.js";
import SuccessPage from "views/BusinessPage/SuccessPage.js";
import ELearningPage from "views/BusinessPage/ELearningVideo.js";
import BillingPage from "views/BusinessPage/BillingPage.js";
import BillingSuccessPage from "views/BusinessPage/BillingSuccessPage.js";
import ExamPage from "views/BusinessPage/Exam.js";



var hist = createBrowserHistory();


ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/price-page" component={PricePage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/components" component={Components} />
      <Route path="/signUp-page" component={SignUpPage} />
      <Route path="/addStudents-page" component={AddStudentsPage} />
      <Route path="/id2b6sh603mfh69ma5vbt54ps7/addStudents-page/:centerId" component={AddStuWithoutToken} />
      <Route path="/id2b6sh603mfh69ma5vbt54ps7/confirm-page" component={SuccessPage} />
      <Route path="/addTeachers-page" component={AddTeachersPage} />
      <Route path="/addWorkers-page" component={AddWorkersPage} />
      <Route path="/myAccount" component={MyAccount} />
      <Route path="/contact-page" component={ContactPage} />
      <Route path="/about-page" component={AboutPage} />
      <Route path="/eLearning-page" component={ELearningPage} />
      <Route path="/exam-page" component={ExamPage} />
      <Route path="/billing-page" component={BillingPage} />
      <Route path="/billingSuccess-page" component={BillingSuccessPage} />
      <Route path="/" component={LandingPage} />
      {/* <Route path="/" component={AddStuWithoutToken} /> */}
    </Switch>
  </Router>,
  document.getElementById("root")
);


//  set HTTPS=true&&npm start
